import {workAttrList} from "../types/workForm";
import {MutationFormHookResult} from "../utils/formUtil";
import {registerWorkClass} from "../../../utils/awsAmplifyUtil";
import {ClassFormInputs} from "../components/forms/groups/ClassInputGroup";

export type RegisterClassFormInputs = ClassFormInputs

export const registerClassInitInputs: RegisterClassFormInputs = {}

export interface RegisterClassMutationParams {
  inputs: RegisterClassFormInputs
}

export default function useRegisterClass(): MutationFormHookResult<
  RegisterClassFormInputs,
  RegisterClassMutationParams,
  string
> {
  return {
    validate: (inputs: RegisterClassFormInputs) => ({
        isValid: true,
        errors: [],
      }),
    mutate: async (params: RegisterClassMutationParams) => {
      const classResult = await registerWorkClass({
        version: 'work-user',
        [workAttrList.nameJp.key]: params.inputs[workAttrList.nameJp.key] as string,
        [workAttrList.nameEn.key]: params.inputs[workAttrList.nameEn.key],
        [workAttrList.code.key]: params.inputs[workAttrList.code.key] as string,
        [workAttrList.contentWeight.key]: params.inputs[workAttrList.contentWeight.key]
          ? Number(params.inputs[workAttrList.contentWeight.key])
          : undefined,
        [workAttrList.contentVolume.key]: params.inputs[workAttrList.contentVolume.key]
          ? Number(params.inputs[workAttrList.contentVolume.key])
          : undefined,
      });
      if (!classResult.isSuccess) {
        return {
          isSuccess: false,
          message: '更新処理が失敗しました。',
          error: classResult.err,
        }
      }

      if (!classResult.data) {
        throw new Error('Class ID is not found');
      }

      return {
        isSuccess: true,
        // Class ID
        data: classResult.data,
      }
    }
  }
}
