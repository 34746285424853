/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from "react";

interface Props {
  toolTip: string|JSX.Element;
  sx?:object;
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
});

/**
 * ツールチップ
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props:Props) => (
    <Grid alignSelf="start">
      { typeof props.toolTip === "string" &&
        <Tooltip title={props.toolTip} >
          <HelpOutlineIcon sx={props.sx!==undefined?props.sx:{mt:2}} color='secondary'/>
        </Tooltip>
      }
      { typeof props.toolTip === "object" &&
        <NoMaxWidthTooltip title={props.toolTip}>
          <HelpOutlineIcon sx={props.sx!==undefined?props.sx:{mt:2}} color='secondary'/>
        </NoMaxWidthTooltip>
      }
    </Grid>
));
