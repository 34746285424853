import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";

export interface NameEnProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function NameEn(props: NameEnProps) {
  return (
    <WithLabel label={workAttrList.nameEn.label}>
      <Pjdb1TextField
        label={workAttrList.nameEn.label}
        name={workAttrList.nameEn.key}
        value={props.value ?? ''}
        onChange={props.onChange}
        disabled={props.disabled}
        isClear={props.isClear}
        xs={6}
        toolTip="英語名称"
        inputProps={{
          pattern: "[\x00-\x7F]+" // ASCII文字のみ
        }}
      />
    </WithLabel>
  )
}
