import {ReactElement, useState} from "react";
import fileDownload from "js-file-download";
import JSZip from "jszip";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Pjdb1Button, {Pjdb1ButtonProps} from "../../../../components/inputs/Pjdb1Button";
import Pjdb1CheckBox from "../../../../components/inputs/Pjdb1CheckBox";

interface FileDownloadProps extends Partial<Pjdb1ButtonProps> {
  files: DownloadFile[];
}

export interface DownloadFile {
  signedUrls: string[];
  id: string;
}


export default function DownloadFileButton(props: FileDownloadProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfDownloadedFiles, setNumberOfDownloadedFiles] = useState(0);
  const {open, openConfirmDialog, closeConfirmDialog, confirmed, setConfirmed} = useConfirmDialogState();
  const hasFiles = props.files.map((file) => file.signedUrls).flat().length > 0;

  const MAX_ZIP_SIZE = 200 * 1024 * 1024;  // 200MB

  const saveZip = async (zip: JSZip, filename: string) => {
    /* eslint-disable no-await-in-loop */
    const content = await zip.generateAsync({type: "blob"});
    fileDownload(content, filename);
  };

  const downloadFilesAsZip = async (files: DownloadFile[]) => {
    console.log("Start download files as zip", files);
    let zip = new JSZip();
    let currentZipSize = 0;
    let zipIndex = 1;
    /* eslint-disable no-restricted-syntax */
    for (const [index, file] of Array.from(files.entries())) {
      if (currentZipSize > MAX_ZIP_SIZE) {
        await saveZip(zip, `work_obj${zipIndex}.zip`);
        zip = new JSZip();
        currentZipSize = 0;
        zipIndex += 1;
      }

      const folder = zip.folder(file.id);
      const downloadPromises = file.signedUrls.map(async (url) => {
        const response = await fetch(url);
        const data = await response.blob();
        return {data, url};
      });

      const results = await Promise.all(downloadPromises);
      for (const {data, url} of results) {
        const filename = url.split("/").pop()?.split("?")[0];
        if (filename && folder) {
          folder.file(filename, data);
          currentZipSize += data.size;
        }
      }
      setNumberOfDownloadedFiles(index + 1)
    }

    if (currentZipSize > 0) {
      await saveZip(zip, `work_obj${zipIndex}.zip`);
    }
    console.log("Finish download files as zip");
  };


  const handleDownload = () => {
    (async () => {
      setIsLoading(true);
      await downloadFilesAsZip(props.files);
      setIsLoading(false);
    })().catch((error) => {
      console.error("Unhandled error:", error);
    }).finally(() => {
      setIsLoading(false);
      // closeConfirmDialog();
    });
  };

  return (
    <>
      <Pjdb1Button
        label={`ファイルをダウンロード${numberOfDownloadedFiles > 0 ? `(${numberOfDownloadedFiles}ファイル)` : ""}`}
        variant='contained'
        color='primary'
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        onClick={openConfirmDialog}
        disabled={isLoading || !hasFiles || props.disabled}
        isLoading={isLoading}
      />
      <ConfirmDialog
        open={open}
        confirmed={confirmed}
        isLoading={isLoading}
        setConfirmed={setConfirmed}
        onClose={closeConfirmDialog}
        onConfirm={handleDownload}
        onCancel={closeConfirmDialog}
      />
    </>
  );
};

function useConfirmDialogState() {
  const [open, setOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setConfirmed(false);
  }

  return {
    open,
    confirmed,
    setConfirmed,
    openConfirmDialog: handleOpen,
    closeConfirmDialog: handleClose,
  }
}

interface ConfirmDialogProps {
  open: boolean;
  confirmed: boolean;
  isLoading: boolean;
  setConfirmed: (value: boolean) => void;
  onClose: () => void;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmDialog(props: ConfirmDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>ダウンロードの確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          本データは3Dモデルを活用したロボットシステム構築・運用の研究・検証向けに
          準備したものであり、ダウンロードは「3Dモデルのデータの権利保護」を尊重する
          との趣旨に賛同し、弊機構からの事前の許可を得てから実行してください。
        </DialogContentText>
        <Pjdb1CheckBox
          label="メッセージを確認した"
          value={props.confirmed}
          onChange={() => props.setConfirmed(!props.confirmed)} name='agree'
        />
      </DialogContent>
      <DialogActions>
        <Pjdb1Button
          label="キャンセル"
          onClick={props.onCancel}
          variant="contained"
          color="inherit"
        />
        <Pjdb1Button
          label="ダウンロード"
          onClick={props.onConfirm} variant="contained"
          disabled={!props.confirmed || props.isLoading}
          isLoading={props.isLoading}
          color='info'
        />
      </DialogActions>
    </Dialog>
  )
}
