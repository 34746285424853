/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React,{ useState , useEffect } from "react";

import { InputLabel, Box, FormHelperText } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { FormHandAttr, FormHandShapeIntegrated } from '../../types/handForm'

import {handleInputChangeNameVal} from "../../../../utils/formUtil"

import Pjdb1TextField from '../../../../components/inputs/Pjdb1TextField';

// import Pjdb1ToolTip from "../../../../components/inputs/Pjdb1ToolTip";

interface Props {
  inputs: FormHandAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormHandAttr>>;
  isClear?: boolean;
  customErrorCheck?:() => void;
  customErrorMsg?:string;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log('[HandShapeIntegrated]:areEqual(prev)',prevProps);
  // console.log('[HandShapeIntegrated]:areEqual(next)',nextProps);
  let retEqual:boolean = JSON.stringify(prevProps.inputs.handShapeIntegrated) === JSON.stringify(nextProps.inputs.handShapeIntegrated);
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  if (nextProps.customErrorMsg !== undefined && JSON.stringify(prevProps.customErrorMsg) !== JSON.stringify(nextProps.customErrorMsg)) retEqual = false;
  // console.log('[HandShapeIntegrated]:areEqual(dif) val:',retEqual);
  return retEqual;
}

export default React.memo((props: Props) => {
  // console.log('[HandShapeIntegrated]:rendering!!',props);
  const [dispList, setDispList] = useState<FormHandShapeIntegrated[]>([]);
  useEffect(()=>{
    const dispListData:FormHandShapeIntegrated[] = [];
    if(props.inputs.handShapeIntegrated.length > 0){
      dispListData.push({
        range: props.inputs.handShapeIntegrated[0].range ? props.inputs.handShapeIntegrated[0].range : "",
        openLengthOuter: props.inputs.handShapeIntegrated[0].openLengthOuter ? props.inputs.handShapeIntegrated[0].openLengthOuter : "",
        closeLengthOuter: props.inputs.handShapeIntegrated[0].closeLengthOuter ? props.inputs.handShapeIntegrated[0].closeLengthOuter : "",
        openLengthInner: props.inputs.handShapeIntegrated[0].openLengthInner ? props.inputs.handShapeIntegrated[0].openLengthInner : "",
        closeLengthInner: props.inputs.handShapeIntegrated[0].closeLengthInner ? props.inputs.handShapeIntegrated[0].closeLengthInner : "",
      });
    }else{
      dispListData.push({
        range: "",
        openLengthOuter: "",
        closeLengthOuter: "",
        openLengthInner: "",
        closeLengthInner: "",
      });
    }
    setDispList(dispListData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.inputs.handShapeIntegrated]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,controlName:string) => {
    const controlValue:string = event.target.value ? event.target.value : "";
    // console.log("handleChange",controlName,rowIdx,controlValue);
    setDispList((prevList)=>{
      const newList:FormHandShapeIntegrated[] = [{
        range: controlName==="range" ? controlValue : prevList[0].range,
        openLengthOuter: controlName==="openLengthOuter" ? controlValue : prevList[0].openLengthOuter,
        closeLengthOuter: controlName==="closeLengthOuter" ? controlValue : prevList[0].closeLengthOuter,
        openLengthInner: controlName==="openLengthInner" ? controlValue : prevList[0].openLengthInner,
        closeLengthInner: controlName==="closeLengthInner" ? controlValue : prevList[0].closeLengthInner,
      }];
      handleInputChangeNameVal<FormHandAttr>("handShapeIntegrated", newList , props.inputs, props.setInputs);
      return newList;
    });
  }


  return (
    <>
      {dispList.map((value,index) => (
        <Box key={`row_${index}`}>

          <Pjdb1TextField
            label="ストローク"
            name="range" value={value.range}
            onChange={(e)=>{handleChange(e,"range")}}
            isClear={props.isClear}
            required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="mm"
            toolTip={
              <Grid container spacing={1} alignItems="start">
                <Grid>
                  <img src="/images/handdb/tooltip/integrated.range.jpg" style={{width:"320px"}} alt="ストローク"/>
                </Grid>
                <Grid>
                  <span>半角数値<br />最大開幅と最小開幅の差<br />※基準点によらない</span>
                </Grid>
              </Grid>
            }
          />

          <Grid container spacing={2} alignItems="start">
            <Grid width="90px">
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>外径把持</InputLabel>
            </Grid>
            <Grid width="250px">
              <Pjdb1TextField
                label="爪開寸法"
                name="openLengthOuter" value={value.openLengthOuter}
                onChange={(e)=>{handleChange(e,"openLengthOuter")}}
                isClear={props.isClear}
                inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={
                  <Grid container spacing={1} alignItems="start">
                    <Grid>
                      <img src="/images/handdb/tooltip/integrated.openLengthOuter.jpg" style={{width:"160px"}} alt="爪開寸法"/>
                    </Grid>
                    <Grid>
                      <span>半角数値<br />最大開時の把持部間の距離<br />※外径把持か内径把持のいずれか必須</span>
                    </Grid>
                  </Grid>
                }
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg}
              />
            </Grid>
            <Grid width="250px">
              <Pjdb1TextField
                label="爪閉寸法"
                name="closeLengthOuter" value={value.closeLengthOuter}
                onChange={(e)=>{handleChange(e,"closeLengthOuter")}}
                isClear={props.isClear}
                inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={
                  <Grid container spacing={1} alignItems="start">
                    <Grid>
                      <img src="/images/handdb/tooltip/integrated.closeLengthOuter.jpg" style={{width:"160px"}} alt="爪閉寸法"/>
                    </Grid>
                    <Grid>
                      <span>半角数値<br />最大閉時の把持部間の距離<br />※0が最小値<br />※外径把持か内径把持のいずれか必須</span>
                    </Grid>
                  </Grid>
                }
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="start">
            <Grid width="90px">
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>内径把持</InputLabel>
            </Grid>
            <Grid width="250px">
              <Pjdb1TextField
                label="爪開寸法"
                name="openLengthInner" value={value.openLengthInner}
                onChange={(e)=>{handleChange(e,"openLengthInner")}}
                isClear={props.isClear}
                inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={
                  <Grid container spacing={1} alignItems="start">
                    <Grid>
                      <img src="/images/handdb/tooltip/integrated.openLengthInner.jpg" style={{width:"160px"}} alt="爪開寸法"/>
                    </Grid>
                    <Grid>
                      <span>半角数値<br />最大開時の把持部間の距離<br />※外径把持か内径把持のいずれか必須</span>
                    </Grid>
                  </Grid>
                }
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg}
              />
            </Grid>
            <Grid width="250px">
              <Pjdb1TextField
                label="爪閉寸法"
                name="closeLengthInner" value={value.closeLengthInner}
                onChange={(e)=>{handleChange(e,"closeLengthInner")}}
                isClear={props.isClear}
                inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={
                  <Grid container spacing={1} alignItems="start">
                    <Grid>
                      <img src="/images/handdb/tooltip/integrated.closeLengthInner.jpg" style={{width:"160px"}} alt="爪閉寸法"/>
                    </Grid>
                    <Grid>
                      <span>半角数値<br />最大閉時の把持部間の距離<br />※外径把持か内径把持のいずれか必須</span>
                    </Grid>
                  </Grid>
                }
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={props.customErrorMsg}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="start" sx={{mb:1}}>
            <Grid sx={{flexGrow: 1}}>
              <FormHelperText>※外径把持、内径把持のいずれか必須</FormHelperText>
            </Grid>
          </Grid>

        </Box>
      ))}
    </>
  );
},areEqual);