/**
 * ハンド一覧検索条件
 */
export interface FormFingertipSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:string,
}

export interface FormFingertipShape {
  mountPattern: string;
  thicknessInner?: string;
  thicknessOuter?: string;
}

/**
 * 爪登録フォーム用タイプ
 */
export interface FormFingertipAttr {
  fingertipId: string;
  registrationDate: string;
  registrant: string;
  domain: string;
  setFingertip: boolean;
  public: boolean;
  name: string;
  number: string;
  producer: string;
  weight: string;
  fingertipType: string;
  elasticFingertip: boolean;
  material: string;
  keyword: string[];
  description: string;
  source: string[];
  fingertipShape: FormFingertipShape[];
  file: File|null;
  zipURI: string;
  handIds: string[];
}
// 爪登録フォーム 初期値
export const fingertipInitInputs:FormFingertipAttr = {
  fingertipId: "",
  registrationDate: "",
  registrant: "",
  domain: "",
  setFingertip: false,
  public: false,
  name: "",
  number: "",
  producer: "",
  weight: "",
  fingertipType: "",
  elasticFingertip: false,
  material: "",
  keyword: [""],
  description: "",
  source: [""],
  fingertipShape:[
    {
      mountPattern: "",
      thicknessInner: "",
      thicknessOuter: "",
    }
  ],
  file: null,
  zipURI: "",
  handIds: [""],
}