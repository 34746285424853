/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
  FormHelperText,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { HAND_POWER_SOURCES, HAND_FINGER_TYPES } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";

import {
  ApiHandRegist,
  makeApiHandRegistFromForm,
  ApiHandUpdate,
  makeApiHandUpdateFromForm,
  HandDocument
} from '../types/pj1dbApiHand'
import { FormHandAttr, handInitInputs } from '../types/handForm'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1SelectCheck from '../../../components/inputs/Pjdb1SelectCheck';
import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';

import HandShapeIntegrated from './inputs/HandShapeIntegrated';
import HandShapeDetachable from './inputs/HandShapeDetachable';
import HandExtraFields from './inputs/HandExtraFields';

import {ResponseType, fileUploade, registHand, updateHand, findHand} from '../../../utils/awsAmplifyUtil'

interface Props {
  regMode: boolean;
  setRegMode: React.Dispatch<React.SetStateAction<boolean>>;
  inputs: FormHandAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormHandAttr>>;
}
export default function HandRegistePanel(props:Props) {
  const { userRole } = useUserRole();
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();
  const { state, dispatch } = useContext(DataStoreContext);

  const formRef = useRef<HTMLFormElement>(null);

  const [fileErr,setFileErr] = useState({err:false,errMsg:""});


  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"handId":props.inputs.handId} };
    const result = await findHand(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:HandDocument = Object.entries(result.data).map(([,handDoc])=>(handDoc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info: newData} });
      let newList:HandDocument[] = state.handListPage.handList;
      newList = newList.map(row=>row.attr.handId===props.inputs.handId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_LIST ,
        payload: {...state.handListPage, handList:newList} });
    }
  }

  // 論理エラー input validationではハンドリングできないもの
  const customErrorInit = {
    gripForceMax: "",
    strokeLength: "",
    extraKey: [""],
  }
  const [customError, setCustomError] = useState(customErrorInit);
  const customErrorCheck = (controlName = ""):boolean => {
    if(controlName===""){
      setCustomError(customErrorInit);
    }
    if(controlName===""||controlName==="gripForceMax"){
      setCustomError({...customError,...{gripForceMax:""}});
      // 最大内方向把持力、最大外方向把持力のいずれか必須
      if(!props.inputs.freeFormat && props.inputs.gripForceInnerMax===""&&props.inputs.gripForceOuterMax===""){
        setCustomError({...customError,...{gripForceMax:"外径把持力(最大)、内径把持力（最大）のいずれかは必須です。"}});
        return false;
      }
    }
    if(controlName===""||controlName==="strokeLength"){
      setCustomError({...customError,...{strokeLength:""}});
      if(!props.inputs.freeFormat && !props.inputs.fingertipDetachable && props.inputs.handShapeIntegrated.length > 0){
        // 外径把持か内径把持のいずれか必須
        if(!( (props.inputs.handShapeIntegrated[0].closeLengthOuter!=="" && props.inputs.handShapeIntegrated[0].openLengthOuter!=="") ||
              (props.inputs.handShapeIntegrated[0].closeLengthInner!=="" && props.inputs.handShapeIntegrated[0].openLengthInner!=="") )){
          setCustomError({...customError,...{strokeLength:"外径把持か内径把持のいずれか必須です。"}});
          return false;
        }
      }
    }
    if(controlName===""||controlName==="extraKey"){
      setCustomError({...customError,...{extraKey:[""]}});
      const tempErr:string[] = [];
      // 任意項目のキー重複
      if(props.inputs.freeFormat && props.inputs.extra.length > 0){
        props.inputs.extra.forEach(row=>{
          if(row.key!=="" && props.inputs.extra.filter(row2=>row2.key===row.key).length>1){
            tempErr.push("項目名は重複不可です。");
          }else{
            tempErr.push("");
          }
        });
        setCustomError({...customError,...{extraKey:tempErr}});
      }
    }

    return true;
  }

  const clearProc = () => {
    setIsLoading(true);
    props.setRegMode(true);
    props.setInputs({...props.inputs,...handInitInputs});
    setIsClear(true); // 各入力コントロールのerrを初期化
    setFileErr({err:false,errMsg:""});
    setCustomError(customErrorInit);
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);

  const registData = async () => {
    try {
      let inputErr = false;
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        inputErr = true;
      }
      // 論理エラーチェック
      if(!customErrorCheck()){
        inputErr = true;
      }
      if(inputErr){
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (!props.inputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
      if(!resFile.isSuccess){
        errorMsg("ファイルアップロード処理が失敗しました。");
        return;
      }
      props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));

      // DB登録
      const regData:ApiHandRegist = makeApiHandRegistFromForm(props.inputs, String(resFile.data));
      const resReg:ResponseType<string[]> = await registHand(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }
      savedMsg();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  const updateData = async () => {
    try {
      let inputErr = false;
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        inputErr = true;
      }
      // 論理エラーチェック
      if(!customErrorCheck()){
        inputErr = true;
      }
      if(inputErr){
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (props.inputs.file){
        const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB更新
      const regData:ApiHandUpdate = makeApiHandUpdateFromForm(props.inputs, sInputFile);
      const resReg:ResponseType<string[]> = await updateHand(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }

      updatedMsg();

      void updateContextData();

    } catch (error) {
      console.log("updateData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Card sx={{p:3}}>

          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
            <Grid width="150px">
              <Typography variant="h6">ハンド情報</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              { !props.regMode &&
                <Typography variant="body2" sx={{color:"gray"}}>
                  登録日時（登録者）：
                    {`${props.inputs.registrationDate}
                    （${userRole === ROLE_KEYS.ADMIN ? String(props.inputs.registrant) : String(props.inputs.domain)}）`}
                </Typography>
              }
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>爪脱着可否</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>
                    <Pjdb1CheckBox
                      label="爪の脱着が可能"
                      name="fingertipDetachable" value={props.inputs.fingertipDetachable}
                      onChange={(e)=>{
                        handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs);
                        if(e.target.checked){
                          handleInputChangeNameVal<FormHandAttr>("freeFormat",false, props.inputs, props.setInputs);
                        }
                      }}
                      disabled={!props.regMode}
                      toolTip="爪の脱着が可能なハンドの場合にチェック"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>属性値</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>
                    <Pjdb1CheckBox
                      label="自由フォーマット"
                      name="freeFormat" value={props.inputs.freeFormat}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      disabled={!props.regMode || props.inputs.fingertipDetachable}
                      toolTip="以下のフォーマットに属性値が合わない場合にチェック"
                    />
                  </Grid>
                </Grid>

                <Pjdb1TextField
                  label="製品名"
                  name="name" value={props.inputs.name}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製造者（メーカー名など）"
                  name="producer" value={props.inputs.producer}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製品の型番"
                  name="number" value={props.inputs.number}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="ハンド質量"
                  name="weight" value={props.inputs.weight}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="kg"
                  toolTip={<span>半角数値<br/>{props.inputs.fingertipDetachable?"ハンドと爪の総質量":"ハンドの質量(爪質量は含まない)"}</span>}
                />

                <Pjdb1RadioGroup
                  name="fingertipType" value={props.inputs.fingertipType}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  radios={HAND_FINGER_TYPES.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                  required
                  toolTip="爪の種類を選択"
                />

                { !props.inputs.fingertipDetachable &&
                  <>
                    <Pjdb1CheckBox
                      label="柔軟爪"
                      name="elasticFingertip" value={props.inputs.elasticFingertip}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      toolTip="柔軟爪の場合にチェック"
                    />

                    <Pjdb1TextField
                      label="爪の材質"
                      name="material" value={props.inputs.material}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      xs={6}
                      toolTip="文字列"
                    />

                  </>
                }

                <Pjdb1SelectCheck
                  name="handPowerSource" value={props.inputs.handPowerSource}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  checks={HAND_POWER_SOURCES.map((row)=>({chkKey:row.key,chkLbl:row.label}))}
                  toolTip="１つ以上チェック"
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>製品情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextFieldList
                  label="キーワード"
                  name="keyword" value={props.inputs.keyword}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      属性値で表せないハンドの特徴を表すキーワードを必要に応じて入力する<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="説明欄"
                  name="description" value={props.inputs.description}
                  onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6} multiline rows={4}
                  toolTip={
                    <span>
                      自由記入欄<br />
                      属性値で表せないハンドの特徴を必要に応じて記入する
                    </span>
                  }
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他情報</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1TextFieldList
                      label="URL(製品HP, カタログのリンクなど)"
                      name="source" value={props.inputs.source}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      isClear={isClear}
                      xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            { !props.inputs.freeFormat &&
              <>
                <Grid container spacing={2} alignItems="start">
                  <Grid width="150px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>形状</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    { !props.inputs.fingertipDetachable &&
                      <HandShapeIntegrated
                        inputs={props.inputs}
                        setInputs={props.setInputs}
                        isClear={isClear}
                        customErrorCheck={()=>(customErrorCheck("strokeLength"))}
                        customErrorMsg={customError.strokeLength}
                      />
                    }

                    { props.inputs.fingertipDetachable &&
                      <HandShapeDetachable
                        inputs={props.inputs}
                        setInputs={props.setInputs}
                        isClear={isClear}
                      />
                    }

                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="start">
                  <Grid width="150px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>性能</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1TextField
                      label="可搬質量"
                      name="payload" value={props.inputs.payload}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="kg"
                      toolTip="半角数値"
                    />

                    <Pjdb1TextField
                      label="把持位置分解能"
                      name="gripPositionResolution" value={props.inputs.gripPositionResolution}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="mm"
                      toolTip="半角数値"
                    />

                    <Grid container spacing={2} alignItems="start">
                      <Grid width="120px">
                        <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>外径把持力</InputLabel>
                      </Grid>
                      <Grid width="200px">
                        <Pjdb1TextField
                          label="最小"
                          name="gripForceInnerMin" value={props.inputs.gripForceInnerMin}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                        />
                      </Grid>
                      <Grid width="240px">
                        <Pjdb1TextField
                          label="最大"
                          name="gripForceInnerMax" value={props.inputs.gripForceInnerMax}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                          toolTip={
                            <span>半角数値<br/>※外径把持力(最大)、内径把持力（最大）のいずれかは必須</span>
                          }
                          customErrorCheck={()=>(customErrorCheck("gripForceMax"))}
                          customErrorMsg={customError.gripForceMax}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} alignItems="start">
                      <Grid width="120px">
                        <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>内径把持力</InputLabel>
                      </Grid>
                      <Grid width="200px">
                        <Pjdb1TextField
                          label="最小"
                          name="gripForceOuterMin" value={props.inputs.gripForceOuterMin}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                        />
                      </Grid>
                      <Grid width="240px">
                        <Pjdb1TextField
                          label="最大"
                          name="gripForceOuterMax" value={props.inputs.gripForceOuterMax}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="N"
                          toolTip={
                            <span>半角数値<br/>※外径把持力(最大)、内径把持力（最大）のいずれかは必須</span>
                          }
                          customErrorCheck={()=>(customErrorCheck("gripForceMax"))}
                          customErrorMsg={customError.gripForceMax}
                        />

                      </Grid>
                    </Grid>
                    <Grid container alignItems="start" sx={{mb:1}}>
                      <Grid sx={{flexGrow: 1}}>
                        <FormHelperText>※外径把持力(最大)、内径把持力（最大）のいずれかは必須</FormHelperText>
                      </Grid>
                    </Grid>


                    <Pjdb1TextField
                      label="把持力分解能"
                      name="gripForceResolution" value={props.inputs.gripForceResolution}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="N"
                      toolTip="半角数値"
                    />

                    <Grid container spacing={2} alignItems="start">
                      <Grid width="120px">
                        <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>開閉速度</InputLabel>
                      </Grid>
                      <Grid width="200px">
                        <Pjdb1TextField
                          label="最小"
                          name="gripSpeedMin" value={props.inputs.gripSpeedMin}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm/s"
                        />
                      </Grid>
                      <Grid width="240px">
                        <Pjdb1TextField
                          label="最大"
                          name="gripSpeedMax" value={props.inputs.gripSpeedMax}
                          onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                          isClear={isClear}
                          inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm/s"
                          toolTip="半角数値"
                        />
                      </Grid>
                    </Grid>

                    <Pjdb1TextField
                      label="速度分解能"
                      name="gripSpeedResolution" value={props.inputs.gripSpeedResolution}
                      onChange={(e)=>(handleInputChange<FormHandAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3}
                      toolTip="半角数値"
                    />

                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="start" sx={{mt:1}}>
                  <Grid width="150px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>インターフェース</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1TextFieldList
                      label="通信IF(Modbus RTU(USB接続時), シリアル通信,  Ethenet/IP(Eth接続時) など)"
                      name="communication" value={props.inputs.communication}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      xs={9}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                    <Pjdb1TextFieldList
                      label="制御IF(ROS, 独自制御方式など)"
                      name="control" value={props.inputs.control}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      xs={9}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                    <Pjdb1TextFieldList
                      label="機械的IF(ISO-9409-***など)"
                      name="mechanic" value={props.inputs.mechanic}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      xs={9}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                    <Pjdb1TextFieldList
                      label="電気的IF(USB2.0, RS485, Ethなど)"
                      name="electronic" value={props.inputs.electronic}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                      disabled={!props.regMode}
                      isClear={isClear}
                      xs={9}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                  </Grid>
                </Grid>

              </>
            }

            { props.inputs.freeFormat &&
                <Grid container spacing={2} alignItems="start">
                <Grid width="150px">
                  <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他の属性</InputLabel>
                </Grid>
                <Grid sx={{flexGrow: 1}}>

                  <HandExtraFields
                    inputs={props.inputs}
                    setInputs={props.setInputs}
                    isClear={isClear}
                    customErrorCheck={()=>(customErrorCheck("extraKey"))}
                    customErrorMsg={customError.extraKey}
                  />

                </Grid>
              </Grid>
            }

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px" alignItems="start">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1FileSelect
                  label="ファイル"
                  name="file" value={props.inputs.file ? [props.inputs.file]:[]}
                  onChange={(name,value)=>(handleInputChangeFile<FormHandAttr>(name, value, props.inputs, props.setInputs))}
                  accept={{'application/zip': ['.zip']}} multiple={false}
                  err={fileErr.err} errMsg={fileErr.errMsg}
                  xs={8}
                  toolTip={
                    <span>
                      以下のフォルダ構成のzipファイルのみアップロード可能<br />
                      zip内フォルダ構成<br />
                      ┣&nbsp;image<br />
                      ┃┣&nbsp;main<br />
                      ┃┃┗&nbsp;メイン画像 ※必須、1枚、外観のわかる画像<br />
                      ┃┗&nbsp;sub<br />
                      ┃&nbsp;&nbsp;┗ サブ画像 任意、0枚以上、その他補足画像<br />
                      ┗&nbsp;urdf<br />
                      &nbsp;&nbsp;┗ URDFファイル 任意、1枚<br /><br />
                      ※jpg、png、svgの画像をアップロード可能
                    </span>
                  }
                />

              </Grid>
            </Grid>
          </form>

        </Card>
      </Container>

      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`製品情報を${props.regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            if(props.regMode){
              void registData();
            }else{
              void updateData();
            }
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${props.regMode?'登録':'更新'}確認`}
        message={`ハンド情報を${props.regMode?'登録':'更新'}しますか？`}
        confirmButton={`${props.regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />

    </>
  );
}