/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {useLocation, useNavigate} from "react-router-dom";

import {WORK_URLS} from "../../../constants/constants";
import {CONTEXT_ACTION_TYPE, DataStoreContext} from "../contexts/WorkContext";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WorkFuncTab() {
  const { state, dispatch } = React.useContext(DataStoreContext);

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB, payload: newValue });
    Object.entries(WORK_URLS).some(([, CONST]) => {
      if (CONST.TAB_IDX === newValue) {
        navigate(CONST.URL);
        return true;
      }
      return false;
    });
  };

  // locationの状態からタブ状態を復元（戻る・進む対応）
  const location = useLocation();
  React.useEffect(() => {
    Object.entries(WORK_URLS).some(([, CONST]) => {
      if (location.pathname.includes(CONST.URL)) {
        if (state.tabIndex !== CONST.TAB_IDX)
          dispatch({ type: CONTEXT_ACTION_TYPE.TAB, payload: CONST.TAB_IDX });
        return true;
      }
      return false;
    });
  }, [dispatch, location.pathname, state.tabIndex]);

  const workDetailTabDisabled = false;

  return (
    <Container
      sx={{
        m: 0,
        pt: 2,
        backgroundColor: "white",
        width: "100%",
        maxWidth: "unset!important",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={state.tabIndex} onChange={handleChange}>
            <Tab
              label="商品/部品一覧"
              {...a11yProps(0)}
              sx={{ fontSize: "1rem" }}
            />
            <Tab
              label="商品/部品詳細"
              {...a11yProps(1)}
              sx={{ fontSize: "1rem" }}
              disabled={workDetailTabDisabled}
            />
            <Tab
              label="登録済み商品/部品一覧"
              {...a11yProps(2)}
              sx={{ fontSize: "1rem" }}
            />
            <Tab
              label="商品/部品登録"
              {...a11yProps(3)}
              sx={{ fontSize: "1rem" }}
            />
          </Tabs>
        </Box>
      </Box>
    </Container>
  );
}
