/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { IconButton} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import React,{ useState , useEffect} from "react";
import Pjdb1TextField from "./Pjdb1TextField";

interface Props {
  name: string;
  label: string;
  value: string[];
  onChange?: (name:string,value:string[]) => void;
  required?: boolean;
  disabled?: boolean;
  inputProps?:object;
  xs?:number;
  unit?: string;
  toolTip?: string|JSX.Element;
  isClear?: boolean;
  requiredOnlyFirst?: boolean;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextFieldList]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextFieldList]:areEqual(next)',nextProps);
  // let retEqual:boolean = prevProps.value === nextProps.value;
  let retEqual:boolean = JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value);
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  // console.log(`[${prevProps.name}]`,'[Pjdb1TextFieldList]:areEqual(dif) val:',retEqual);
  return retEqual;
}

/**
 * 複数テキストボックス
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props: Props) => {
  const [values, setValue] = useState(props.value.length>0?props.value:[""]);

  useEffect(()=>{
    // setValue(props.value);
    setValue(props.value.length>0?props.value:[""]);
  },[props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name:string = event.target.name ? event.target.name : "";
    const value:string = event.target.value ? event.target.value : "";

    setValue((prevValues)=>{
      const index:number = parseInt(name.replace(`${props.name}_`,""), 10);
      const newValues = prevValues.map((val,idx)=>{
        if(idx === index){
          return value;
        }
        return val;
      })
      if(props.onChange) props.onChange(props.name,newValues);
      return newValues;
    });
  }

  const handleDeleted = (name:string) => {
    setValue((prevValues)=>{
      if(prevValues.length===1){
        const newValues = prevValues.map(() => "");
        if(props.onChange) props.onChange(props.name,newValues);
        return newValues;
      }
      const index:number = parseInt(name.replace(`${props.name}_`,""), 10);
      const newValues = prevValues.filter((val,idx) => idx !== index)
      if(props.onChange) props.onChange(props.name,newValues);
      return newValues;
    });
  }

  const addTextField = () => {
    setValue((prevValues)=>{
      const newValues = [...prevValues,""];
      if(props.onChange) props.onChange(props.name,newValues);
      return newValues;
    });
  }

  // console.log(`[${props.name}]`,'[Pjdb1TextFieldList]:rendering!!',props);
  return (
    <>
      {values.map((value,index) => (
        <Pjdb1TextField
          name={`${props.name}_${index}`} label={props.label}
          key={`${props.name}_${index}`}
          value={value} onChange={handleChange}
          {...(props.required !== undefined || (props.requiredOnlyFirst !== undefined && index === 0) ? {required:true} : {})}
          {...(props.disabled !== undefined ? {disabled:props.disabled}:{})}
          {...(props.inputProps !== undefined ? {inputProps:props.inputProps} : {})}
          {...(props.xs !== undefined ? {xs:props.xs} : {})}
          {...(props.unit !== undefined ? {unit:props.unit} : {})}
          {...(index === 0 && props.toolTip !== undefined ? {toolTip:props.toolTip} : {})}
          dispDeleteButton
          deleted={handleDeleted}
          {...(props.isClear !== undefined ? {isClear:props.isClear} : {})}
        />
      ))}
      <IconButton
        color="info" sx={{mt:0,mb:1}} onClick={addTextField}
        disabled={props.disabled !== undefined ? props.disabled : false}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </>
  );
},areEqual);

export function TextFieldListDescription() {
  return (
    <>
      <AddCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで項目追加、
      <RemoveCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで項目削除
    </>
  )
};