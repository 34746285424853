/* eslint-disable react/require-default-props */
import React from 'react';
import LoadingButton, {LoadingButtonProps} from '@mui/lab/LoadingButton';

export interface Pjdb1ButtonProps {
  label:string;
  variant: "text" | "outlined" | "contained" ;
  color: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  sx?:object;
  disabled?: boolean;
  isLoading?:boolean;
  onClick?: () => void;
  type?: LoadingButtonProps['type'];
}

/**
 * ボタン
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props: Pjdb1ButtonProps) => (
    <LoadingButton
      variant={props.variant}
      color={props.color}
      onClick={props.onClick}
      disabled={props.disabled !== undefined ? props.disabled : false}
      loading={props.isLoading}
      sx={{...props.sx,px:3}}
      type={props.type}
    >
      {props.label}
    </LoadingButton>
  ));
