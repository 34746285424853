import {Box, InputLabel} from "@mui/material";
import Grid from '@mui/material/Grid2';
import React from "react";

export interface WithLabelProps {
  label: string;
  children: React.ReactNode;
}

export default function WithLabel(props: WithLabelProps) {
  return (
    <Grid container columnSpacing={2}>
      <Grid size={{xs: 12, md: 4, lg: 3}}>
        <InputLabel variant='standard' sx={{mt: 2, color: 'text.primary', textWrap: 'wrap'}}>{props.label}</InputLabel>
      </Grid>
      <Grid sx={{flexGrow: 1}}>
        {props.children}
      </Grid>
    </Grid>
  )
}

export function WithLabelSpacingWrapper({children}: { children: React.ReactNode }) {
  return (
    <Box my={2}>
      {children}
    </Box>
  )
}
