/* eslint-disable react/require-default-props */
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button} from "@mui/material";
import {useState} from "react";

type ConfirmDialogResult = "confirm" | "cancel";

type ConfirmDialogProps = {
  open: boolean;
  onClose: (result: ConfirmDialogResult) => void;
  title: string;
  message: string;
  confirmButton: string;
  cancelButton?: string;
  confirmButtonStyle: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

function ConfirmDialogComponent(props: ConfirmDialogProps) {
  return <Dialog open={props.open} onClose={() => props.onClose("cancel")}>
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.message}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => props.onClose("cancel")} variant="contained" color="inherit">
        {props.cancelButton ? props.cancelButton : "キャンセル"}
      </Button>
      <Button onClick={() => props.onClose("confirm")} variant="contained"
              color={props.confirmButtonStyle} autoFocus>
        {props.confirmButton}
      </Button>
    </DialogActions>
  </Dialog>
}

export default function useConfirmDialog() {
  const [open, setOpen] = useState(false);
  const [resolve, setResolve] = useState<(value: ConfirmDialogResult) => void>();

  const openConfirmDialog = () => {
    setOpen(true);

    // eslint-disable-next-line @typescript-eslint/no-shadow
    return new Promise<ConfirmDialogResult>((resolve) => {
      setResolve(() => resolve);
    });
  };

  const onClose = (result: ConfirmDialogResult) => {
    setOpen(false);
    if (resolve) {
      resolve(result);
    }
  };

  interface Props {
    title: string;
    message: string;
    confirmButton: string;
    cancelButton?: string;
    confirmButtonStyle: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined;
  }

  function ConfirmDialog(props: Props) {
    return (
      <ConfirmDialogComponent
        title={props.title} message={props.message}
        confirmButton={props.confirmButton}
        cancelButton={props.cancelButton}
        confirmButtonStyle={props.confirmButtonStyle ? props.confirmButtonStyle : "primary"}
        open={open} onClose={onClose}/>
    )
  }

  return {
    ConfirmDialog,
    openConfirmDialog,
  };
};
