import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";

export interface CodeProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function Code(props: CodeProps) {
  return (
    <WithLabel label={workAttrList.code.label}>
      <Pjdb1TextField
        label={workAttrList.code.label}
        name={workAttrList.code.key}
        value={props.value ?? ''}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        isClear={props.isClear}
        xs={6}
        toolTip="商品/部品の一意のコード"
      />
    </WithLabel>
  )
}
