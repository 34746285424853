import {Box, BoxProps, Card, CardContent, ImageList, ImageListItem, Stack, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {workAttrList} from "../../types/workForm";
import {WorkClassDocument, WorkInstanceDocument, WorkModelDocument} from "../../types/pj1dbApiWork";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import DetailPageLink from "../links/DetailPageLink";
import {WORK_URLS} from "../../../../constants/constants";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType,
  makeInstanceAndModelRegistrationPayload,
  makeInstanceEditPayload,
  makeModelRegistrationPayload,
} from "../../contexts/WorkContext";
import {DEFAULT_IMAGE_BLACK_URL} from "../../utils/pickImageUrl";
import {canRegisterInstanceAndModel} from "../forms/RegisterInstanceAndModelForm";
import {canEditInstance} from "../forms/EditInstanceForm";
import {canRegisterModel} from "../forms/RegisterModelForm";
import useUser from "../../hooks/useUser";

export interface WorkGroupListItemProps {
  work: WorkClassDocument;
  instances: WorkInstanceWithModels[];
  selectedInstanceId: string | null;
  onChange: (instanceId: string | null) => void;
}

export interface WorkInstanceWithModels {
  instance: WorkInstanceDocument;
  models: WorkModelDocument[];
}

const IMAGE_HEIGHT = 145;

const IMAGE_WIDTH = 145;

export default function WorkGroupListItem(props: WorkGroupListItemProps) {
  const {user, role} = useUser();
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const selectedStyle: BoxProps['sx'] = {
    outlineColor: (theme) => theme.palette.primary.light,
    outlineStyle: "solid",
    outlineWidth: 6,
    borderRadius: 1,
  };
  const pickModelImages = (models: WorkModelDocument[]): string[] => models
    .map(model => props.work.url?.[model.attr[workAttrList.modelId.key]])
    .filter((url): url is string[] => url !== null)
    .flat() ?? []
  const selectedInstance = props.instances.find(({instance}) => instance.attr[workAttrList.instanceId.key] === props.selectedInstanceId)?.instance;

  return (
    <Card sx={{p: 0, m: 0, height: '100%'}}>
      <CardContent>
        <Stack gap={2}>
          <DetailPageLink work={props.work} style={{textDecoration: 'none'}}>
            <Typography variant="subtitle2" component='p' sx={{overflowWrap: 'break-word'}}>
              商品/部品名：{props.work.attr[workAttrList.nameJp.key]}
            </Typography>
          </DetailPageLink>
          <Box display='flex' gap={4} p={1} flexWrap='nowrap' sx={{overflowX: 'scroll'}}>
            {props.instances.length === 0 && (
              <Box sx={{
                height: IMAGE_HEIGHT,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}/>
            )}
            {props.instances.map(({instance, models}) => (
              <Box key={instance.attr[workAttrList.instanceId.key]}
                   onClick={() => props.onChange(props.selectedInstanceId === instance.attr[workAttrList.instanceId.key] ? null : instance.attr[workAttrList.instanceId.key])}
                   sx={instance.attr[workAttrList.instanceId.key] === props.selectedInstanceId ? selectedStyle : {}}>
                <WorkInstance images={pickModelImages(models)}/>
              </Box>
            ))}
          </Box>
          <Box display='flex' justifyContent='flex-end' gap={4}>
            <Pjdb1Button
              label="別サンプルを登録"
              variant="contained"
              color="info"
              disabled={!canRegisterInstanceAndModel()}
              onClick={() => {
                dispatch({
                  type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
                  payload: makeInstanceAndModelRegistrationPayload({state, work: props.work}),
                });
                navigate(WORK_URLS.WORK_REGISTER.URL)
              }}/>
            <Pjdb1Button
              label="サンプル情報を修正"
              variant="contained"
              color="secondary"
              disabled={!hasInstance(props.selectedInstanceId ?? '', props.work) || !selectedInstance || !canEditInstance({
                user,
                role,
                work: selectedInstance,
              })}
              onClick={() => {
                if (props.selectedInstanceId === null) {
                  throw new Error(`Instance not selected`);
                }

                if (selectedInstance == null) {
                  throw new Error(`Instance not found (${props.selectedInstanceId})`);
                }

                dispatch({
                  type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
                  payload: makeInstanceEditPayload({
                    state,
                    work: props.work,
                    instance: selectedInstance,
                  }) satisfies DataStoreType['workRegistrationPage'],
                });

                navigate(WORK_URLS.WORK_REGISTER.URL)
              }}/>
            <Pjdb1Button
              label="別モデルを登録"
              variant="contained"
              color="primary"
              disabled={!hasInstance(props.selectedInstanceId ?? '', props.work) || !canRegisterModel()}
              onClick={() => {
                if (props.selectedInstanceId === null) {
                  throw new Error(`Instance not selected`);
                }

                if (selectedInstance == null) {
                  throw new Error(`Instance not found (${props.selectedInstanceId})`);
                }

                dispatch({
                  type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
                  payload: makeModelRegistrationPayload({
                    state,
                    work: props.work,
                    instance: selectedInstance,
                  }) satisfies DataStoreType['workRegistrationPage'],
                });
                navigate(WORK_URLS.WORK_REGISTER.URL)
              }}/>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}

interface WorkInstanceProps {
  images: string[];
}

function WorkInstance(props: WorkInstanceProps) {
  if (props.images.length === 0) {
    return (
      <Box sx={{
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
        border: 1,
        borderStyle: 'solid',
        borderColor: 'grey.500',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <img src={DEFAULT_IMAGE_BLACK_URL} alt="" width={IMAGE_WIDTH} height={IMAGE_HEIGHT}/>
      </Box>
    )
  }

  return (
    <ImageList cols={props.images.length} gap={2} sx={{p: 0, m: 0}}>
      {props.images.map((url) => (
        <ImageListItem key={url} sx={{width: IMAGE_WIDTH, height: IMAGE_HEIGHT}}>
          <img
            srcSet={url}
            src={url}
            alt={url}
            loading="lazy"
            style={{borderRadius: 4}}
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

function hasInstance(id: string, work: WorkClassDocument): boolean {
  return Object.keys(work.instanceModelRelation ?? {}).includes(id);
}
