import {useContext, useEffect, useState} from "react";
import {Box, Container, SelectChangeEvent, Typography,} from "@mui/material";
import Grid from '@mui/material/Grid2';

import {handleInputChangeSelect,} from "../../../utils/formUtil";

import WorkListItem from "../components/WorkListItem";
import {WorkClassDocument} from "../types/pj1dbApiWork";
import useNotification from "../../../utils/notificationUtil";
import {CONTEXT_ACTION_TYPE, DataStoreContext, DataStoreType} from "../contexts/WorkContext";
import {WorkSortOrderValue} from "../../../constants/constants";
import SearchForm, {useSearchFormProps} from "../components/forms/SearchForm";
import SortOrder, {useSortOrder} from "../components/forms/SortOrder";
import {FormWorkSearchCond, workAttrList} from "../types/workForm";
import {findWork} from "../../../utils/awsAmplifyUtil";
import DetailPageLink from "../components/links/DetailPageLink";
import {excludeClassesWithChildren} from "../utils/workUtil";

export default function WorkList() {
  const {errorMsg} = useNotification();
  const {state, dispatch} = useContext(DataStoreContext);
  const [workList, setWorkList] = useState<WorkClassDocument[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");
  const {isLoading, setIsLoading, searchCond, setSearchCond} = useSearchFormProps();
  const {sortWorkList} = useSortOrder();

  const findWorkProc = async () => {
    setIsLoading(true);
    try {
      const params = {
        version: "work-user",
        queryAttr: {
          ...(searchCond.keyword === "" ? {} : {
            $or: [
              {[workAttrList.nameJp.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[workAttrList.nameEn.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[workAttrList.code.key]: {"$regex": searchCond.keyword, "$options": "i"}},
            ]
          }),
        },
      };

      const result = await findWork('class', params);

      if (!result.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data = sortWorkList(
        excludeClassesWithChildren(result.data ? Object.values(result.data.items) : []),
        searchCond.sortOrder
      );
      if (data.length === 0) {
        setNotFoundMsg("該当データがありません。");
      }
      setWorkList(data);
      setSearchCond({...searchCond, listCount: data.length});
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_LIST, payload: {
          searchCond: {...searchCond, listCount: data.length},
          workList: data,
        } satisfies DataStoreType['workListPage']
      });
    } finally {
      setIsLoading(false);
    }
  };

  const changeSortOrder = (event: SelectChangeEvent<WorkSortOrderValue>) => {
    handleInputChangeSelect<FormWorkSearchCond>(event, "sortOrder", searchCond, setSearchCond);
    setWorkList(sortWorkList(workList, event.target.value as WorkSortOrderValue));
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_LIST, payload: {
        searchCond: {...searchCond, sortOrder: event.target.value as WorkSortOrderValue},
        workList,
      } satisfies DataStoreType['workListPage']
    });
  }

  // 検索済みデータの復元
  useEffect(() => {
    setSearchCond(state.workListPage.searchCond);
    setWorkList(state.workListPage.workList);
    setNotFoundMsg("検索キーワードを入力し、検索ボタンを押してください。");
    if (state.workListPage.searchCond.keyword !== "") {
      setNotFoundMsg("該当データがありません。");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchForm isLoading={isLoading} searchCond={searchCond} setSearchCond={setSearchCond} onSubmit={(e) => {
        e.preventDefault();
        findWorkProc().catch(console.error);
      }}/>

      <Container
        sx={{p: 0, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
        maxWidth={false}
      >
        <Grid container alignItems="end" sx={{mt: 2, flexWrap: "nowrap"}} size={{xs: 12}}>
          <Grid size="grow">
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
              検索結果一覧
            </Typography>
          </Grid>
          <Grid size="auto">
            <Grid container alignItems="center" spacing={2} sx={{flexWrap: "nowrap"}}>
              <Grid>
                <Box component="span">
                  {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
                </Box>
              </Grid>
              <Grid>
                <Box component="span">
                  <SortOrder value={searchCond.sortOrder} onChange={changeSortOrder}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="stretch" spacing={2} sx={{mt: 2}}>
          {workList.map(row => (
            <Grid key={row.attr[workAttrList.classId.key]}>
              <DetailPageLink work={row} style={{textDecoration: 'none'}}>
                <WorkListItem work={row}/>
              </DetailPageLink>
            </Grid>
          ))}

        </Grid>

        {(workList.length === 0) && (
          <Box sx={{my: 3, width: "100%", textAlign: "center",}}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: notFoundMsg}}/>
          </Box>
        )}
      </Container>
    </>
  );
}
