import {Card, Container, Typography} from "@mui/material";

export default function Forbidden() {
  return (
    <Container maxWidth="lg">
        <Typography variant="h6">403 Forbidden</Typography>
        <Typography>アクセス権限がありません。</Typography>
    </Container>
  )
}
