import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#48B130',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#FCCE02',
      contrastText: '#ffffff',
    },
    error: {
      main: '#D32F2F',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ED6C02',
      contrastText: '#ffffff',
    },
    info: {
      main: '#0288D1',
      contrastText: '#ffffff',
    },
    success: {
      main: '#2E7D32',
      contrastText: '#ffffff',
    },
    background: {
      default: '#FFFFFF',
    },
    text: { primary: 'rgba(0,0,0,0.87)' },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '"Noto Sans JP"',
      '"Helvetica"',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: "none"
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'contained' && ownerState.color === 'primary' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.primary.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'secondary' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.secondary.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'error' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.error.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'warning' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.warning.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'info' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.info.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'success' && {
            '&:hover': {
              backgroundColor: defaultTheme.palette.success.light,
            },
          }),
          ...(ownerState.variant === 'contained' && ownerState.color === 'inherit' && {
            backgroundColor: grey[600],
            color: "white",
            '&:hover': {
              backgroundColor: grey[500],
            },
          }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { opacity: "0",display:"none" },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: { opacity: "0",display:"none" },
      },
    },
  },
});
export default defaultTheme;