import Model from "../fields/Model";
import {workAttrList} from "../../../types/workForm";
import {handleInputChange, handleInputChangeFiles} from "../../../../../utils/formUtil";
import FileType from "../fields/FileType";
import When from "../fields/When";
import Who from "../fields/Who";
import {WorkModelType} from "../../../../../constants/constants";

export interface ModelInputGroupProps {
  inputs: ModelFormInputs;
  setInputs: React.Dispatch<React.SetStateAction<ModelFormInputs>>;
  modelFileErr: ModelFileErr;
  setModelFileErr: React.Dispatch<React.SetStateAction<ModelFileErr>>;
  isClear: boolean;
}

export interface ModelFormInputs {
  [workAttrList.description.key]?: string;
  [workAttrList.modelType.key]?: WorkModelType;
  [workAttrList.modelFile.key]: File[] | null;
  [workAttrList.fileType.key]?: string;
  [workAttrList.when.key]?: string;
  [workAttrList.who.key]?: string;
}

export interface ModelFileErr {
  err: boolean;
  errMsg: string;
}

export function clearModelFileErr(setModelFileErr: React.Dispatch<React.SetStateAction<ModelFileErr>>) {
  setModelFileErr({err: false, errMsg: ''});
}

export default function ModelInputGroup(props: ModelInputGroupProps) {
  return (
    <>
      <Model modelType={props.inputs[workAttrList.modelType.key] ?? ''}
             files={props.inputs[workAttrList.modelFile.key]}
             fileErr={props.modelFileErr.err}
             fileErrMsg={props.modelFileErr.errMsg}
             onChangeModelType={(e) => (handleInputChange<ModelFormInputs>(e, props.inputs, props.setInputs))}
             onChangeFile={(name, value) => {
               clearModelFileErr(props.setModelFileErr);
               handleInputChangeFiles<ModelFormInputs>(name, value, props.inputs, props.setInputs)
             }}
             required
             isClear={props.isClear}/>

      <FileType value={props.inputs[workAttrList.fileType.key] ?? ''}
                onChange={(e) => (handleInputChange<ModelFormInputs>(e, props.inputs, props.setInputs))}
                modelType={props.inputs[workAttrList.modelType.key]}
                required
                isClear={props.isClear}/>

      <When value={props.inputs[workAttrList.when.key]}
            onChange={(e) => (handleInputChange<ModelFormInputs>(e, props.inputs, props.setInputs))}
            isClear={props.isClear}/>

      <Who value={props.inputs[workAttrList.who.key]}
           onChange={(e) => (handleInputChange<ModelFormInputs>(e, props.inputs, props.setInputs))}
           isClear={props.isClear}/>
    </>
  )
}
