/* eslint-disable react/require-default-props */
import Grid from '@mui/material/Grid2';

import { Outlet } from "react-router-dom"
import MainHeader from "../components/MainHeader"
import MainFooter from '../components/MainFooter';

export interface Props {
  bgcolor?:string;
}

export default function MainLayout(props:Props) {
  return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{height:"100vh",flexWrap:"nowrap"}}
      >
        <Grid sx={{flexGrow: 0}}>
          <MainHeader />
        </Grid>
        <Grid sx={{bgcolor:props.bgcolor,flexGrow: 1}}>
          <Outlet />
        </Grid>
        <Grid sx={{flexGrow: 0}}>
          <MainFooter />
        </Grid>
      </Grid>

  )
}
