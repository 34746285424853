import {useCallback, useEffect, useRef, useState} from "react";
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from "../../../components/ConfirmDialog";

export interface UseMutationHelperProps {
  onClear?: () => void;
}

export interface MakeMutationParams {
  callback: () => Promise<boolean>,
  validate?: () => boolean,
  onCanceled?: () => void,
  onCompleted?: () => void,
  onError?: (err: unknown) => void,
  successMsg?: string
  errMsg?: string
}


export default function useMutationHelper(params?: UseMutationHelperProps) {
  const notification = useNotification();
  const [isClear, setIsClear] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();
  const formRef = useRef<HTMLFormElement>(null);

  const clear = () => {
    setIsLoading(true);
    params?.onClear?.();
    setIsClear(true); // 各入力コントロールのerrを初期化
    notification.infoMsg("クリアしました。");
    setIsLoading(false);
  }

  // isClearの戻し処理
  useEffect(() => {
    if (isClear) setIsClear(false);
  }, [isClear]);

  const handleError = useCallback(({error, message}: { error?: unknown, message?: string } = {}) => {
    console.error(`${message ?? "エラーが発生しました。"}: `, error);
    notification.errorMsg(message ?? "エラーが発生しました。");
    return false;
  }, [notification]);

  const makeMutation = useCallback((mutationParams: MakeMutationParams) => async () => {
    try {
      // Validation
      if (!formRef.current?.reportValidity()) {
        notification.errorMsg("入力内容に誤りがあります。");
        return;
      }

      if (mutationParams.validate && !mutationParams.validate()) {
        return;
      }

      // Confirm
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        mutationParams.onCanceled?.();
        return;
      }

      // Loading
      setIsLoading(true);

      // Callback
      if (await mutationParams.callback()) {
        if (mutationParams.onCompleted) {
          mutationParams.onCompleted();
        } else {
          notification.infoMsg(mutationParams.successMsg ?? "処理が完了しました。");
        }
      }
    } catch (error) {
      if (mutationParams.onError) {
        mutationParams.onError(error);
      } else {
        handleError({error, message: mutationParams.errMsg});
      }
    } finally {
      setIsLoading(false);
    }
  }, [handleError, notification, openConfirmDialog]);

  return {
    notification,
    clear,
    setIsClear,
    isClear,
    isLoading,
    setIsLoading,
    ConfirmDialog,
    openConfirmDialog,
    formRef,
    handleError,
    makeMutation,
  }
}
