import {FormControl, InputLabel, MenuItem, Select, SelectProps} from "@mui/material";
import {useCallback, useMemo, useState} from "react";
import {WorkClassDocument} from "../../types/pj1dbApiWork";
import {workAttrList} from "../../types/workForm";
import {WORK_SORT_ORDER, WorkSortOrderValue} from "../../../../constants/constants";

export interface SortOrderProps {
  value: WorkSortOrderValue;
  onChange: SelectProps<WorkSortOrderValue>['onChange'];
}

export function useSortOrder() {
  const [sortOrder, setSortOrder] = useState<WorkSortOrderValue>(WORK_SORT_ORDER.NAME.value);

  const sortList = useMemo(() => ({
    [WORK_SORT_ORDER.NAME.value]: (a: WorkClassDocument, b: WorkClassDocument) => (a.attr[workAttrList.nameJp.key] ?? '').localeCompare(b.attr[workAttrList.nameJp.key] ?? ''),
    [WORK_SORT_ORDER.CODE.value]: (a: WorkClassDocument, b: WorkClassDocument) => (a.attr[workAttrList.code.key] ?? '').localeCompare(b.attr[workAttrList.code.key] ?? ''),
    [WORK_SORT_ORDER.LATEST.value]: (a: WorkClassDocument, b: WorkClassDocument) => b.attr[workAttrList.created.key] - a.attr[workAttrList.created.key],
  } as const satisfies Record<WorkSortOrderValue, (a: WorkClassDocument, b: WorkClassDocument) => number>), []);

  const sortWorkList = useCallback((list: WorkClassDocument[], order: WorkSortOrderValue): WorkClassDocument[] => list.sort(sortList[order]), [sortList]);

  return {
    SORT_ORDER: WORK_SORT_ORDER,
    sortWorkList,
    sortOrder,
    setSortOrder,
  }
}

export default function SortOrder(props: SortOrderProps) {
  return (
    <FormControl sx={{minWidth: 120}} size="small">
      <InputLabel id="sort-order-label">並び順</InputLabel>
      <Select
        labelId="sort-order-label"
        name="sortOrder"
        value={props.value}
        label="並び順"
        onChange={props.onChange}
        sx={{bgcolor: "white"}}
      >
        {Object.values(WORK_SORT_ORDER).map((item) => (
          <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
