import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";

export interface WhoProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function Who(props: WhoProps) {
  return (
    <WithLabel label={workAttrList.who.label}>
      <Pjdb1TextField
        label={workAttrList.who.label}
        name={workAttrList.who.key}
        value={props.value ?? ''}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        isClear={props.isClear}
        xs={6}
        toolTip="モデルの測定者"
      />
    </WithLabel>
  )
}
