import {useContext} from "react";
import {Link, LinkProps} from "react-router-dom";
import {WORK_URLS} from "../../../../constants/constants";
import {workAttrList} from "../../types/workForm";
import {CONTEXT_ACTION_TYPE, DataStoreContext, DataStoreType} from "../../contexts/WorkContext";
import {WorkClassDocument} from "../../types/pj1dbApiWork";

export interface DetailPageLinkProps extends Partial<LinkProps> {
  work: WorkClassDocument;
}

export default function DetailPageLink(props: DetailPageLinkProps) {
  const {state, dispatch} = useContext(DataStoreContext);

  return (
    <Link
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      to={`${WORK_URLS.WORK_DETAIL.URL}/${props.work.attr[workAttrList.classId.key]}`}
      onClick={() => {
        dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: WORK_URLS.WORK_DETAIL.TAB_IDX})
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_DETAIL,
          payload: {
            ...state.workDetailPage,
            info: props.work,
          } satisfies DataStoreType['workDetailPage']
        });
      }}
    >
      {props.children}
    </Link>
  );
}
