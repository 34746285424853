import {workAttrList} from "../types/workForm";
import {WorkInstanceDocument} from "../types/pj1dbApiWork";
import {registerWorkModel, uploadModelFiles} from "../../../utils/awsAmplifyUtil";
import {MutationFormHookResult, ValidationError} from "../utils/formUtil";
import {ModelFormInputs} from "../components/forms/groups/ModelInputGroup";

export type RegisterModelFormInputs = ModelFormInputs

export const registerModelFormInitInputs: RegisterModelFormInputs = {
  [workAttrList.modelFile.key]: null,
}

export interface RegisterModelMutationParams {
  inputs: RegisterModelFormInputs,
  instanceId: WorkInstanceDocument['attr'][typeof workAttrList.instanceId.key],
}

export default function useRegisterModel(): MutationFormHookResult<
  RegisterModelFormInputs,
  RegisterModelMutationParams,
  string
> {
  return {
    validate: (inputs: RegisterModelFormInputs) => {
      const errors: ValidationError[] = []

      if (!inputs[workAttrList.modelFile.key]) {
        errors.push({field: workAttrList.modelFile.key, message: "ファイルを選択してください。"});
      }

      return {
        isValid: errors.length === 0,
        errors,
      }
    },
    mutate: async (params: RegisterModelMutationParams) => {
      // ファイルアップロード
      const inputModelFiles = params.inputs[workAttrList.modelFile.key]
      const resFile = await uploadModelFiles(inputModelFiles ?? []);
      if (!resFile.isSuccess) {
        return  {
          isSuccess: false,
          message: 'ファイルアップロード処理が失敗しました。',
          error: resFile.err,
        }
      }

      const modelResult = await registerWorkModel({
        version: 'work-user',
        s3Uri: resFile.data,
        bulk: false, // TODO: モデル数が1つの場合はfalse、複数の場合はtrueに設定するがどのように判定するか確認が必要
        scale: 1,
        changeYZ: false,
        instanceId: params.instanceId,
        [workAttrList.fileType.key]: params.inputs[workAttrList.fileType.key] as string,
        [workAttrList.when.key]: params.inputs[workAttrList.when.key] ? new Date(params.inputs[workAttrList.when.key] as string).getTime() : undefined,
        [workAttrList.who.key]: params.inputs[workAttrList.who.key] as string,
      })

      if (!modelResult.isSuccess) {
        return {
          isSuccess: false,
          message: '更新処理が失敗しました。',
        }
      }

      if (modelResult.data === undefined) {
        throw new Error('Model ID is not returned');
      }

      return {
        isSuccess: true,
        // Model ID
        data: modelResult.data,
      }
    },
  }
}
