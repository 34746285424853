/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
  CardMedia,
  CardContent,
  TextField,
  FormControlLabel,
  Switch
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom"

import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog';

import {
  ApiAttachmentRegist,
  makeApiAttachmentRegistFromForm,
  ApiAttachmentUpdate,
  makeApiAttachmentUpdateFromForm,
  AttachmentDocument,
} from '../types/pj1dbApiAttachment';
import { FormAttachmentAttr, attachmentInitInputs } from '../types/attachmentForm'
import { HandDocument } from '../types/pj1dbApiHand';
import { ArmDocument } from '../types/pj1dbApiArm';

import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";
import { EQUIPMENT_URLS } from '../../../constants/constants'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import Pjdb1ToolTip from '../../../components/inputs/Pjdb1ToolTip';

import {ResponseType, fileUploade, registAttachment, updateAttachment, findAttachment, findHand, findArm} from '../../../utils/awsAmplifyUtil'

interface Props {
  regMode: boolean;
  setRegMode: React.Dispatch<React.SetStateAction<boolean>>;
  inputs: FormAttachmentAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormAttachmentAttr>>;
}
export default function AttachmentRegistePanel(props:Props) {
  const { userRole } = useUserRole();
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const { state, dispatch } = useContext(DataStoreContext);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const formRef = useRef<HTMLFormElement>(null);

  const [fileErr,setFileErr] = useState({err:false,errMsg:""});

  // 接続可能ハンド選択関連
  const [handList, setHandList] = useState<HandDocument[]>([]);
  const [handSearchKeyword, setHandSearchKeyword] = useState("");
  const [handCandidateList, setHandCandidateList] = useState<HandDocument[]>([]);
  const [handSelectedList, setHandSelectedList] = useState<HandDocument[]>([]);

  // 接続可能アーム選択関連
  const [armList, setArmList] = useState<ArmDocument[]>([]);
  const [armSearchKeyword, setArmSearchKeyword] = useState("");
  const [armCandidateList, setArmCandidateList] = useState<ArmDocument[]>([]);
  const [armSelectedList, setArmSelectedList] = useState<ArmDocument[]>([]);

  const clearProc = () => {
    setIsLoading(true);
    props.setRegMode(true);
    props.setInputs({...props.inputs,...attachmentInitInputs,handIds:[""],armIds:[""]});
    setIsClear(true); // 各入力コントロールのerrを初期化
    setFileErr({err:false,errMsg:""});
    setHandSearchKeyword("");
    setHandCandidateList([]);
    setHandSelectedList([]);
    setArmSearchKeyword("");
    setArmCandidateList([]);
    setArmSelectedList([]);
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);

  const registData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (!props.inputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
      if(!resFile.isSuccess){
        errorMsg("ファイルアップロード処理が失敗しました。");
        return;
      }
      props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));

      // DB登録
      const regData:ApiAttachmentRegist = makeApiAttachmentRegistFromForm(props.inputs, String(resFile.data));
      const resReg:ResponseType<string[]> = await registAttachment(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }
      savedMsg();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"attachmentId":props.inputs.attachmentId} };
    const result = await findAttachment(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:AttachmentDocument = Object.entries(result.data).map(([,attachmentDoc])=>(attachmentDoc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.ATTACHMENT_DETAIL , payload: {info: newData} });
      let newList:AttachmentDocument[] = state.attachmentListPage.attachmentList;
      newList = newList.map(row=>row.attr.attachmentId===props.inputs.attachmentId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.ATTACHMENT_LIST ,
        payload: {...state.attachmentListPage, attachmentList:newList} });
    }
  }

  const updateData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (props.inputs.file){
        const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB登録
      const regData:ApiAttachmentUpdate = makeApiAttachmentUpdateFromForm(props.inputs, sInputFile);
      const resReg:ResponseType<string[]> = await updateAttachment(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }

      updatedMsg();

      void updateContextData();

    } catch (error) {
      console.log("updateData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  // ハンド検索（全件）
  const findHandProc = async () => {
    const cond = {};
    const result = await findHand(cond);
    if(!result.isSuccess) return;
    // console.log(result);
    if(result.data && Object.entries(result.data).length > 0){
      let handListData:HandDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
      handListData = handListData.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
      setHandList(handListData);
      setHandSelectedList(
        handListData.filter((item)=> props.inputs.handIds.includes(item.attr.handId))
      );
    }else{
      setHandList([]);
    }
  }
  // アーム検索（全件）
  const findArmProc = async () => {
    const cond = {};
    const result = await findArm(cond);
    if(!result.isSuccess) return;
    // console.log(result);
    if(result.data && Object.entries(result.data).length > 0){
      let armListData:ArmDocument[] = Object.entries(result.data).map(([,armDoc])=>(armDoc));
      armListData = armListData.sort((a,b) => a.attr.armInfo.name.localeCompare(b.attr.armInfo.name));
      setArmList(armListData);
      setArmSelectedList(
        armListData.filter((item)=> props.inputs.armIds.includes(item.attr.armId))
      );
    }else{
      setArmList([]);
    }
  }

  useEffect(()=>{
    // 初回のハンド・アーム情報検索
    void findHandProc();
    void findArmProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // (接続可能ハンド)製品名変更
  const changeHandAutoComp = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|null,) => {
    let sKey = "";
    if(event!==null){
      sKey = event.target.value;
      setHandSearchKeyword(sKey);
    }else{
      sKey = handSearchKeyword;
    }
    // console.log(sKey);
    let tempCandidateList:HandDocument[] = []
    if(sKey!==""){
      tempCandidateList =
        handList.filter((item)=>
          item.attr.handInfo.name.toUpperCase().includes(String(sKey).toUpperCase()) ||
          item.attr.handInfo.producer.toUpperCase().includes(String(sKey).toUpperCase()) ||
          (
            item.attr.handInfo.number &&
            item.attr.handInfo.number.toUpperCase().includes(String(sKey).toUpperCase())
          ) ||
          (
            item.attr.productInfo.keyword &&
            item.attr.productInfo.keyword.filter((kwd)=>(kwd.toUpperCase().includes(String(sKey).toUpperCase()))).length > 0
          ) ||
          (
            item.attr.productInfo.description &&
            item.attr.productInfo.description.toUpperCase().includes(String(sKey).toUpperCase())
          )
        );
    }else{
      tempCandidateList = handList;
    }
    setHandCandidateList(tempCandidateList);
  }
  // (接続可能ハンド)接続可能スイッチ変更
  const changeHandSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean)=>{
    const name:string = event.target.name ? event.target.name : "";
    let tempHandIds:string[] = props.inputs.handIds;
    if(checked){
      if(!props.inputs.handIds.includes(name)){
        tempHandIds.push(name);
      }
    }else{
      tempHandIds = tempHandIds.filter((handId)=>(handId!==name));
    }
    handleInputChangeNameVal<FormAttachmentAttr>("handIds", tempHandIds, props.inputs, props.setInputs)
    setHandSelectedList(
      handList.filter((item)=> tempHandIds.includes(item.attr.handId))
    );
  };

  // (接続可能アーム)製品名変更
  const changeArmAutoComp = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>|null,) => {
    let sKey = "";
    if(event!==null){
      sKey = event.target.value;
      setArmSearchKeyword(sKey);
    }else{
      sKey = armSearchKeyword;
    }
    // console.log(sKey);
    let tempCandidateList:ArmDocument[] = []
    if(sKey!==""){
      tempCandidateList =
        armList.filter((item)=>
          item.attr.armInfo.name.toUpperCase().includes(String(sKey).toUpperCase()) ||
          item.attr.armInfo.manufacturer.toUpperCase().includes(String(sKey).toUpperCase()) ||
          (
            item.attr.armInfo.number &&
            item.attr.armInfo.number.toUpperCase().includes(String(sKey).toUpperCase())
          ) ||
          (
            item.attr.productInfo.keyword &&
            item.attr.productInfo.keyword.filter((kwd)=>(kwd.toUpperCase().includes(String(sKey).toUpperCase()))).length > 0
          ) ||
          (
            item.attr.productInfo.description &&
            item.attr.productInfo.description.toUpperCase().includes(String(sKey).toUpperCase())
          )
        );
    }else{
      tempCandidateList = armList;
    }
    setArmCandidateList(tempCandidateList);
  }
  // (接続可能アーム)接続可能スイッチ変更
  const changeArmSwitch = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean)=>{
    const name:string = event.target.name ? event.target.name : "";
    let tempArmIds:string[] = props.inputs.armIds;
    if(checked){
      if(!props.inputs.armIds.includes(name)){
        tempArmIds.push(name);
      }
    }else{
      tempArmIds = tempArmIds.filter((armId)=>(armId!==name));
    }
    handleInputChangeNameVal<FormAttachmentAttr>("armIds", tempArmIds, props.inputs, props.setInputs)
    setArmSelectedList(
      armList.filter((item)=> tempArmIds.includes(item.attr.armId))
    );
  };

  return (
    <>
      <Container maxWidth="lg">
        <Card sx={{p:3}}>
          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
              <Grid width="200px">
                <Typography variant="h6">アタッチメント情報</Typography>
              </Grid>
              <Grid sx={{flexGrow: 1}}>
                { !props.regMode &&
                  <Typography variant="body2" sx={{color:"gray"}}>
                    登録日時（登録者）：
                      {`${props.inputs.registrationDate}
                      （${userRole === ROLE_KEYS.ADMIN ? String(props.inputs.registrant) : String(props.inputs.domain)}）`}
                  </Typography>
                }
              </Grid>
              <Grid><Pjdb1ImputEx/></Grid>
            </Grid>
            <form ref={formRef}>
              <Grid container spacing={2} alignItems="start">
                <Grid width="150px">
                  <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
                </Grid>
                <Grid sx={{flexGrow: 1}}>
                  <Pjdb1CheckBox
                    label="メーカー製アタッチメント"
                    name="setAttachment" value={props.inputs.setAttachment}
                    onChange={(e)=>{
                      handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs);
                      if(e.target.checked){
                        handleInputChangeNameVal<FormAttachmentAttr>("public",true, props.inputs, props.setInputs);
                      }
                    }}
                    disabled={!props.regMode}
                    toolTip="メーカー製アタッチメントの場合にチェック"
                  />

                  <Pjdb1CheckBox
                    label="公開アタッチメント"
                    name="public" value={props.inputs.public}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    disabled={props.inputs.setAttachment}
                    toolTip="アタッチメントを公開しても問題ない場合にチェック"
                  />

                  <Pjdb1TextField
                    label="製品名"
                    name="name" value={props.inputs.name}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    disabled={!props.regMode}
                    isClear={isClear}
                    required xs={6}
                    toolTip={
                      <span>
                        文字列<br/>
                        ハンドとセットで売っているものはその属性値を入力する<br/>
                        単体で売っている場合はその属性値を優先して入力する<br/>
                        独自に製作した場合は適当な名前とする
                      </span>
                    }
                  />

                  <Pjdb1TextField
                    label="製造者（メーカー名など）"
                    name="manufacturer" value={props.inputs.manufacturer}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    disabled={!props.regMode}
                    isClear={isClear}
                    required xs={6}
                    toolTip="文字列"
                  />

                  <Pjdb1TextField
                    label="製品の型番"
                    name="number" value={props.inputs.number}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    disabled={!props.regMode}
                    isClear={isClear}
                    xs={6}
                    toolTip={
                      <span>
                        文字列<br/>
                        ハンドとセットで売っているものはその属性値を入力する<br/>
                        単体で売っている場合はその属性値を優先して入力する<br/>
                        独自に製作した場合は適当な名前とする
                      </span>
                    }
                  />

                  <Pjdb1TextField
                    label="アタッチメント質量"
                    name="weight" value={props.inputs.weight}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    isClear={isClear}
                    inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="kg"
                    toolTip={<span>半角数値<br/>アタッチメントの質量</span>}
                  />

                  <Pjdb1TextField
                    label="同時取付け可能ハンド数"
                    name="handCount" value={props.inputs.handCount}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    isClear={isClear}
                    required inputProps={{pattern: "[0-9]*" }} xs={4}
                    toolTip="半角数値"
                  />

                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="start">
                <Grid width="150px">
                  <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>製品情報</InputLabel>
                </Grid>
                <Grid sx={{flexGrow: 1}}>

                  <Pjdb1TextFieldList
                    label="キーワード"
                    name="keyword" value={props.inputs.keyword}
                    onChange={(name,value)=>(handleInputChangeNameVal<FormAttachmentAttr>(name, value, props.inputs, props.setInputs))}
                    isClear={isClear}
                    xs={6}
                    toolTip={
                      <span>
                        文字列<br/>
                        属性値で表せないアタッチメントの特徴を表すキーワードを必要に応じて入力する<br/>
                        <TextFieldListDescription />
                      </span>
                    }
                  />

                  <Pjdb1TextField
                    label="説明欄"
                    name="description" value={props.inputs.description}
                    onChange={(e)=>(handleInputChange<FormAttachmentAttr>(e, props.inputs, props.setInputs))}
                    isClear={isClear}
                    xs={6} multiline rows={4}
                    toolTip={
                      <span>
                        自由記入欄<br />
                        属性値で表せないアタッチメントの特徴を必要に応じて記入する
                      </span>
                    }
                  />

                  <Grid container spacing={2} alignItems="start">
                    <Grid width="100px">
                      <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他情報</InputLabel>
                    </Grid>
                    <Grid sx={{flexGrow: 1}}>

                      <Pjdb1TextFieldList
                        label="URL(製品HP, カタログのリンクなど)"
                        name="source" value={props.inputs.source}
                        onChange={(name,value)=>(handleInputChangeNameVal<FormAttachmentAttr>(name, value, props.inputs, props.setInputs))}
                        isClear={isClear}
                        xs={10}
                        toolTip={
                          <span>
                            文字列<br/>
                            <TextFieldListDescription />
                          </span>
                        }
                      />

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="start" sx={{mt:1}}>
                <Grid width="150px" alignItems="start">
                  <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
                </Grid>
                <Grid sx={{flexGrow:1}}>

                  <Pjdb1FileSelect
                    label="ファイル"
                    name="file" value={props.inputs.file ? [props.inputs.file]:[]}
                    onChange={(name,value)=>(handleInputChangeFile<FormAttachmentAttr>(name, value, props.inputs, props.setInputs))}
                    accept={{'application/zip': ['.zip']}} multiple={false}
                    err={fileErr.err} errMsg={fileErr.errMsg}
                    xs={8}
                    toolTip={
                      <span>
                        以下のフォルダ構成のzipファイルのみアップロード可能<br />
                        zip内フォルダ構成<br />
                        ┣&nbsp;image<br />
                        ┃┣&nbsp;main<br />
                        ┃┃┗&nbsp;メイン画像 ※必須、1枚、外観のわかる画像<br />
                        ┃┗&nbsp;sub<br />
                        ┃&nbsp;&nbsp;┗ サブ画像 任意、0枚以上、その他補足画像<br />
                        ┗&nbsp;urdf<br />
                        &nbsp;&nbsp;┗ URDFファイル 任意、1枚<br /><br />
                        ※jpg、png、svgの画像をアップロード可能
                      </span>
                    }
                  />

                </Grid>
              </Grid>
            </form>

            <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>接続可能ハンド選択</InputLabel>

              <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
                <Grid size={{xs:6}} sx={{pt:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象ハンド検索</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          下記に製品名を入力して候補を検索<br />
                          製品カードをクリックでハンド詳細ページに遷移<br />
                          製品カードの接続可能をONにして選択済みハンドに移動
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>

                  <Box sx={{ width: 0.8 }}>

                    <form onSubmit={(e)=>{
                      e.preventDefault();
                      changeHandAutoComp(null);
                    }}>
                      <TextField
                        label="製品名、製造者、型番、特徴などを入力"
                        name="handSearchKeyword" value={handSearchKeyword}
                        variant="outlined" size="small"
                        margin="dense" fullWidth
                        onChange={(e)=>{
                          changeHandAutoComp(e);
                        }}
                      />
                    </form>

                  </Box>

                  <Box sx={{ mt:0, pr:4 }}>
                    {handCandidateList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} key={row.attr.handId} style={{ textDecoration:'none' }}
                      onClick={()=>{
                        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_DETAIL.TAB_IDX });
                        dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
                      }}>

                        <Card sx={{ display: 'flex', height:100, mt:2 }} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180 }}
                            image={row.url?.image[0]}
                            alt={row.attr.handInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.handInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.handInfo.producer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.handId}
                                  checked={props.inputs.handIds.includes(row.attr.handId)}
                                  onChange={changeHandSwitch}
                                />
                              }
                              label="接続可能"
                              onClick={(e)=>{
                                e.stopPropagation();
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>

                </Grid>
                <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                  <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済みハンド</InputLabel>

                  <Box sx={{ mt:"74px", pr:2 }}>

                    {handSelectedList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} key={row.attr.handId} style={{ textDecoration:'none' }}
                      onClick={()=>{
                        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_DETAIL.TAB_IDX });
                        dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
                      }}>
                        <Card sx={{ display: 'flex', height:100, mt:2 }} key={row.attr.handId} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180 }}
                            image={row.url?.image[0]}
                            alt={row.attr.handInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.handInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.handInfo.producer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.handId}
                                  checked={props.inputs.handIds.includes(row.attr.handId)}
                                  onChange={changeHandSwitch}
                                />
                              }
                              label="接続可能"
                              onClick={(e)=>{
                                e.stopPropagation();
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>

                </Grid>
              </Grid>
            </Box>

            <Box sx={{mt:3.5,borderTop:"1px solid rgba(0, 0, 0, 0.23)"}}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>接続可能アーム選択</InputLabel>

              <Grid container spacing={2} alignItems="start" sx={{px:3,py:0,mt:1,alignItems:"stretch"}}>
                <Grid size={{xs:6}} sx={{pt:2}}>
                  <Grid container spacing={2} alignItems="start" sx={{p:0,m:0}}>
                    <Grid sx={{p:"0 !important"}}>
                      <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>対象アーム検索</InputLabel>
                    </Grid>
                    <Grid sx={{pt:"0 !important"}}>
                      <Pjdb1ToolTip toolTip={
                        <span>
                          下記に製品名を入力して候補を検索<br />
                          製品カードをクリックでアーム詳細ページに遷移<br />
                          製品カードの接続可能をONにして選択済みアームに移動
                        </span>
                      } sx={{p:0}} />
                    </Grid>
                  </Grid>

                  <Box sx={{ width: 0.8 }}>

                    <form onSubmit={(e)=>{
                      e.preventDefault();
                      changeArmAutoComp(null);
                    }}>
                      <TextField
                        label="製品名、製造者、型番、特徴などを入力"
                        name="armSearchKeyword" value={armSearchKeyword}
                        variant="outlined" size="small"
                        margin="dense" fullWidth
                        onChange={(e)=>{
                          changeArmAutoComp(e);
                        }}
                      />
                    </form>

                  </Box>

                  <Box sx={{ mt:0, pr:4 }}>
                    {armCandidateList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.ARM_DETAIL.URL}/${row.attr.armId}`} key={row.attr.armId} style={{ textDecoration:'none' }}
                      onClick={()=>{
                        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.ARM_DETAIL.TAB_IDX });
                        dispatch({ type: CONTEXT_ACTION_TYPE.ARM_DETAIL , payload: {info:row} });
                      }}>

                        <Card sx={{ display: 'flex', height:100, mt:2 }} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180 }}
                            image={row.url?.image[0]}
                            alt={row.attr.armInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.armInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.armInfo.manufacturer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.armId}
                                  checked={props.inputs.armIds.includes(row.attr.armId)}
                                  onChange={changeArmSwitch}
                                />
                              }
                              label="接続可能"
                              onClick={(e)=>{
                                e.stopPropagation();
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>

                </Grid>
                <Grid size={{xs:6}} sx={{borderLeft:"1px solid rgba(0, 0, 0, 0.23)",pt:2,pl:2}}>
                  <InputLabel variant='standard' sx={{pt:0,color: 'text.primary'}}>選択済みアーム</InputLabel>

                  <Box sx={{ mt:"74px", pr:2 }}>

                    {armSelectedList.map(row =>(
                      <Link to={`${EQUIPMENT_URLS.ARM_DETAIL.URL}/${row.attr.armId}`} key={row.attr.armId} style={{ textDecoration:'none' }}
                      onClick={()=>{
                        dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.ARM_DETAIL.TAB_IDX });
                        dispatch({ type: CONTEXT_ACTION_TYPE.ARM_DETAIL , payload: {info:row} });
                      }}>
                        <Card sx={{ display: 'flex', height:100, mt:2 }} key={row.attr.armId} >
                          <CardMedia
                            component="img"
                            sx={{ width: 180 }}
                            image={row.url?.image[0]}
                            alt={row.attr.armInfo.name}
                          />
                          <CardContent sx={{p:1}}>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                              製品名：{row.attr.armInfo.name}
                            </Typography>
                            <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5,color:"#888"}}>
                              製造者：{row.attr.armInfo.manufacturer}
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  name={row.attr.armId}
                                  checked={props.inputs.armIds.includes(row.attr.armId)}
                                  onChange={changeArmSwitch}
                                />
                              }
                              label="接続可能"
                              onClick={(e)=>{
                                e.stopPropagation();
                              }}
                            />
                          </CardContent>
                        </Card>
                      </Link>
                    ))}
                  </Box>

                </Grid>
              </Grid>
            </Box>

        </Card>
      </Container>
      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`製品情報を${props.regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            if(props.regMode){
              void registData();
            }else{
              void updateData();
            }
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${props.regMode?'登録':'更新'}確認`}
        message={`アタッチメント情報を${props.regMode?'登録':'更新'}しますか？`}
        confirmButton={`${props.regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />
    </>
  );
}
