import {equalAdminRoleRule, equalCreatorRule, EqualCreatorRuleParams, RoleRuleParams} from "./rules";
import {WorkClassDocument, WorkInstanceDocument} from "../types/pj1dbApiWork";

export const policyTypes = {
  // Class
  showClass: 'showClass',
  registerClass: 'registerClass',
  editClass: 'editClass',
  // Instance
  showInstance: 'showInstance',
  registerInstance: 'registerInstance',
  editInstance: 'editInstance',
  // Model
  showModel: 'showModel',
  downloadModelFile: 'downloadModelFile',
  registerModel: 'registerModel',
  editModel: 'editModel',
} as const

export type PolicyType = typeof policyTypes[keyof typeof policyTypes]

export const policies = {
  // Class
  [policyTypes.showClass]: {
    can: () => true,
  },
  [policyTypes.registerClass]: {
    can: () => true,
  },
  [policyTypes.editClass]: {
    can: (params: RoleRuleParams & EqualCreatorRuleParams<WorkClassDocument>) => equalAdminRoleRule(params) || equalCreatorRule(params),
  },
  // Instance
  [policyTypes.showInstance]: {
    can: () => true,
  },
  [policyTypes.registerInstance]: {
    can: () => true,
  },
  [policyTypes.editInstance]: {
    can: (params: RoleRuleParams & EqualCreatorRuleParams<WorkInstanceDocument>) => equalAdminRoleRule(params) || equalCreatorRule(params),
  },
  // Model
  [policyTypes.showModel]: {
    can: () => true,
  },
  [policyTypes.downloadModelFile]: {
    can: () => true,
  },
  [policyTypes.registerModel]: {
    can: () => true,
  },
  [policyTypes.editModel]: {
    can: () => true,
  },
} as const satisfies Record<PolicyType, { can: (...params: any[]) => boolean }>

export type Policies = typeof policies
