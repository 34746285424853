import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";

export interface ContentVolumeProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function ContentVolume(props: ContentVolumeProps) {
  return (
    <WithLabel label={workAttrList.contentVolume.label}>
      <Pjdb1TextField
        label={workAttrList.contentVolume.label}
        unit="ℓ"
        name={workAttrList.contentVolume.key}
        value={props.value ?? ''}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        isClear={props.isClear}
        xs={4}
        toolTip="単位[ℓ]"
        inputProps={{pattern: "[0-9.]*"}}
      />
    </WithLabel>
  )
}
