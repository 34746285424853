import React from "react";
import {Stack} from "@mui/material";
import WithLabel from "./WithLabel";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";
import {WORK_INPUT_FILE_TYPES, WORK_MODEL_TYPES} from "../../../../../constants/constants";
import Pjdb1FileSelect from "../../../../../components/inputs/Pjdb1FileSelect";
import {workAttrList} from "../../../types/workForm";

export interface ModelProps {
  modelType: string;
  // eslint-disable-next-line react/require-default-props
  onChangeModelType?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;

  files: File[] | null;
  fileErr: boolean;
  fileErrMsg: string;
  // eslint-disable-next-line react/require-default-props
  onChangeFile?: (name: string, value: File[]) => void;

  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function Model(props: ModelProps) {
  return (
    <WithLabel label={workAttrList.modelType.label}>
      <Stack>
        <Pjdb1RadioGroup
          name={workAttrList.modelType.key}
          value={props.modelType}
          onChange={props.onChangeModelType}
          radios={Object.values(WORK_MODEL_TYPES).map((row) => ({btnKey: row.key, btnLbl: row.label}))}
          required={props.required}
          disabled={props.disabled}
          isClear={props.isClear}
          toolTip='"作成済みのモデルを登録"を選択した場合、入力ファイル方式も選択してください'
        />
        <Pjdb1FileSelect
          label={workAttrList.modelFile.label}
          name={workAttrList.modelFile.key}
          value={props.files ?? []}
          onChange={props.onChangeFile}
          webkitdirectory
          multiple
          accept={{
            'application/zip': ['.zip'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'application/octet-stream': ['.obj', '.mtl', '.ob', '.jmtl', '.usdz'],
          }}
          err={props.fileErr}
          errMsg={props.fileErrMsg}
          xs={8}
          toolTip={modelTypeTooltip[props.modelType as keyof typeof modelTypeTooltip]}
        />
      </Stack>
    </WithLabel>
  )
}

const modelTypeTooltip = {
  [WORK_MODEL_TYPES.REGISTRATION.key]: "自身で作成したモデルを含むフォルダの中身をアップロード",
  [WORK_MODEL_TYPES.CREATION.key]: "携帯などで撮影した画像や動画をを含むフォルダの中身をアップロード",
} as const;
