/* eslint-disable react/require-default-props */
import React,{useState, useRef, useEffect} from 'react';
import { RadioGroup, Radio, FormControlLabel, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import Pjdb1ToolTip from "./Pjdb1ToolTip";

interface Props {
  name: string;
  value: string;
  radios: {btnKey: string, btnLbl:string, disabled?: boolean}[];
  disabledRadios?: string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  toolTip?: string|JSX.Element;
  isClear?: boolean;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1RadioGroup]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1RadioGroup]:areEqual(next)',nextProps);
  let retEqual:boolean = prevProps.value === nextProps.value;
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  if (nextProps.disabledRadios !== undefined && prevProps.disabledRadios !== nextProps.disabledRadios) retEqual = false;
  if (nextProps.disabled !== undefined && prevProps.disabled !== nextProps.disabled) retEqual = false;
  // console.log(`[${prevProps.name}]`,'[Pjdb1RadioGroup]:areEqual(dif) val:',retEqual);
  return retEqual;
}

/**
 * ラジオボタン
 * @author TAW j.sano
 * @param props
 * @returns
 */
// eslint-disable-next-line arrow-body-style
export default React.memo((props: Props) => {

  // 入力チェック用
  const validRef = useRef<HTMLInputElement>(null);
  const [validRequired, setValidRequired] = useState(true);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleChange = () => {
    setValidRequired(false);
    setTimeout(() => {
      setErr(!validRef.current?.checkValidity());
    }, 100);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeValid = () => {
    // console.log("handleChangeValid");
  }
  const handleInvalid = () =>{
    setErr(true);
    setErrMsg("いずれかひとつを選択してください。");
  }

  // isClear = trueになったときはErr状態解除
  useEffect(()=>{
    if(props.isClear) {
      setErr(false);
      setErrMsg("");
    }
  },[props.isClear]);

  // console.log(`[${props.name}]`,'[Pjdb1RadioGroup]:rendering!!',props);
  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{flexWrap:"nowrap"}}>
        <Grid sx={{pt:"6px !important"}}>
          <RadioGroup
            row
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          >
            {props.radios.map((radio) => (
              <FormControlLabel key={radio.btnKey} value={radio.btnKey} label={radio.btnLbl}
              control={
                <Radio
                  inputRef={validRef}
                  onChange={handleChange}
                  onInvalid={handleInvalid}
                  sx={err ? {color:"error.main"}:{}}
                  required={props.required !== undefined && validRequired? props.required : false}
                  disabled={radio.disabled !== undefined && radio.disabled}
                />
              }
              disabled={
                (props.disabled !== undefined && props.disabled === true) ||
                (props.disabledRadios && props.disabledRadios.includes(radio.btnKey))
              }
              />
            ))}
          </RadioGroup>
        </Grid>
        { props.toolTip !== undefined &&
        <Pjdb1ToolTip toolTip={props.toolTip} />
        }
      </Grid>
      { err &&
        <Typography color="error" sx={{fontSize:"0.75rem"}}>
          {errMsg}
        </Typography>
      }
    </>
  );
},areEqual);
