import { RobocipDbDocument } from "./pj1dbApi";
import { FormFingertipAttr } from './fingertipForm'
import { AttrListRowType } from "./handForm";
import { HAND_FINGER_TYPES, HAND_MOUNT_PATTERN } from "../../../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * fingertip APIのレスポンス型の定義
 */

export interface Thickness {
  inner?: number;
  outer?: number;
}

export interface FingertipInfo {
  name: string;
  number?: string;
  producer: string;
  weight?: number;
  setFingertip: boolean;
  fingertipType: string;
  elasticFingertip: boolean;
  material?: string;
}
export interface ProductInfo {
  description?: string;
  keyword?: string[];
  source?: string[];
}
export interface FingertipShape {
  mountPattern: string;
  thickness: Thickness;
}

export interface FingertipAttr extends RobocipDbDocument {
  _id: string;
  fingertipId: string;
  public: boolean;
  handIds: string[];
  fingertipInfo: FingertipInfo
  productInfo: ProductInfo;
  fingertipShape: FingertipShape[];
}

export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface FingertipDocument {
  attr: FingertipAttr;
  url?: ObjUrl;
}

// FingertipDocumentの初期値
export const fingertipInitDoc:FingertipDocument = {
  attr: {
    _id: "",
    fingertipId: "",
    public:false,
    handIds:[],
    fingertipInfo: {
      name: "",
      number: "",
      producer: "",
      weight: 0,
      setFingertip: false,
      fingertipType: "",
      elasticFingertip: false,
      material: "",
    },
    productInfo: {
      description: "",
      keyword: [""],
      source: [""],
    },
    fingertipShape: [
      {
        mountPattern: "",
        thickness: {
          inner: 0,
          outer: 0,
        },
      }
    ],
    registrationDate: 0,
    registrant: "",
    domain:"",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

/**
 * 爪API 登録用タイプ
 */
export interface ApiFingertipRegist {
  zipURI: string;
  public: boolean;
  handIds: string[];
  fingertipInfo: FingertipInfo
  productInfo: ProductInfo;
  fingertipShape: FingertipShape[];
}

export interface ApiFingertipUpdate {
  id:string;
  set:{
    zipURI?: string;
    public?: boolean;
    handIds?: string[];
    fingertipInfo?: FingertipInfo
    productInfo?: ProductInfo;
    fingertipShape?: FingertipShape[];
  }
}

// --------------------------------------------------------------------------------------
function makeApiFingertipInfoFromForm(inputs:FormFingertipAttr):FingertipInfo{
  return {
    name: inputs.name,
    ...(inputs.number ? {number: inputs.number} : {}),
    producer: inputs.producer,
    ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
    setFingertip: inputs.setFingertip,
    fingertipType: inputs.fingertipType,
    elasticFingertip: inputs.elasticFingertip,
    ...(inputs.material ? {material: inputs.material} : {}),
  }
}
function makeApiProductInfoFromForm(inputs:FormFingertipAttr):ProductInfo{
  return {
    ...(inputs.description ? {description: inputs.description} : {}),
    ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
    ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
  }
}
function makeApiFingertipShapeFromForm(inputs:FormFingertipAttr):FingertipShape[]{
  const retHs:FingertipShape[] = [];
  inputs.fingertipShape.forEach(fs => {
    retHs.push(
      {
        mountPattern: fs.mountPattern,
        thickness:{
          ...(fs.thicknessInner ? {inner: parseFloat(fs.thicknessInner)} : {}),
          ...(fs.thicknessOuter ? {outer: parseFloat(fs.thicknessOuter)} : {}),
        }
      }
    );
  });
  return retHs;
}

export function makeApiFingertipRegistFromForm(
  inputs:FormFingertipAttr,zipURI:string
):ApiFingertipRegist{
  return {
    zipURI,
    public: inputs.public,
    handIds: inputs.handIds,
    fingertipInfo: makeApiFingertipInfoFromForm(inputs),
    productInfo: makeApiProductInfoFromForm(inputs),
    fingertipShape: makeApiFingertipShapeFromForm(inputs),
  }
}

export function makeApiFingertipUpdateFromForm(
  inputs:FormFingertipAttr,argZipURI:string
):ApiFingertipUpdate{
  return {
    id: inputs.fingertipId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      public: inputs.public,
      handIds: inputs.handIds,
      fingertipInfo: makeApiFingertipInfoFromForm(inputs),
      productInfo: makeApiProductInfoFromForm(inputs),
      fingertipShape: makeApiFingertipShapeFromForm(inputs),
    }
  }
}

export function makeFormFingertipFromDocument(
  info:FingertipDocument
):FormFingertipAttr{
  return {
    fingertipId: info.attr.fingertipId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    domain: String(info.attr.domain),
    setFingertip: info.attr.fingertipInfo.setFingertip,
    public: info.attr.public,
    name: info.attr.fingertipInfo.name ? info.attr.fingertipInfo.name : "",
    producer: info.attr.fingertipInfo.producer ? info.attr.fingertipInfo.producer : "",
    number: info.attr.fingertipInfo.number ? info.attr.fingertipInfo.number : "",
    weight: info.attr.fingertipInfo.weight ? String(info.attr.fingertipInfo.weight) : "",
    fingertipType: info.attr.fingertipInfo.fingertipType,
    elasticFingertip: info.attr.fingertipInfo.elasticFingertip,
    material: info.attr.fingertipInfo.material ? info.attr.fingertipInfo.material : "",
    keyword: info.attr.productInfo.keyword ? info.attr.productInfo.keyword : [""],
    description: info.attr.productInfo.description ? info.attr.productInfo.description : "",
    source: info.attr.productInfo.source ? info.attr.productInfo.source : [""],
    fingertipShape: info.attr.fingertipShape.map(fs => (
      {
        mountPattern: fs.mountPattern,
        ...(fs.thickness.inner ? {thicknessInner: String(fs.thickness.inner)} : {}),
        ...(fs.thickness.outer ? {thicknessOuter: String(fs.thickness.outer)} : {}),
      }
    )),
    file: null,
    zipURI: "",
    handIds: info.attr.handIds,
  }
}

// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeFingertipAttrList(
  info:FingertipDocument,
  isSimplify:boolean
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  if(!isSimplify){
    attrList.push({key:"setFingertip", class:"基本情報", attr:"メーカー製か否か",
      value:info.attr.fingertipInfo.setFingertip?"メーカー製":"自作カスタム爪"});
    attrList.push({key:"public", class:"", attr:"公開可否",
      value:info.attr.public?"公開爪":"非公開爪"});
  }
  attrList.push({key:"name", class:isSimplify?"基本情報":"", attr:"製品名", value:info.attr.fingertipInfo.name});
  attrList.push({key:"producer", class:"", attr:"製造者", value:info.attr.fingertipInfo.producer});
  if(info.attr.fingertipInfo.number){
    attrList.push({key:"number", class:"", attr:"型番", value:info.attr.fingertipInfo.number });
  }
  if(!isSimplify){
    if(info.attr.fingertipInfo.weight){
      attrList.push({key:"weight", class:"", attr:"爪質量",
        value : `${info.attr.fingertipInfo.weight.toLocaleString()} kg` });
    }
    attrList.push({key:"fingertipType", class:"", attr:"爪タイプ", value:
      String(HAND_FINGER_TYPES.find((row)=>(row.key===info.attr.fingertipInfo.fingertipType))?.label)
    });
    attrList.push({key:"elasticFingertip", class:"", attr:"柔軟爪か否か",
      value:info.attr.fingertipInfo.elasticFingertip?"柔軟爪":"柔軟爪ではない" });
    if(info.attr.fingertipInfo.material){
      attrList.push({key:"material", class:"", attr:"材質", value:info.attr.fingertipInfo.material });
    }
    let divLabel = "製品情報";
    if(info.attr.productInfo.keyword){
      attrList.push({key:"keyword", class: divLabel, attr:"キーワード",
        value:info.attr.productInfo.keyword.join("、")});
      divLabel = "";
    }
    if(info.attr.productInfo.description){
      attrList.push({key:"description", class: divLabel, attr:"説明",
        value:String(info.attr.productInfo.description.replace(/\n/g,'<br>')) });
      divLabel = "";
    }
    if(info.attr.productInfo.source){
      let sourceStr = "";
      info.attr.productInfo.source.forEach((src)=>{
        if(sourceStr) sourceStr += "<br />";
        sourceStr += getUrlHtml(src);
      });
      attrList.push({key:"source", class: divLabel, attr:"その他情報", value:sourceStr});
      divLabel = "";
    }
    // ソートリスト作成
    const fingertipShapeList:FingertipShape[] =
      info.attr.fingertipShape.sort((a,b) => a.mountPattern.localeCompare(b.mountPattern));
    // 同一複数マウントパターンに対応した表示
    let mountPatternIdx = 0;
    let brkMountPattern = "";
    fingertipShapeList.forEach((row,idx)=>{
      const mountPatternCount = fingertipShapeList.filter(row2=>row2.mountPattern===row.mountPattern).length;

      let mountPattern = String(HAND_MOUNT_PATTERN.find((row2)=>(row2.key===row.mountPattern))?.label);
      if(mountPatternCount>1){
        if(brkMountPattern!==row.mountPattern) mountPatternIdx = 0;
        brkMountPattern = row.mountPattern;
        mountPatternIdx += 1;
        mountPattern += `&nbsp;(${mountPatternIdx})`
      }
      let shapeStr = "";
      if(row.thickness.inner){
        shapeStr += `外径把持方向の厚さ：${row.thickness.inner.toLocaleString()} mm<br />`;
      }
      if(row.thickness.outer){
        shapeStr += `内径把持方向の厚さ：${row.thickness.outer.toLocaleString()} mm<br />`;
      }
      attrList.push({key:`fingertipShape${String(idx)}`, class: idx===0 ? "形状" : "",
        attr:`取付け方向&nbsp;${mountPattern}`, value:shapeStr});
    });

  }

  return attrList;
}