/**
 * アタッチメント一覧検索条件
 */
export interface FormAttachmentSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:string,
}

/**
 * アタッチメント登録フォーム用タイプ
 */
export interface FormAttachmentAttr {
  attachmentId: string;
  registrationDate: string;
  registrant: string;
  domain: string;
  setAttachment: boolean;
  public: boolean;
  name: string;
  manufacturer: string;
  number: string;
  weight: string;
  handCount: string;
  keyword: string[];
  description: string;
  source: string[];
  file: File|null;
  zipURI: string;
  armIds: string[];
  handIds: string[];
}

// アタッチメント登録フォーム 初期値
export const attachmentInitInputs:FormAttachmentAttr = {
  attachmentId: "",
  registrationDate: "",
  registrant: "",
  domain: "",
  setAttachment: false,
  public: false,
  name: "",
  manufacturer: "",
  number: "",
  weight: "",
  handCount: "",
  keyword: [""],
  description: "",
  source: [""],
  file: null,
  zipURI: "",
  armIds: [""],
  handIds: [""],
}