/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-shadow */

import React, { useEffect, useState, Suspense } from 'react';
// import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Plane } from '@react-three/drei';
import  URDFLoader ,{URDFRobot} from 'urdf-loader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';

interface Props {
  urdfUrl:string;
}

/**
 * URDFファイル表示
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props: Props) => {
  const [robot, setRobot] = useState<URDFRobot>();

  useEffect(() => {
    if (props.urdfUrl) {
      const loader = new URDFLoader();
      loader.loadMeshCb = (path, manager, done) => {
        if (path.endsWith('.dae')) {
          new ColladaLoader(manager).load(
            path,
            (collada) => {
              const object = collada.scene;
              done(object);
              // console.log(object);
            },
            undefined,
            (err) => {
              console.error(`Error loading DAE file from ${path}:`, err);
              // done(null, err);
              // done(new THREE.Object3D);
            }
          );
        } else {
          console.error(`Unsupported file type for path: ${path}`);
          // done(null, new Error(`Unsupported file type for path: ${path}`));
          // done(new THREE.Object3D);
        }
      };
      loader.load(props.urdfUrl, (robot) => {
        setRobot(robot)
        // console.log(robot);
      });
    }
  }, [props.urdfUrl]);

  console.log("props.urdfUrl",props.urdfUrl);

  return (
    <Canvas shadows={{ type: 2 }} camera={{ position: [-1.7, 1, 1.0], fov: 40 }}>
      <directionalLight
        castShadow
        intensity={1}
        color={0xffffff}
        position={[5, 30, 5]}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
      />
      <ambientLight intensity={0.8} color={0xffffff} />
      <gridHelper args={[10, 10]} />
      <axesHelper />
      <Suspense fallback={null}>
        <OrbitControls enableZoom={true} />
        <group>
          <mesh
            castShadow
            receiveShadow
            position={[0, 0, 0]}
            rotation={[-0.5 * Math.PI, 0, Math.PI]}
            scale={1}
          >
            {robot && <primitive object={robot} />}
          </mesh>
          <Plane receiveShadow rotation={[-Math.PI / 2, 0, 0]} args={[1000, 1000]} scale={30}>
            <shadowMaterial opacity={0.25} />
          </Plane>
        </group>
      </Suspense>
    </Canvas>
  );
});