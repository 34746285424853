import { RobocipDbDocument } from "./pj1dbApi";
import { FormArmAttr } from './armForm'
import { AttrListRowType } from "./handForm";
import { ARM_REACH_POINT } from "../../../constants/constants";

// --------------------------------------------------------------------------------------
/**
 * arm APIのレスポンス型の定義
 */
export interface Reach {
  maximumReach: number;
  point?: string;
}
export interface MotionAngle {
  min: number[];
  max: number[];
}
export interface Air {
  piping?: string;
  pressure?: number;
}

export interface ArmInfo {
  name: string;
  number?: string;
  manufacturer: string;
  numberOfAxes: number;
  weight?: number;
  cobot: boolean;
}
export interface ProductInfo {
  keyword?: string[];
  description?: string;
  source?: string[];
}
export interface ArmPerformance {
  payload: number;
  reach: Reach;
  motionAngle: MotionAngle;
  angularSpeed?: number[];
  collaborativeSpeed?: number;
  nonCollaborativeSpeed: number;
  repeatability: number;
  wristLoadMoment: number[];
  wristLoadInertia: number[];
}
export interface Equipment {
  applicationCable?: string[];
  air: Air;
}
export interface InterFace {
  mechanical?: string;
}

export interface ArmAttr extends RobocipDbDocument {
  _id: string;
  armId: string;
  armInfo: ArmInfo;
  productInfo: ProductInfo;
  armPerformance: ArmPerformance;
  equipment: Equipment;
  interface: InterFace;
}
export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface ArmDocument {
  attr: ArmAttr;
  url?: ObjUrl;
}

// ArmDocumentの初期値
export const armInitDoc:ArmDocument = {
  attr: {
    _id: "",
    armId: "",
    armInfo: {
      name: "",
      manufacturer: "",
      numberOfAxes: 0,
      cobot: false,
    },
    productInfo: {},
    armPerformance: {
      payload: 0,
      reach: {
        maximumReach: 0,
      },
      motionAngle: {
        min: [],
        max: [],
      },
      nonCollaborativeSpeed: 0,
      repeatability: 0,
      wristLoadMoment: [],
      wristLoadInertia: [],
    },
    equipment: {
      air:{
        piping: "",
        pressure: 0,
      },
    },
    interface:{},
    registrationDate: 0,
    registrant: "",
    domain:"",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

// --------------------------------------------------------------------------------------

/**
 * アームAPI 登録用タイプ
 */
export interface ApiArmRegist {
  zipURI: string;
  armInfo: ArmInfo;
  productInfo: ProductInfo;
  armPerformance: ArmPerformance;
  equipment: Equipment;
  interface: InterFace;
}

export interface ApiArmUpdate {
  id:string;
  set:{
    zipURI?: string;
    armInfo: ArmInfo;
    productInfo: ProductInfo;
    armPerformance: ArmPerformance;
    equipment: Equipment;
    interface: InterFace;
  }
}

// --------------------------------------------------------------------------------------
export function makeApiArmRegistFromForm(
  inputs:FormArmAttr,zipURI:string
):ApiArmRegist{
  return {
    zipURI,
    armInfo:{
      name: inputs.name,
      ...(inputs.number ? {number: inputs.number} : {}),
      manufacturer: inputs.manufacturer,
      numberOfAxes: parseFloat(inputs.numberOfAxes),
      ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
      cobot: inputs.cobot,
    },
    productInfo:{
      ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
      ...(inputs.description ? {description: inputs.description} : {}),
      ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
    },
    armPerformance:{
      payload: parseFloat(inputs.payload),
      reach: {
        maximumReach: parseFloat(inputs.reachMaximumReach),
        ...(inputs.reachPoint ? {point: inputs.reachPoint} : {}),
      },
      motionAngle: {
        min: inputs.motionAngleMin.map((val)=>(parseFloat(val)))
          .filter((data,idx)=> idx < parseInt(inputs.numberOfAxes, 10)),
        max: inputs.motionAngleMax.map((val)=>(parseFloat(val)))
          .filter((data,idx)=> idx < parseInt(inputs.numberOfAxes, 10)),
      },
      angularSpeed: inputs.angularSpeed.map((val)=>(parseFloat(val)))
        .filter((data,idx)=> idx < parseInt(inputs.numberOfAxes, 10)),
      ...(inputs.collaborativeSpeed ? {collaborativeSpeed: parseFloat(inputs.collaborativeSpeed)} : {}),
      nonCollaborativeSpeed: parseFloat(inputs.nonCollaborativeSpeed),
      repeatability: parseFloat(inputs.repeatability),
      wristLoadMoment: inputs.wristLoadMoment.filter(val => val !== '').map((val)=>(parseFloat(val))),
      wristLoadInertia: inputs.wristLoadInertia.filter(val => val !== '').map((val)=>(parseFloat(val))),
    },
    equipment:{
      ...(inputs.applicationCable.filter(val => val !== '').length > 0 ? {applicationCable: inputs.applicationCable.filter(val => val !== '')} : {}),
      air: {
        ...(inputs.airPiping ? {piping: inputs.airPiping} : {}),
        ...(inputs.airPressure ? {pressure: parseFloat(inputs.airPressure)} : {}),
      },
    },
    interface:{
      ...(inputs.mechanical ? {mechanical: inputs.mechanical} : {}),
    },
  };
}
export function makeApiArmUpdateFromForm(
  inputs:FormArmAttr,argZipURI:string
):ApiArmUpdate{
  return {
    id: inputs.armId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      armInfo:{
        name: inputs.name,
        ...(inputs.number ? {number: inputs.number} : {}),
        manufacturer: inputs.manufacturer,
        numberOfAxes: parseFloat(inputs.numberOfAxes),
        ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
        cobot: inputs.cobot,
      },
      productInfo:{
        ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
        ...(inputs.description ? {description: inputs.description} : {}),
        ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
      },
      armPerformance:{
        payload: parseFloat(inputs.payload),
        reach: {
          maximumReach: parseFloat(inputs.reachMaximumReach),
          ...(inputs.reachPoint ? {point: inputs.reachPoint} : {}),
        },
        motionAngle: {
          min: inputs.motionAngleMin.map((val)=>(parseFloat(val))),
          max: inputs.motionAngleMax.map((val)=>(parseFloat(val))),
        },
        angularSpeed: inputs.angularSpeed.map((val)=>(parseFloat(val))),
        ...(inputs.collaborativeSpeed ? {collaborativeSpeed: parseFloat(inputs.collaborativeSpeed)} : {}),
        nonCollaborativeSpeed: parseFloat(inputs.nonCollaborativeSpeed),
        repeatability: parseFloat(inputs.repeatability),
        wristLoadMoment: inputs.wristLoadMoment.filter(val => val !== '').map((val)=>(parseFloat(val))),
        wristLoadInertia: inputs.wristLoadInertia.filter(val => val !== '').map((val)=>(parseFloat(val))),
      },
      equipment:{
        ...(inputs.applicationCable.filter(val => val !== '').length > 0 ? {applicationCable: inputs.applicationCable.filter(val => val !== '')} : {}),
        air: {
          ...(inputs.airPiping ? {piping: inputs.airPiping} : {}),
          ...(inputs.airPressure ? {pressure: parseFloat(inputs.airPressure)} : {}),
        },
      },
      interface:{
        ...(inputs.mechanical ? {mechanical: inputs.mechanical} : {}),
      },
    }
  };
}

export function makeFormArmFromDocument(
  info:ArmDocument
):FormArmAttr{
  return {
    armId: info.attr.armId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    domain: String(info.attr.domain),
    name: info.attr.armInfo.name,
    manufacturer: info.attr.armInfo.manufacturer,
    number: info.attr.armInfo.number ? info.attr.armInfo.number : "",
    numberOfAxes: String(info.attr.armInfo.numberOfAxes),
    weight: info.attr.armInfo.weight ? String(info.attr.armInfo.weight) : "",
    cobot: info.attr.armInfo.cobot,
    keyword: info.attr.productInfo.keyword ? info.attr.productInfo.keyword : [],
    description: info.attr.productInfo.description ? info.attr.productInfo.description : "",
    source: info.attr.productInfo.source ? info.attr.productInfo.source : [],
    payload: String(info.attr.armPerformance.payload),
    reachMaximumReach: String(info.attr.armPerformance.reach.maximumReach),
    reachPoint: info.attr.armPerformance.reach.point ? info.attr.armPerformance.reach.point : "",
    motionAngleMin: info.attr.armPerformance.motionAngle.min ?
      info.attr.armPerformance.motionAngle.min.map((val)=>(String(val))) : [],
    motionAngleMax: info.attr.armPerformance.motionAngle.max ?
      info.attr.armPerformance.motionAngle.max.map((val)=>(String(val))) : [],
    angularSpeed: info.attr.armPerformance.angularSpeed ?
      info.attr.armPerformance.angularSpeed.map((val)=>(val?String(val):"")) : [],
    collaborativeSpeed: String(info.attr.armPerformance.collaborativeSpeed),
    nonCollaborativeSpeed: String(info.attr.armPerformance.nonCollaborativeSpeed),
    repeatability: String(info.attr.armPerformance.repeatability),
    wristLoadMoment: info.attr.armPerformance.wristLoadMoment.map((val)=>(String(val))),
    wristLoadInertia: info.attr.armPerformance.wristLoadInertia.map((val)=>(String(val))),
    applicationCable: info.attr.equipment.applicationCable ? info.attr.equipment.applicationCable : [],
    airPiping: info.attr.equipment.air.piping ? info.attr.equipment.air.piping : "",
    airPressure: info.attr.equipment.air.pressure ? String(info.attr.equipment.air.pressure) : "",
    mechanical: info.attr.interface.mechanical ? info.attr.interface.mechanical : "",
    file: null,
    zipURI: "",
  }
}
// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeArmAttrList(
  info:ArmDocument
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  attrList.push({key:"name", class:"基本情報", attr:"製品名", value:info.attr.armInfo.name});
  attrList.push({key:"manufacturer", class:"", attr:"製造者", value:info.attr.armInfo.manufacturer});
  if(info.attr.armInfo.number){
    attrList.push({key:"number", class:"", attr:"型番", value:info.attr.armInfo.number });
  }
  attrList.push({key:"numberOfAxes", class:"", attr:"制御軸数",
    value:info.attr.armInfo.numberOfAxes.toLocaleString()});
  if(info.attr.armInfo.weight){
    attrList.push({key:"weight", class:"", attr:"アーム質量",
      value : `${String(info.attr.armInfo.weight.toLocaleString())} kg` });
  }
  attrList.push({key:"cobot", class:"", attr:"協働か否か",
    value:info.attr.armInfo.cobot?"協働ロボット":"非協働ロボット" });
  let divLabel = "製品情報";
  if(info.attr.productInfo.keyword){
    attrList.push({key:"keyword", class: divLabel, attr:"キーワード",
      value:info.attr.productInfo.keyword.join("、")});
    divLabel = "";
  }
  if(info.attr.productInfo.description){
    attrList.push({key:"description", class: divLabel, attr:"説明",
      value:String(info.attr.productInfo.description.replace(/\n/g,'<br>')) });
    divLabel = "";
  }
  if(info.attr.productInfo.source){
    let sourceStr = "";
    info.attr.productInfo.source.forEach((src)=>{
      if(sourceStr) sourceStr += "<br />";
      sourceStr += getUrlHtml(src);
    });
    attrList.push({key:"source", class: divLabel, attr:"その他情報", value:sourceStr});
    divLabel = "";
  }
  attrList.push({key:"payload", class:"性能", attr:"可搬質量",
    value : `${String(info.attr.armPerformance.payload.toLocaleString())} kg` });
  attrList.push({key:"reachMaximumReach", class:"", attr:"最大リーチ",
    value : `${String(info.attr.armPerformance.reach.maximumReach.toLocaleString())} mm` });
  if(info.attr.armPerformance.reach.point){
    attrList.push({key:"reachPoint", class:"", attr:"基準点", value:
      String(ARM_REACH_POINT.find((row)=>(row.key===info.attr.armPerformance.reach.point))?.label)
    });
  }
  for (let i = 0; i < info.attr.armPerformance.motionAngle.min.length; i+=1) {
    let motionStr = "";
    motionStr += `動作角度 最小：${info.attr.armPerformance.motionAngle.min[i].toLocaleString()} degまたはmm<br />`;
    motionStr += `動作角度 最大：${info.attr.armPerformance.motionAngle.max[i].toLocaleString()} degまたはmm<br />`;
    if(info.attr.armPerformance.angularSpeed && info.attr.armPerformance.angularSpeed.length > i && info.attr.armPerformance.angularSpeed[i]){
      motionStr += `最大速度：${info.attr.armPerformance.angularSpeed[i].toLocaleString()} deg/s`;
    }
    attrList.push({key:`motion${String(i)}`, class: "", attr:`&nbsp;&nbsp;&nbsp;&nbsp;軸${String(i+1)}`, value:motionStr});
  }
  if(info.attr.armInfo.cobot){
    if(info.attr.armPerformance.collaborativeSpeed){
      attrList.push({key:"collaborativeSpeed", class:"", attr:"協働時最大速度",
        value : `${String(info.attr.armPerformance.collaborativeSpeed.toLocaleString())} mm/s` });
    }
    attrList.push({key:"nonCollaborativeSpeed", class:"", attr:"非協働時最大速度",
      value : `${String(info.attr.armPerformance.nonCollaborativeSpeed.toLocaleString())} mm/s` });
  }else{
    attrList.push({key:"nonCollaborativeSpeed", class:"", attr:"最大速度",
      value : `${String(info.attr.armPerformance.nonCollaborativeSpeed.toLocaleString())} mm/s` });
  }
  attrList.push({key:"repeatability", class:"", attr:"位置繰り返し精度",
    value : `${String(info.attr.armPerformance.repeatability.toLocaleString())} mm` });

  let wristLoadStr = "";
  info.attr.armPerformance.wristLoadMoment.forEach((src)=>{
    if(wristLoadStr) wristLoadStr += "<br />";
    wristLoadStr += `${String(src.toLocaleString())} Nm`;
  });
  attrList.push({key:"wristLoadMoment", class: "", attr:"手首許容負荷モーメント", value:wristLoadStr});
  wristLoadStr = "";
  info.attr.armPerformance.wristLoadInertia.forEach((src)=>{
    if(wristLoadStr) wristLoadStr += "<br />";
    wristLoadStr += `${String(src.toLocaleString())} kgm^2`;
  });
  attrList.push({key:"wristLoadInertia", class: "", attr:"手首許容負荷慣性モーメント", value:wristLoadStr});
  divLabel = "装置";
  if(info.attr.equipment.applicationCable){
    attrList.push({key:"applicationCable", class: divLabel, attr:"ツール配線",
      value:info.attr.equipment.applicationCable.join("、") });
    divLabel = "";
  }
  if(info.attr.equipment.air.piping){
    attrList.push({key:"airPiping", class: divLabel, attr:"エア配管", value: info.attr.equipment.air.piping});
    divLabel = "";
  }
  if(info.attr.equipment.air.pressure){
    attrList.push({key:"airPressure", class: divLabel, attr:"耐圧",
      value : `${String(info.attr.equipment.air.pressure.toLocaleString())} MPa` });
    divLabel = "";
  }
  if(info.attr.interface.mechanical){
    attrList.push({key:"mechanical", class: "インターフェース", attr:"メカニカルインターフェース対応規格", value: info.attr.interface.mechanical});
  }

  return attrList;
}