/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React,{ useState , useEffect} from "react";

import { InputLabel } from "@mui/material";
import Grid from '@mui/material/Grid2';

import { FormArmAttr } from '../../types/armForm'

import {handleInputChangeNameVal} from "../../../../utils/formUtil"

import Pjdb1TextField from '../../../../components/inputs/Pjdb1TextField';
import Pjdb1ToolTip from "../../../../components/inputs/Pjdb1ToolTip";

interface Props {
  inputs: FormArmAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormArmAttr>>;
  isClear?: boolean;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log('[ArmAxesTable]:areEqual(prev)',prevProps);
  // console.log('[ArmAxesTable]:areEqual(next)',nextProps);
  let retEqual:boolean = prevProps.inputs.numberOfAxes === nextProps.inputs.numberOfAxes;
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  // console.log('[ArmAxesTable]:areEqual(dif) val:',retEqual);
  return retEqual;
}

export default React.memo((props: Props) => {
  // console.log('[ArmAxesTable]:rendering!!',props);
  interface DispRow {
    motionAngleMin: string;
    motionAngleMax: string;
    angularSpeed: string;
  }
  const [dispList, setDispList] = useState<DispRow[]>([]);
  useEffect(()=>{
    const listCount = !Number.isNaN(props.inputs.numberOfAxes) ? parseInt(props.inputs.numberOfAxes, 10) : 0;
    const dispListData:DispRow[] = [];
    for (let i = 0; i < listCount; i+=1) {
      dispListData.push({
        motionAngleMin: props.inputs.motionAngleMin.length > i ? props.inputs.motionAngleMin[i] : "",
        motionAngleMax: props.inputs.motionAngleMax.length > i ? props.inputs.motionAngleMax[i] : "",
        angularSpeed: props.inputs.angularSpeed.length > i ? props.inputs.angularSpeed[i] : "",
      });
    }
    setDispList(dispListData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.inputs.numberOfAxes]);


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,controlName:string,rowIdx:number) => {
    const controlValue:string = event.target.value ? event.target.value : "";
    // console.log("handleChange",controlName,rowIdx,controlValue);
    setDispList((prevList)=>{
      const newList:DispRow[] = prevList.map((row,idx)=>{
        if(idx === rowIdx){
          return {
            motionAngleMin: controlName==="motionAngleMin" ? controlValue : row.motionAngleMin,
            motionAngleMax: controlName==="motionAngleMax" ? controlValue : row.motionAngleMax,
            angularSpeed: controlName==="angularSpeed" ? controlValue : row.angularSpeed,
          };
        }
        return row;
      });

      const newArray = newList.map((row)=>{
        type ObjKey = keyof typeof row;
        return row[(controlName as ObjKey)];
      });
      handleInputChangeNameVal<FormArmAttr>(controlName, newArray , props.inputs, props.setInputs);

      return newList;
    });

  }
  return (
    <>
      {dispList.map((value,index) => (

        <Grid container spacing={2} alignItems="start" key={`row_${index}`}>
          <Grid width="70px">
            <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>軸{index+1}</InputLabel>
          </Grid>
          <Grid width="70px">
            <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>動作角度</InputLabel>
          </Grid>
          <Grid width="200px">
            <Pjdb1TextField
              label="最小値"
              name={`motionAngleMin_${index}`} value={value.motionAngleMin}
              onChange={(e)=>{handleChange(e,"motionAngleMin",index)}}
              isClear={props.isClear}
              required inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="degまたはmm"
            />
          </Grid>
          <Grid width="200px">
            <Pjdb1TextField
              label="最大値"
              name={`motionAngleMax_${index}`} value={value.motionAngleMax}
              onChange={(e)=>{handleChange(e,"motionAngleMax",index)}}
              isClear={props.isClear}
              required inputProps={{pattern: "-?[0-9]*\\.?[0-9]*" }} unit="degまたはmm"
            />
          </Grid>
          <Grid width="180px" sx={{ml:1}}>
            <Pjdb1TextField
              label="最大速度"
              name={`angularSpeed_${index}`} value={value.angularSpeed}
              onChange={(e)=>{handleChange(e,"angularSpeed",index)}}
              isClear={props.isClear}
              inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="deg/s"
            />
          </Grid>
          { index===0 &&
            <Grid>
              <Pjdb1ToolTip toolTip={
                <span>
                  半角数値<br />
                  各軸のうち根元に近い方から順に入力する。
                </span>
              } />
            </Grid>
          }
        </Grid>

      ))}
    </>
  );
},areEqual);