import {useContext} from "react";
import {CONTEXT_ACTION_TYPE, DataStoreContext, DataStoreType} from "../contexts/WorkContext";
import {WORK_FORM_STEP, WorkFormStep} from "../types/workForm";
import SelectWork from "../components/registration/SelectWork";
import WorkForm from "../components/registration/WorkForm";

export default function WorkRegister() {
  const {state, dispatch} = useContext(DataStoreContext);
  console.log('WorkRegisterStep: ', state.workRegistrationPage.step);
  const setStep = (step: WorkFormStep, payload: Partial<DataStoreType['workRegistrationPage']> = {}) => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        ...payload,
        step,
      } satisfies DataStoreType['workRegistrationPage']
    });
  }

  return (
    <>
      {state.workRegistrationPage.step === WORK_FORM_STEP.WORK_SELECT &&
          <SelectWork
              nextStep={(payload: Partial<DataStoreType['workRegistrationPage']> = {}) => setStep(WORK_FORM_STEP.WORK_INPUT, payload)}/>}
      {state.workRegistrationPage.step === WORK_FORM_STEP.WORK_INPUT &&
          <WorkForm
              prevStep={(payload: Partial<DataStoreType['workRegistrationPage']> = {}) => setStep(WORK_FORM_STEP.WORK_SELECT, payload)}/>}
    </>
  )
}
