/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-void */
import { useState, useContext, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  CardMedia,
  InputLabel,
  FormControl,
  Select,
  SelectChangeEvent,
  TextField,
  MenuItem,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Link } from "react-router-dom"

import useNotification from "../../../utils/notificationUtil";
import {findHand } from '../../../utils/awsAmplifyUtil'
import { HandDocument } from '../types/pj1dbApiHand'
import { FormHandSearchCond } from '../types/handForm'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";
import { EQUIPMENT_URLS } from '../../../constants/constants'
import {handleInputChange, handleInputChangeSelect} from "../../../utils/formUtil"

import Pjdb1Button from '../../../components/inputs/Pjdb1Button';

export default function HandList() {
  const { infoMsg , errorMsg} = useNotification();
  const { state, dispatch } = useContext(DataStoreContext);

  const [isLoading, setIsLoading] = useState(false);

  const [ searchCond, setSearchCond] = useState<FormHandSearchCond>({
    keyword:"",
    listCount:0,
    sortOrder:"name",
  });

  const [handList, setHandList] = useState<HandDocument[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");
  const sortList = (handListData:HandDocument[],sortOrder:string):HandDocument[] => {
    let list = handListData;
    if(sortOrder==="name"){
      list = list.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
    }else if(sortOrder==="producer"){
      list = list.sort((a,b) => a.attr.handInfo.producer.localeCompare(b.attr.handInfo.producer));
    }else if(sortOrder==="latest"){
      list = list.sort((a,b) => b.attr.registrationDate - a.attr.registrationDate );
    }
    return list;
  }
  const findHandProc = async () => {
    setIsLoading(true);
    try {
      let cond = {};
      if (searchCond.keyword !== "") {
        cond = {
          "query":{
            "$or": [
              {"handInfo.name":{ "$regex" : searchCond.keyword, "$options":"i" }},
              {"handInfo.number":{ "$regex" : searchCond.keyword, "$options":"i" }},
              {"handInfo.producer":{ "$regex"  :searchCond.keyword, "$options":"i" }},
              {"productInfo.keyword":{ "$regex"  :searchCond.keyword, "$options":"i" }},
              {"productInfo.description":{ "$regex"  :searchCond.keyword, "$options":"i" }},
            ]
          }
        };
      }
      const result = await findHand(cond);
      if(!result.isSuccess){
        errorMsg("検索処理が失敗しました。");
        return;
      }
      // console.log(result);
      if(result.data && Object.entries(result.data).length > 0){
        const handListData:HandDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
        setHandList(sortList(handListData,searchCond.sortOrder));
        setSearchCond({...searchCond,listCount:handListData.length});
        dispatch({ type: CONTEXT_ACTION_TYPE.HAND_LIST, payload: {
          searchCond:{...searchCond,listCount:handListData.length},
          handList:handListData,
        }});
      }else{
        setHandList([]);
        setNotFoundMsg("該当データがありません。");
      }
      infoMsg("検索しました。");
    } finally {
      setIsLoading(false);
    }
  }

  const changeSortOrder = (event:SelectChangeEvent<string>) => {
    handleInputChangeSelect<FormHandSearchCond>(event, "sortOrder",searchCond, setSearchCond);
    setHandList(sortList(handList,event.target.value));
    dispatch({ type: CONTEXT_ACTION_TYPE.HAND_LIST, payload: {
      searchCond:{...searchCond,sortOrder:event.target.value},
      handList,
    }});
  }

  // 検索済みデータの復元
  useEffect(()=>{
    if(state.handListPage.handList.length>0){
      // console.log("HandList","restore from context.",state);
      setSearchCond(state.handListPage.searchCond);
      setHandList(state.handListPage.handList);
    }
    setNotFoundMsg("検索条件を入力し[検索]ボタンを押下してください。");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return (
    <>
      <Container sx={{mt:2}} maxWidth={false}>
        <Card sx={{p:3}}>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>検索条件</Typography>
          <Box sx={{ml:2}}>
            <form onSubmit={(e)=>{
              e.preventDefault();
              void findHandProc();
            }}>
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>検索キーワード</InputLabel>

              <Grid container>
                <Grid size={{xs:5}}>
                  <TextField
                    label="製品名、製造者、型番、特徴などを入力"
                    name="keyword" value={searchCond.keyword}
                    variant="outlined" size="small"
                    margin="dense" fullWidth
                    onChange={(e)=>(handleInputChange<FormHandSearchCond>(e, searchCond, setSearchCond))}
                  />
                </Grid>
              </Grid>
              <Box sx={{mt:1}}>
                <Pjdb1Button
                  label="検索"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                  onClick={()=>{void findHandProc();}}
                />
              </Box>
            </form>
          </Box>
        </Card>
      </Container>

      <Container sx={{p:0, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} maxWidth={false}>
        <Grid container alignItems="end" sx={{mt:2,flexWrap:"nowrap"}} size={{xs:12}}>
          <Grid sx={{flexGrow:1}}>
            <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>検索結果一覧</Typography>
          </Grid>
          <Grid>
            <Grid container alignItems="center" spacing={2} sx={{flexWrap:"nowrap"}}>
              <Grid>
                <Box component="span">
                  {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
                </Box>
              </Grid>
              <Grid>
                <Box component="span">
                  <FormControl sx={{minWidth:120}} size="small">
                    <InputLabel id="sort-order-label">並び順</InputLabel>
                    <Select
                      labelId="sort-order-label"
                      name="sortOrder"
                      value={searchCond.sortOrder}
                      label="並び順"
                      onChange={changeSortOrder}
                      sx={{bgcolor:"white"}}
                    >
                      <MenuItem value="name">製品名</MenuItem>
                      <MenuItem value="producer">製造者</MenuItem>
                      <MenuItem value="latest">最新</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="start" spacing={2} sx={{mt:0,pt:2}} size={{xs:12}}>
        {handList.map(row =>(
          <Grid key={row.attr.handId}>
            <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${row.attr.handId}`} style={{ textDecoration:'none' }}
            onClick={()=>{
              dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_DETAIL.TAB_IDX });
              dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:row} });
            }}>
              <Card sx={{ width:240, height:200 }}>
                <CardMedia
                  component="img"
                  height="145"
                  image={row.url?.image[0]}
                  alt={row.attr.handInfo.name}
                />
                <CardContent sx={{py:0.5,px:1,m:0}}>
                  <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                    製品名：{row.attr.handInfo.name}
                  </Typography>
                  <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                    製造者：{row.attr.handInfo.producer}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
        { (handList.length===0) &&
          <Box sx={{my:3,width:"100%",textAlign:"center"}}>
            <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>{notFoundMsg}</Typography>
          </Box>
        }
        </Grid>

      </Container>
    </>
  )
}