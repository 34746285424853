/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, {createContext, useReducer} from "react";
import {WorkClassDocument, WorkInstanceDocument, WorkModelDocument} from '../types/pj1dbApiWork'
import {FormWorkSearchCond, WORK_FORM_STEP, workAttrList, WorkFormStep,} from '../types/workForm'
import {
  registerInstanceAndModelFormInitInputs,
  RegisterInstanceAndModelFormInputs
} from "../components/forms/RegisterInstanceAndModelForm";
import {newFormInitInputs, RegisterFormInputs} from "../components/forms/RegisterForm";
import {WORK_SORT_ORDER} from "../../../constants/constants";
import {ControlState, initialControlState} from "../components/3d/View3D";
import {EditInstanceFormInputs, editInstanceFormInitInputs} from "../components/forms/EditInstanceForm";
import {registerInstanceFormInitInputs, RegisterInstanceFormInputs} from "../hooks/useRegisterInstance";
import {registerModelFormInitInputs, RegisterModelFormInputs} from "../hooks/useRegisterModel";
import {editClassFormInitInputs, EditClassFormInputs} from "../components/forms/EditClassForm";

export const REGISTRATION_ACTION_TYPE = {
  NEW: 'new',
  EDIT_CLASS: 'edit_class',
  REGISTER_INSTANCE_AND_MODEL: 'register_instance_and_model',
  EDIT_INSTANCE: 'edit_instance',
  REGISTER_INSTANCE: 'register_instance',
  REGISTER_MODEL: 'register_model',
} as const;

export type RegistrationActionType = typeof REGISTRATION_ACTION_TYPE[keyof typeof REGISTRATION_ACTION_TYPE];

export interface WorkRegisteredListPageWorkListItem {
  class: WorkClassDocument,
  instances: {
    instance: WorkInstanceDocument,
    models: WorkModelDocument[],
  }[]
}

// useReducerで生成する「参照用のstate」の型
export type DataStoreType = {
  tabIndex: number;
  workListPage: {
    searchCond: FormWorkSearchCond;
    workList: WorkClassDocument[];
  };
  workRegisteredListPage: {
    searchCond: FormWorkSearchCond;
    workList: WorkRegisteredListPageWorkListItem[];
    selectedInstanceId: string | null;
  };
  workDetailPage: {
    info: WorkClassDocument | null;
    models: WorkModelDocument[];
    controlState: ControlState;
  };
  workRegistrationPage: {
    step: WorkFormStep;
    selection: {
      selectedWork: WorkClassDocument | null;
      searchCond: FormWorkSearchCond;
      workList: WorkClassDocument[];
    }
    input: {
      actionType: RegistrationActionType;
      [REGISTRATION_ACTION_TYPE.NEW]: {
        registered: boolean;
        inputs: RegisterFormInputs;
      };
      [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
        work: WorkClassDocument | null;
        inputs: EditClassFormInputs,
      },
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
        registered: boolean;
        work: WorkClassDocument | null;
        inputs: RegisterInstanceAndModelFormInputs;
      };
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
        registered: boolean;
        work: WorkClassDocument | null;
        inputs: RegisterInstanceFormInputs;
      };
      [REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]: {
        work: WorkClassDocument | null;
        instance: WorkInstanceDocument | null;
        inputs: EditInstanceFormInputs;
      };
      [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
        registered: boolean;
        work: WorkClassDocument | null;
        instance: WorkInstanceDocument | null;
        inputs: RegisterModelFormInputs;
      };
    }
  };
};

export const initialState: DataStoreType = {
  tabIndex: 0,
  workListPage: {
    searchCond: {
      keyword: "",
      listCount: 0,
      sortOrder: WORK_SORT_ORDER.NAME.value,
    },
    workList: [],
  },
  workRegisteredListPage: {
    searchCond: {
      keyword: "",
      listCount: 0,
      sortOrder: WORK_SORT_ORDER.NAME.value,
    },
    workList: [],
    selectedInstanceId: null,
  },
  workDetailPage: {
    info: null,
    models: [],
    controlState: initialControlState,
  },
  workRegistrationPage: {
    step: WORK_FORM_STEP.WORK_SELECT,
    selection: {
      selectedWork: null,
      searchCond: {
        keyword: "",
        listCount: 0,
        sortOrder: WORK_SORT_ORDER.NAME.value,
      },
      workList: [],
    },
    input: {
      actionType: REGISTRATION_ACTION_TYPE.NEW,
      [REGISTRATION_ACTION_TYPE.NEW]: {
        registered: false,
        inputs: newFormInitInputs
      },
      [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
        work: null,
        inputs: editClassFormInitInputs,
      },
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
        registered: false,
        work: null,
        inputs: registerInstanceAndModelFormInitInputs,
      },
      [REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]: {
        work: null,
        instance: null,
        inputs: editInstanceFormInitInputs,
      },
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
        registered: false,
        work: null,
        inputs: registerInstanceFormInitInputs,
      },
      [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
        registered: false,
        work: null,
        instance: null,
        inputs: registerModelFormInitInputs,
      }
    }
  },
};

// dispatch関数の第2引数に渡す「action」の型
type ReducerActionType = {
  type: string;
  payload: any;
};

// createContext()のデフォルト値オブジェクトにasで割り当てる。
type DataStoreContextType = {
  state: DataStoreType;
  // dispatchの引数オブジェクトの型を、React.Dispatch<XXXXX> に定義する。
  dispatch: React.Dispatch<ReducerActionType>;
};

// reducer関数：更新用dispatchトリガーで、stateを更新する処理。
// 引数:   1.state 2.action(dispatch関数の引数)
// 戻り値: 更新後の新しいstate
export const CONTEXT_ACTION_TYPE = {
  TAB: "SET_TAB_INDEX",
  WORK_LIST: "SET_WORK_LIST_PAGE",
  WORK_REGISTERED_LIST: "SET_WORK_REGISTERED_LIST_PAGE",
  WORK_DETAIL: "SET_WORK_DETAIL_PAGE",
  WORK_REGISTRATION: "SET_WORK_REGISTRATION_PAGE",
} as const;
const reducerFunc = (state: DataStoreType, action: ReducerActionType) => {
  // action.typeの値で更新内容を切り替える。
  switch (action.type) {
    case CONTEXT_ACTION_TYPE.TAB:
      return {
        ...state,
        tabIndex: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_LIST:
      return {
        ...state,
        workListPage: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_REGISTERED_LIST:
      return {
        ...state,
        workRegisteredListPage: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_DETAIL:
      return {
        ...state,
        workDetailPage: action.payload,
      } satisfies DataStoreType;
    case CONTEXT_ACTION_TYPE.WORK_REGISTRATION:
      return {
        ...state,
        workRegistrationPage: action.payload,
      } satisfies DataStoreType;
    // 更新前のstateをそのまま返す。
    default:
      return state;
  }
};


// createContextはReactフックではないため、コンポーネント外で使用可能
// as でオブジェクトの型チェックをクリアする。
export const DataStoreContext = createContext({} as DataStoreContextType);

export function DataStoreContextProvider(props: any): JSX.Element {
  // useReducerで生成した「参照用state」と「更新用dispatch」を、contextに渡す。
  const [state, dispatch] = useReducer(reducerFunc, initialState);
  return (
    <DataStoreContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </DataStoreContext.Provider>
  );
}

export function makeClassEditPayload({state, work}: {
  state: DataStoreType;
  work: WorkClassDocument;
}): DataStoreType['workRegistrationPage'] {
  const isSameWork = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS].work?.attr[workAttrList.classId.key] === work.attr[workAttrList.classId.key];
  const isClear = !isSameWork;
  const initInputs = {
    ...work.attr,
    [workAttrList.contentWeight.key]: work.attr[workAttrList.contentWeight.key]
      ? work.attr[workAttrList.contentWeight.key]!.toString()
      : undefined,
    [workAttrList.contentVolume.key]: work.attr[workAttrList.contentVolume.key]
      ? work.attr[workAttrList.contentWeight.key]!.toString()
      : undefined,
  }

  return {
    ...state.workRegistrationPage,
    step: WORK_FORM_STEP.WORK_INPUT,
    input: {
      ...state.workRegistrationPage.input,
      actionType: REGISTRATION_ACTION_TYPE.EDIT_CLASS,
      [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
        ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS],
        work,
        inputs: isClear
          ? initInputs
          : {
          ...initInputs,
          ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS].inputs,
        }
      },
    },
  }
}

export function makeInstanceAndModelRegistrationPayload({state, work}: {
  state: DataStoreType;
  work: WorkClassDocument;
}): DataStoreType['workRegistrationPage'] {
  return {
    ...state.workRegistrationPage,
    step: WORK_FORM_STEP.WORK_INPUT,
    selection: {
      ...state.workRegistrationPage.selection,
      selectedWork: work,
    },
    input: {
      ...state.workRegistrationPage.input,
      actionType: REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL,
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
        ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL],
        // フォームをリセットする場合は以下を使用する
        // registered: false,
        // inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL].inputs,
      }
    }
  }
}

export function makeInstanceRegistrationPayload({state, work}: {
  state: DataStoreType;
  work: WorkClassDocument;
}): DataStoreType['workRegistrationPage'] {
  const isSameWork = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].work?.attr[workAttrList.classId.key] === work.attr[workAttrList.classId.key];
  const isClear = !isSameWork;

  return {
    ...state.workRegistrationPage,
    step: WORK_FORM_STEP.WORK_INPUT,
    input: {
      ...state.workRegistrationPage.input,
      actionType: REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE,
      [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
        ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE],
        work,
        // フォームをリセットする場合は以下を使用する
        // registered: false,
        // inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].inputs,
      }
    },
  }
}

export function makeInstanceEditPayload({state, work, instance}: {
  state: DataStoreType;
  work: WorkClassDocument;
  instance: WorkInstanceDocument;
}): DataStoreType['workRegistrationPage'] {
  const isSameInstance = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE].instance?.attr[workAttrList.instanceId.key] === instance.attr[workAttrList.instanceId.key];
  const isClear = !isSameInstance;

  return {
    ...state.workRegistrationPage,
    step: WORK_FORM_STEP.WORK_INPUT,
    input: {
      ...state.workRegistrationPage.input,
      actionType: REGISTRATION_ACTION_TYPE.EDIT_INSTANCE,
      [REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]: {
        ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE],
        work,
        instance,
        inputs: isClear ? instance.attr : {
          ...instance?.attr,
          ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE].inputs,
        }
      }
    },
  }
}

export function makeModelRegistrationPayload({state, work, instance}: {
  state: DataStoreType;
  work: WorkClassDocument;
  instance: WorkInstanceDocument;
}): DataStoreType['workRegistrationPage'] {
  return {
    ...state.workRegistrationPage,
    step: WORK_FORM_STEP.WORK_INPUT,
    input: {
      ...state.workRegistrationPage.input,
      actionType: REGISTRATION_ACTION_TYPE.REGISTER_MODEL,
      [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
        ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL],
        work,
        instance,
        // フォームをリセットする場合は以下を使用する
        // registered: false,
        // inputs:  initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL].inputs,
      },
    },
  }
}
