import {Box, Card, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useState} from "react";
import Pjdb1ImputEx from "../../../../components/inputs/Pjdb1ImputEx";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {workAttrList} from "../../types/workForm";
import useMutationHelper from "../../hooks/useMutationHelper";
import useRegisterInstance, {RegisterInstanceFormInputs} from "../../hooks/useRegisterInstance";
import useRegisterModel, {RegisterModelFormInputs} from "../../hooks/useRegisterModel";
import useRegisterClass, {RegisterClassFormInputs} from "../../hooks/useRegisterClass";
import {ValidationError} from "../../utils/formUtil";
import ModelInputGroup from "./groups/ModelInputGroup";
import InstanceInputGroup from "./groups/InstanceInputGroup";
import ClassInputGroup from "./groups/ClassInputGroup";
import {policies} from "../../policies";
import Forbidden from "../errors/Forbidden";

export const newFormInitInputs: RegisterFormInputs = {
  [workAttrList.modelFile.key]: null,
}

export interface RegisterFormInputs extends RegisterClassFormInputs, RegisterInstanceFormInputs, RegisterModelFormInputs {
}

export interface RegisterFormProps {
  inputs: RegisterFormInputs
  setInputs: React.Dispatch<React.SetStateAction<RegisterFormInputs>>;
  // eslint-disable-next-line react/require-default-props
  onError?: (err: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  onSuccess?: () => void;
  // eslint-disable-next-line react/require-default-props
  onClear?: () => void;
  // eslint-disable-next-line react/require-default-props
  validate?: () => boolean;
}

export function canRegisterWork() {
  return policies.registerClass.can() && policies.registerInstance.can() && policies.registerModel.can();
}

export default function RegisterForm(props: RegisterFormProps) {
  const {
    ConfirmDialog,
    registerWork,
    setModelFileErr,
    modelFileErr,
    formRef,
    clear,
    isLoading,
    isClear,
  } = useRegisterFormHelper(props);

  if (!canRegisterWork()) {
    return <Forbidden/>
  }

  return (
    <>
      <ConfirmDialog
        title='ワーク情報登録'
        message='ワーク情報を登録します。入力内容に間違いがないか確認してください。'
        confirmButton='登録'
        confirmButtonStyle="primary"
      />

      <Container maxWidth="lg">
        <Card sx={{p: 3}}>
          <Grid container spacing={3} alignItems="flex-end" justifyContent='space-between' sx={{mb: 2}}>
            <Grid>
              <Typography variant="h6">商品/部品登録</Typography>
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <ClassInputGroup inputs={props.inputs} setInputs={props.setInputs} isClear={isClear}/>
            <InstanceInputGroup inputs={props.inputs} setInputs={props.setInputs} isClear={isClear}/>
            <ModelInputGroup inputs={props.inputs} setInputs={props.setInputs} modelFileErr={modelFileErr}
                             setModelFileErr={setModelFileErr} isClear={isClear}/>
          </form>
        </Card>
      </Container>

      <Box component="section" sx={{textAlign: "center"}}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={clear}
        />
        <Pjdb1Button
          label="ワーク情報を登録"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={() => {
            registerWork().catch(console.error)
          }}
        />
      </Box>
    </>
  )
}

function useRegisterFormHelper(params: RegisterFormProps) {
  const [modelFileErr, setModelFileErr] = useState({err: false, errMsg: ""});
  const {makeMutation, ...helper} = useMutationHelper({
    onClear: () => {
      params.setInputs(newFormInitInputs);
      params.onClear?.();
    }
  });
  const {validate: validateClass, mutate: registerClass} = useRegisterClass();
  const {validate: validateInstance, mutate: registerInstance} = useRegisterInstance();
  const {validate: validateModel, mutate: registerModel} = useRegisterModel();

  const registerWork = makeMutation({
    onCompleted: () => {
      if (params.onSuccess) {
        params.onSuccess();
      } else {
        helper.notification.savedMsg();
      }
    },
    onError: (err) => {
      helper.handleError({error: err, message: '更新処理が失敗しました。'});
      params.onError?.(err);
    },
    validate: () => {
      if (!(params.validate?.() ?? true)) {
        return false;
      }

      const errors: ValidationError[] = [];

      [validateClass, validateInstance].forEach((validateFunc) => {
        const res = validateFunc(params.inputs);
        if (!res.isValid) {
          errors.push(...res.errors);
        }
      });

      const modelRes = validateModel(params.inputs);
      if (!modelRes.isValid) {
        errors.push(...modelRes.errors);
        if (modelRes.errors.some((error) => error.field === workAttrList.modelFile.key)) {
          setModelFileErr({
            err: true,
            errMsg: modelRes.errors.find((error) => error.field === workAttrList.modelFile.key)?.message ?? ""
          });
        } else {
          setModelFileErr({err: false, errMsg: ""});
        }

        return false;
      }
      setModelFileErr({err: false, errMsg: ""});

      if (errors.length > 0) {
        helper.notification.errorMsg(errors.map((error) => error.message).join('\n'));
        return false;
      }

      return true;
    },
    callback: async () => {
      const classRes = await registerClass({
        inputs: params.inputs,
      });
      if (!classRes.isSuccess) {
        return helper.handleError({error: classRes.error, message: '更新処理が失敗しました。'});
      }

      const instanceRes = await registerInstance({
        inputs: params.inputs,
        classId: classRes.data,
      });
      if (!instanceRes.isSuccess) {
        return helper.handleError({error: instanceRes.error, message: '更新処理が失敗しました。'});
      }

      const modelRes = await registerModel({
        inputs: params.inputs,
        instanceId: instanceRes.data,
      });
      if (!modelRes.isSuccess) {
        return helper.handleError({error: modelRes.error, message: modelRes.message});
      }

      return true;
    },
  });

  return {
    registerWork,
    modelFileErr,
    setModelFileErr,
    ...helper,
  }
}
