import React, {useCallback} from "react";
import {WorkModelDocument} from "../../types/pj1dbApiWork";
import View3D, {ThreeState} from "./View3D";
import {ThreeControl} from "../../utils/view3dUtil";

export interface Model3DProps {
  three: ThreeState;
  // eslint-disable-next-line react/require-default-props
  model?: WorkModelDocument;
}

export default function Model3D(props: Model3DProps) {
  const callback = useCallback(
    (ctrl: ThreeControl) => {
      if (props.model?.attr.upDirection) {
        ctrl.setControlPrimaryDirection(
          (props.model.attr.upDirection.theta / 180) * Math.PI,
          (props.model.attr.upDirection.phi / 180) * Math.PI
        );
      } else {
        ctrl.setControlPrimaryDirection();
      }

      if (props.model?.attr.faceDirection) {
        ctrl.setControlSecondaryDirection(
          (props.model.attr.faceDirection.theta / 180) * Math.PI,
          (props.model.attr.faceDirection.phi / 180) * Math.PI
        );
      } else {
        ctrl.setControlSecondaryDirection();
      }
    },
    [props.model]
  );

  return (
    <View3D
      three={props.three}
      url={props.model?.url?.glb.original.find(() => true)}
      showPerspectiveControl
      showLookAtControl
      showGridSizeControl
      options={{
        displayControlPrimaryDirection: true,
        displayControlSecondaryDirection: true,
      }}
      onThreeModelLoaded={callback}
    />
  )
};
