/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */

import { Box , Typography, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React ,{useState, useEffect, useCallback} from "react";
import {useDropzone} from 'react-dropzone'
import Pjdb1ToolTip from "./Pjdb1ToolTip";

interface Props {
  name: string;
  label: string;
  value: File[]|null;
  accept: {[key: string]: string[]};
  multiple: boolean;
  onChange?: (name:string,value:File[]) => void;
  err?: boolean;
  errMsg?: string;
  xs?:number;
  toolTip?: string|JSX.Element;
  webkitdirectory?: boolean;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1FileSelect]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1FileSelect]:areEqual(next)',nextProps);
  const retEqual = prevProps.value === nextProps.value;
  // console.log(`[${prevProps.name}]`,'[Pjdb1FileSelect]:areEqual(dif) val:',retEqual);
  return retEqual;
}

const errorTextStyle = {
  "color" : "#D32F2F",
  "fontSize" : "0.8rem",
  "marginTop" : "4px",
  "marginLeft":"10px"
}

/**
 * ファイル選択
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props:Props) => {

  const [myFiles, setMyFiles] = useState(props.value);
  const fileOrDirLabel = props.webkitdirectory ? "フォルダ" : "ファイル";

  useEffect(()=>{
    if(props.value?.length===0) setMyFiles([]);
  },[props.value]);

  const onDrop = useCallback((acceptedFiles:File[]) => {
    // 指定外のファイルがあれば、何も処理をしない
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (isDragReject) {
      return;
    }
    // const dataUrls = acceptedFiles.map((file) => URL.createObjectURL(file));
    // setPreviewImagePaths(dataUrls);

    console.log('ファイルorディレクトリのアップロード')
    console.log(acceptedFiles);

    setMyFiles(acceptedFiles);
    if(props.onChange) props.onChange(props.name,acceptedFiles)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const {getRootProps, getInputProps, open, fileRejections, isDragReject} = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: props.multiple,
    accept: props.accept,
    useFsAccessApi: !props.webkitdirectory,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.name}>
      {errors.map((e) => (
        <p key={e.code} style={errorTextStyle}>
          「{file.name}」は正しいファイルではありません。
        </p>
      ))}
    </div>
  ));

  // console.log(`[${props.name}]`,'[Pjdb1FileSelect]:rendering!!',props);
  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{flexWrap:"nowrap"}}>
        <Grid 
          {...(props.xs !== undefined ? {size:{xs: props.xs}} : {sx:{flexGrow:1}})}
        >
          <Box component="section"
            sx={{
              ...{width:1, p: 2, border:'2px dotted rgba(0, 0, 0, 0.23)',
                borderRadius:"10px",textAlign:"center"},
              ...( props.err ? {borderColor:"error.main"} : {} )
              }}
            {...getRootProps({className: 'dropzone'})}>
            <input
              {...getInputProps({
                ...(props.webkitdirectory ? {webkitdirectory: "true", dir: ""} : {}),
              })}
            />

            <Typography variant="subtitle2" component="div" sx={{fontSize:"1.1rem"}}>
              ここに{fileOrDirLabel}をドロップ
            </Typography>
            <Typography variant="subtitle2" component="div" sx={{fontSize:"0.9rem"}}>
              または
            </Typography>
            <Button variant="contained" color="primary" onClick={open}
            size="small" sx={{mt:1}}>{fileOrDirLabel}選択</Button>
          </Box>
          {myFiles?.map(file => (
            <div key={file.name} >
              {file.name}&nbsp;&nbsp;-&nbsp;&nbsp;<small>{file.size}&nbsp;bytes</small>
            </div>
          ))}
          {fileRejectionItems}

        </Grid>
        { props.toolTip !== undefined &&
        <Pjdb1ToolTip toolTip={props.toolTip} />
        }
      </Grid>
      { props.err &&
        <Typography color="error" sx={{fontSize:"0.75rem"}}>
          {props.errMsg}
        </Typography>
      }
    </>
  );

},areEqual);
