import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";

export interface NameJpProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function NameJp(props: NameJpProps) {
    return (
      <WithLabel label={workAttrList.nameJp.label}>
          <Pjdb1TextField
            label={workAttrList.nameJp.label}
            name={workAttrList.nameJp.key}
            value={props.value ?? ''}
            required={props.required}
            onChange={props.onChange}
            disabled={props.disabled}
            isClear={props.isClear}
            xs={6}
            toolTip="日本語名称"
          />
      </WithLabel>
    )
}
