import {useAuthenticator} from "@aws-amplify/ui-react";
import {useUserRole} from "../../../utils/awsConfigure";

export default function useUser() {
  const {user} = useAuthenticator((context) => [context.user]);
  const {userRole, userDomain} = useUserRole();

  return {
    user,
    role: userRole,
    domain: userDomain,
  }
}
