/**
 * ハンド一覧検索条件
 */
export interface FormHandSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:string,
}

export interface FormHandShapeIntegrated {
  range: string;
  openLengthOuter: string;
  closeLengthOuter: string;
  openLengthInner: string;
  closeLengthInner: string;
}
export interface FormHandShapeDetachable {
  mountPattern: string;
  range: string;
  openLength: string;
  closeLength: string;
}
export interface FormExtra {
  key: string;
  value: string;
  values: string[];
  isArray: boolean;
}

/**
 * ハンド登録フォーム用タイプ
 */
export interface FormHandAttr {

  // 共通項目
  handId: string;
  registrationDate: string;
  registrant: string;
  domain: string;
  fingertipDetachable: boolean;
  freeFormat: boolean;
  name: string;
  producer: string;
  number: string;
  weight: string;
  fingertipType: string;
  elasticFingertip: boolean;
  material: string;
  handPowerSource: string[];
  keyword: string[];
  description: string;
  source: string[];

  // freeFormat(自由フォーマット):falseの場合
  payload: string;

  //   fingertipDetachable(爪着脱可能):falseの場合
  handShapeIntegrated: FormHandShapeIntegrated[];
  //   fingertipDetachable(爪着脱可能):trueの場合
  handShapeDetachable: FormHandShapeDetachable[];

  gripPositionResolution: string;
  gripForceInnerMin: string;
  gripForceInnerMax: string;
  gripForceOuterMin: string;
  gripForceOuterMax: string;
  gripForceResolution: string;
  gripSpeedMin: string;
  gripSpeedMax: string;
  gripSpeedResolution: string;
  communication: string[];
  control: string[];
  mechanic: string[];
  electronic: string[];

  // freeFormat(自由フォーマット):trueの場合
  extra: FormExtra[];

  file: File|null;
  zipURI: string;
}

// ハンド登録フォーム 初期値
export const handInitInputs:FormHandAttr = {
  handId: "",
  registrationDate: "",
  registrant: "",
  domain: "",
  fingertipDetachable: false,
  freeFormat: false,
  name: "",
  producer: "",
  number: "",
  weight: "",
  fingertipType: "",
  elasticFingertip: false,
  material: "",
  handPowerSource: [],
  keyword: [""],
  description: "",
  source: [""],
  payload: "",
  handShapeIntegrated:[
    {
      range: "",
      openLengthOuter: "",
      closeLengthOuter: "",
      openLengthInner: "",
      closeLengthInner: "",
    }
  ],
  handShapeDetachable:[
    {
      mountPattern: "",
      range: "",
      openLength: "",
      closeLength: "",
    }
  ],
  gripPositionResolution: "",
  gripForceInnerMin: "",
  gripForceInnerMax: "",
  gripForceOuterMin: "",
  gripForceOuterMax: "",
  gripForceResolution: "",
  gripSpeedMin: "",
  gripSpeedMax: "",
  gripSpeedResolution: "",
  communication: [""],
  control: [""],
  mechanic: [""],
  electronic: [""],
  extra:[{key:"", value:"", values:[""], isArray:false}],
  file: null,
  zipURI:"",
}

/**
 * ハンド属性表用タイプ
 */
export interface AttrListRowType {
  key: string;
  class: string;
  attr: string;
  value: string;
}
