/* eslint-disable no-void */
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom'
import {
  Box,
  Typography,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";

import { FormHandAttr, handInitInputs } from '../types/handForm'
import { FormFingertipAttr, fingertipInitInputs } from '../types/fingertipForm'
import { FormArmAttr, armInitInputs } from '../types/armForm'
import { FormAttachmentAttr, attachmentInitInputs } from '../types/attachmentForm'

import { HandDocument, makeFormHandFromDocument } from '../types/pj1dbApiHand'
import { FingertipDocument, makeFormFingertipFromDocument } from '../types/pj1dbApiFingertip'
import { ArmDocument, makeFormArmFromDocument } from '../types/pj1dbApiArm'
import { AttachmentDocument, makeFormAttachmentFromDocument } from '../types/pj1dbApiAttachment'

import HandRegistePanel from '../components/HandRegistePanel';
import FingertipRegistePanel from '../components/FingertipRegistePanel';
import ArmRegistePanel from '../components/ArmRegistePanel';
import AttachmentRegistePanel from '../components/AttachmentRegistePanel';

import Pjdb1ToolTip from '../../../components/inputs/Pjdb1ToolTip';

export default function EquipmentRegiste() {
  const { userRole } = useUserRole();
  const { state, dispatch } = useContext(DataStoreContext);

  const [regModeHand, setRegModeHand] = useState(true);
  const [regModeFingertip, setRegModeFingertip] = useState(true);
  const [regModeArm, setRegModeArm] = useState(true);
  const [regModeAttachment, setRegModeAttachment] = useState(true);
  const [regType, setRegType] = useState("hand");
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
    if (value) setRegType(value);
  }

  // ハンド・爪・アーム・アタッチメントのパネル内input
  const [handInputs, setHandInputs] = useState<FormHandAttr>(handInitInputs);
  const [fingertipInputs, setFingertipInputs] = useState<FormFingertipAttr>(fingertipInitInputs);
  const [armInputs, setArmInputs] = useState<FormArmAttr>(armInitInputs);
  const [attachmentInputs, setAttachmentInputs] = useState<FormAttachmentAttr>(attachmentInitInputs);

  // contextに保存
  useEffect(()=>{
    // console.log("EquipmentRegiste","saveContext",handInputs,fingertipInputs);
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST,
      payload:{
        regType,
        regModeHand,
        handPanel: handInputs ,
        regModeFingertip,
        fingertipPanel: fingertipInputs,
        regModeArm,
        armPanel: armInputs ,
        regModeAttachment,
        attachmentPanel: attachmentInputs
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[regType,regModeHand,handInputs,regModeFingertip,fingertipInputs,regModeArm,armInputs,regModeAttachment,attachmentInputs]);

  // urlパラメータからモード判定
  const urlParams = useParams<{ mode: string, id: string }>();
  const usrParamMode = urlParams.mode ? String(urlParams.mode) : "";
  const usrParamId = urlParams.id ? String(urlParams.id) : "";
  useEffect(()=>{
    switch (usrParamMode) {
      case '':
      case 'hand':
      case 'copyHand': {
        setRegType("hand");
        if(usrParamMode==="hand") setRegModeHand(false);
        if(usrParamMode!==""){
          if(state.registPage.handPanel.handId && state.registPage.handPanel.handId===usrParamId){
            setHandInputs(state.registPage.handPanel);
          }else{
            const dispInfo:HandDocument =
            state.handListPage.handList.filter((item)=>item.attr.handId === usrParamId)[0];
            if(dispInfo) {
              setHandInputs(makeFormHandFromDocument(dispInfo));
            }else{
              setRegModeHand(true);
            }
          }
        }
        break;
      }
      case 'fingertip':
      case 'copyFingertip':{
        setRegType("fingertip");
        if(usrParamMode==="fingertip") setRegModeFingertip(false);
        if(state.registPage.fingertipPanel.fingertipId && state.registPage.fingertipPanel.fingertipId===usrParamId){
          setFingertipInputs(state.registPage.fingertipPanel);
        }else{
          const dispInfo:FingertipDocument =
            state.fingertipListPage.fingertipList.filter((item)=>item.attr.fingertipId === usrParamId)[0];
          if(dispInfo) {
            setFingertipInputs(makeFormFingertipFromDocument(dispInfo));
          }else{
            setRegModeFingertip(true);
          }
        }
        break;
      }
      case 'arm':
      case 'copyArm': {
        setRegType("arm");
        if(usrParamMode==="arm") setRegModeArm(false);
        if(state.registPage.armPanel.armId && state.registPage.armPanel.armId===usrParamId){
          setArmInputs(state.registPage.armPanel);
        }else{
          const dispInfo:ArmDocument =
          state.armListPage.armList.filter((item)=>item.attr.armId === usrParamId)[0];
          if(dispInfo) {
            setArmInputs(makeFormArmFromDocument(dispInfo));
          }else{
            setRegModeArm(true);
          }
        }
        break;
      }
      case 'attachment':
      case 'copyAttachment':{
        setRegType("attachment");
        if(usrParamMode==="attachment") setRegModeAttachment(false);
        if(state.registPage.attachmentPanel.attachmentId && state.registPage.attachmentPanel.attachmentId===usrParamId){
          setAttachmentInputs(state.registPage.attachmentPanel);
        }else{
          const dispInfo:AttachmentDocument =
            state.attachmentListPage.attachmentList.filter((item)=>item.attr.attachmentId === usrParamId)[0];
          if(dispInfo) {
            setAttachmentInputs(makeFormAttachmentFromDocument(dispInfo));
          }else{
            setRegModeAttachment(true);
          }
        }
        break;
      }
      default:
        setRegType("hand");
    }

    // パラメータなし（ノーマル登録モード）の場合はcontextから復元
    if(usrParamMode===""){
      // console.log("EquipmentRegiste","restore!!");
      if(userRole === ROLE_KEYS.SIER){
        setRegType("fingertip");
      }else{
        setRegType(String(state.registPage.regType));
      }
      setRegModeHand(state.registPage.regModeHand);
      setHandInputs({...state.registPage.handPanel});
      setRegModeFingertip(state.registPage.regModeFingertip);
      setFingertipInputs({...state.registPage.fingertipPanel});
      setRegModeArm(state.registPage.regModeArm);
      setArmInputs({...state.registPage.armPanel});
      setRegModeAttachment(state.registPage.regModeAttachment);
      setAttachmentInputs({...state.registPage.attachmentPanel});
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      <Grid container spacing={0.5} alignItems="flexStart" sx={{px:2,mt:2}}>
        <Grid>
          <Typography variant="body1" color='error'>※入力すべき値は</Typography>
        </Grid>
        <Grid><HelpOutlineIcon color='secondary'/></Grid>
        <Grid>
          <Typography variant="body1" color='error'>(tips)を参照して定めること。</Typography>
        </Grid>
      </Grid>

      <Box component="section" sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid>
            <InputLabel variant='standard' sx={{color: 'text.primary'}}>登録タイプ</InputLabel>
          </Grid>
          <Grid>
            <ToggleButtonGroup
              color="primary"
              value={regType}
              exclusive
              onChange={handleChange}
              aria-label="RegType"
              sx={{bgcolor:'white'}}
            >
              { (userRole !== ROLE_KEYS.SIER) &&
                <ToggleButton value="hand" sx={{width:120,py:0.7,px:0,color:'text.primary'}}>ハンド</ToggleButton>
              }
              <ToggleButton value="fingertip" sx={{width:120,py:0.7,px:0,color:'text.primary'}}>爪</ToggleButton>
              { (userRole !== ROLE_KEYS.SIER && userRole !== ROLE_KEYS.HAND_MAKER) &&
                <ToggleButton value="arm" sx={{width:120,py:0.7,px:0,color:'text.primary'}}>アーム</ToggleButton>
              }
              <ToggleButton value="attachment" sx={{width:120,py:0.7,px:0,color:'text.primary'}}>アタッチメント</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid>
            <Pjdb1ToolTip toolTip="登録を行いたいタイプを選択してください。" sx={{mt:1}} />
          </Grid>
        </Grid>
      </Box>
      { regType === 'hand' &&
        <HandRegistePanel
          regMode={regModeHand} setRegMode={setRegModeHand} inputs={handInputs} setInputs={setHandInputs} />
      }
      { regType === 'fingertip' &&
        <FingertipRegistePanel regMode={regModeFingertip} setRegMode={setRegModeFingertip} inputs={fingertipInputs} setInputs={setFingertipInputs} />
      }
      { regType === 'arm' &&
        <ArmRegistePanel
          regMode={regModeArm} setRegMode={setRegModeArm} inputs={armInputs} setInputs={setArmInputs} />
      }
      { regType === 'attachment' &&
        <AttachmentRegistePanel regMode={regModeAttachment} setRegMode={setRegModeAttachment} inputs={attachmentInputs} setInputs={setAttachmentInputs} />
      }
    </>
  )
}
