import {workAttrList} from "../../../types/workForm";
import WithLabel, {WithLabelSpacingWrapper} from "../fields/WithLabel";
import {WorkInstanceDocument} from "../../../types/pj1dbApiWork";

export interface InstanceInfoGroupProps {
  instance: WorkInstanceDocument;
}

export default function InstanceInfoGroup(props: InstanceInfoGroupProps) {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.instance.attr[workAttrList.description.key] && (
        <WithLabel label={workAttrList.description.label}>
          <WithLabelSpacingWrapper>
            {props.instance.attr[workAttrList.description.key]}
          </WithLabelSpacingWrapper>
        </WithLabel>
      )}
    </>
  );
}
