import React from "react";
import WithLabel from "./WithLabel";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";
import {WORK_INPUT_FILE_TYPES, WORK_MODEL_TYPES, WorkModelType} from "../../../../../constants/constants";
import {workAttrList} from "../../../types/workForm";

export interface FileTypeProps {
  value: string;
  // eslint-disable-next-line react/require-default-props
  modelType?: WorkModelType;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function FileType(props: FileTypeProps) {
  return (
    <WithLabel label={workAttrList.fileType.label}>
      <Pjdb1RadioGroup
        name={workAttrList.fileType.key}
        radios={WORK_INPUT_FILE_TYPES.map((row) => ({btnKey: row.key, btnLbl: row.label}))}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled === true || props.modelType !== WORK_MODEL_TYPES.REGISTRATION.key}
        isClear={props.isClear}
        toolTip="オブジェクト（.objとmtl）、オブジェクトテクスチャ（.obとjmtlとテクスチャ画像）"
      />
    </WithLabel>
  )
}
