/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {API, Auth, Storage} from "aws-amplify";
import {Dict} from "../features/equipmentdb/types/pj1dbApi";
import {HandDocument} from "../features/equipmentdb/types/pj1dbApiHand";
import {FingertipDocument} from "../features/equipmentdb/types/pj1dbApiFingertip";
import {ArmDocument} from "../features/equipmentdb/types/pj1dbApiArm";
import {AttachmentDocument} from "../features/equipmentdb/types/pj1dbApiAttachment";

import {
  extractRegisterModelRequestBodyParam,
  extractRegisterModelRequestPathParam,
  extractRegisterModelRequestQueryParam,
  Hierarchy,
  RegisterClassParam,
  RegisterInstanceParam,
  RegisterModelPollParam,
  RegisterModelRequestParam,
  UpdateClassParam, UpdateInstanceParam, WorkClassDocument,
  WorkDocumentDictByHierarchy,
  WorkModelPoolAttr
} from "../features/workdb/types/pj1dbApiWork";
import {convertUnixTimeSec, makeDateJST} from "./dateUtil";
import {workAttrList} from "../features/workdb/types/workForm";

export interface ResponseType<T> {
  isSuccess: boolean;
  data?: T;
  err?: any;
}

/**
 * APIのコール
 * @param method
 * @param apiName
 * @param funcPath
 * @param bodyObject
 * @param queryStringParameters
 * @returns
 */
export const callWebApi = async <T>(
  method: "get" | "post",
  apiName: string,
  funcPath: string,
  bodyObject?: object,
  queryStringParameters?: object
): Promise<ResponseType<T>> => {
  if (apiName === null || apiName === "") {
    return {isSuccess: false, err: "API名が指定されていません"};
  }

  const token = (await Auth.currentSession()).getIdToken().getJwtToken();

  console.log(
    "callWebApi\n",
    "method=",
    method,
    "\n",
    "apiName=",
    apiName,
    "\n",
    "funcPath=",
    funcPath,
    "\n",
    "queryStringParameters=",
    queryStringParameters,
    "\n",
    "bodyObject=",
    bodyObject,
    "\n"
  );

  const apiRes: ResponseType<T> = await API[method](apiName, funcPath, {
    queryStringParameters,
    headers: {
      Authorization: token,
    },
    body: bodyObject,
  })
    .then((response) => {
      console.log("callWebApi response", response);
      return {
        isSuccess: true,
        data: response,
      };
    })
    .catch((error) => {
      console.log("callWebApi error", error);
      return {
        isSuccess: false,
        err: error,
      };
    });

  return apiRes;
};

/**
 * ハンド情報検索
 * @param bodyObject
 * @returns
 */
export const findHand = async (
  bodyObject?: object
): Promise<ResponseType<Dict<HandDocument>>> => {
  console.log("findHand");
  return callWebApi<Dict<HandDocument>>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "findHand_sync",
    bodyObject,
    {}
  );
};

/**
 * ハンド情報登録
 * @param bodyObject
 * @returns
 */
export const registHand = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("registHand");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "insertHand_sync",
    bodyObject,
    {}
  );
};

/**
 * ハンド情報更新
 * @param bodyObject
 * @returns
 */
export const updateHand = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("updateHand");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "updateHand_sync",
    bodyObject,
    {}
  );
};

/**
 * ハンド情報削除
 * @param bodyObject
 * @returns
 */
export const deleteHand = async (
  bodyObject: object
): Promise<ResponseType<string>> => {
  console.log("deleteHand");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "deleteHand_sync",
    bodyObject,
    {}
  );
};

/**
 * 爪情報検索
 * @param bodyObject
 * @returns
 */
export const findFingertip = async (
  bodyObject?: object
): Promise<ResponseType<Dict<FingertipDocument>>> => {
  console.log("findFingertip");
  return callWebApi<Dict<FingertipDocument>>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "findFingertip_sync",
    bodyObject,
    {}
  );
};

/**
 * 爪情報登録
 * @param bodyObject
 * @returns
 */
export const registFingertip = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("registFingertip");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "insertFingertip_sync",
    bodyObject,
    {}
  );
};

/**
 * 爪情報更新
 * @param bodyObject
 * @returns
 */
export const updateFingertip = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("updateFingertip");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "updateFingertip_sync",
    bodyObject,
    {}
  );
};

/**
 * 爪情報削除
 * @param bodyObject
 * @returns
 */
export const deleteFingertip = async (
  bodyObject: object
): Promise<ResponseType<string>> => {
  console.log("deleteFingertip");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_HAND),
    "deleteFingertip_sync",
    bodyObject,
    {}
  );
};

/**
 * アーム情報検索
 * @param bodyObject
 * @returns
 */
export const findArm = async (
  bodyObject?: object
): Promise<ResponseType<Dict<ArmDocument>>> => {
  console.log("findArm");
  return callWebApi<Dict<ArmDocument>>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "findArm_sync",
    bodyObject,
    {}
  );
};
/**
 * アーム情報登録
 * @param bodyObject
 * @returns
 */
export const registArm = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("registArm");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "insertArm_sync",
    bodyObject,
    {}
  );
};

/**
 * アーム情報更新
 * @param bodyObject
 * @returns
 */
export const updateArm = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("updateArm");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "updateArm_sync",
    bodyObject,
    {}
  );
};

/**
 * アーム情報削除
 * @param bodyObject
 * @returns
 */
export const deleteArm = async (
  bodyObject: object
): Promise<ResponseType<string>> => {
  console.log("deleteArm");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "deleteArm_sync",
    bodyObject,
    {}
  );
};

/**
 * アタッチメント情報検索
 * @param bodyObject
 * @returns
 */
export const findAttachment = async (
  bodyObject?: object
): Promise<ResponseType<Dict<AttachmentDocument>>> => {
  console.log("findAttachment");
  return callWebApi<Dict<AttachmentDocument>>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "findAttachment_sync",
    bodyObject,
    {}
  );
};

/**
 * アタッチメント情報登録
 * @param bodyObject
 * @returns
 */
export const registAttachment = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("registAttachment");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "insertAttachment_sync",
    bodyObject,
    {}
  );
};

/**
 * アタッチメント情報更新
 * @param bodyObject
 * @returns
 */
export const updateAttachment = async (
  bodyObject: object
): Promise<ResponseType<string[]>> => {
  console.log("updateAttachment");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "updateAttachment_sync",
    bodyObject,
    {}
  );
};

/**
 * アタッチメント情報削除
 * @param bodyObject
 * @returns
 */
export const deleteAttachment = async (
  bodyObject: object
): Promise<ResponseType<string>> => {
  console.log("deleteAttachment");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_ARM),
    "deleteAttachment_sync",
    bodyObject,
    {}
  );
};

// --------------------------------------------------------------------------------------

export type FindWorkResponseData<H extends Hierarchy> = {
  items: WorkDocumentDictByHierarchy<H>,
  total_count: number
}

/**
 * ワーク情報検索
 * @param hierarchy
 * @param bodyObject
 * @returns
 */
export const findWork = async <H extends Hierarchy>(
  hierarchy: H,
  bodyObject?: object,
): Promise<ResponseType<FindWorkResponseData<H>>> => {
  console.log("findWork");
  return callWebApi<FindWorkResponseData<H>>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `find_sync/${hierarchy}`,
    bodyObject,
    {
      deleted: false,
      // FIXME: ページネーションを実装するまでの間の暫定対応
      limit: 100,
      skip: 0,
    }
  );
};

/**
 * ワーククラスに紐づくモデル情報検索
 * @param workClass
 */
export const findRelatedModels = (
  workClass: WorkClassDocument
) => {
  const modelIds = Object.values(workClass.instanceModelRelation ?? {}).flat();
  if (modelIds.length === 0) {
    return Promise.resolve({
      isSuccess: true,
      data: {
        items: {},
        total_count: 0
      }
    } as const satisfies ResponseType<FindWorkResponseData<'model'>>);
  }

  const params = {
    version: "work-user",
    queryAttr: {
      $or: [
        {[workAttrList.modelId.key]: {"$regex": modelIds.join('|'), "$options": "i"}},
      ]
    }
  };

  return findWork('model', params);
}

/**
 * ワーククラス情報登録
 * @param bodyObject
 * @returns ResponseType<string> classId
 */
export const registerWorkClass = async (
  {version, ...body}: RegisterClassParam
): Promise<ResponseType<string>> => {
  console.log("registerWorkClass");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `insertClass_sync/${version}`,
    body,
    {}
  );
};

/**
 * ワーククラス情報登録
 * @param bodyObject
 * @returns
 */
export const registerWorkInstance = async (
  {version, ...body}: RegisterInstanceParam
): Promise<ResponseType<string>> => {
  console.log("registerWorkInstance");
  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `insertInstance_sync/${version}`,
    body,
    {}
  );
};

/**
 * ワークモデル情報登録
 *
 * モデル属性データの作成・変更に必要な関数を定義する。モデルは事前に.obj（及び.mtl）ファイルをs3にアップロードした上で、insertModel_request関数を呼ぶことでDBに登録する。時間がかかる為非同期関数とする。処理完了したかを確認するにはmodel-idを使用し問合（insertModel_poll）を行うこと。
 * ※：モデルファイルはinsertModel関数の処理の中で複製し別の場所に保管する為、元ファイルはs3上から削除しても問題ない
 *
 * @returns
 * @param params
 */
export const registerWorkModel = async (
  params: RegisterModelRequestParam
): Promise<ResponseType<string>> => {
  const pathParams = extractRegisterModelRequestPathParam(params);
  const bodyParams = extractRegisterModelRequestBodyParam(params);
  const queryParams = extractRegisterModelRequestQueryParam(params);

  return callWebApi<string>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `insertModel_request/${pathParams.version}`,
    bodyParams,
    queryParams
  );
};

/**
 * ワークモデルファイルアップロード
 * @param files
 * @returns
 */
export const uploadModelFiles = async (files: File[]) => {
  const topDir = files.find(() => true)?.webkitRelativePath?.split('/')[0] ?? '';
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const resFile: ResponseType<string> = await fileBulkUpload(files.map(file => ({
    file,
    path: file.webkitRelativePath.replace(topDir, '').replace(file.name, ''),
  })), `work/model_file/${convertUnixTimeSec(makeDateJST())}_${topDir}`);
  if (!resFile.isSuccess) {
    return {isSuccess: false, err: resFile} as const;
  }

  return {
    isSuccess: true,
    data: String(resFile.data).endsWith('/') ? String(resFile.data) : `${String(resFile.data)}/`,
  } as const
}


export const pollWorkModel = async (
  bodyObject: RegisterModelPollParam
): Promise<ResponseType<WorkModelPoolAttr>> => {
  console.log("pollWorkModel");
  return callWebApi<WorkModelPoolAttr>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    "insertModel_poll",
    bodyObject,
    {}
  );
}

export const updateWorkClass = async (
  {classId, ...body}: UpdateClassParam
): Promise<ResponseType<string[]>> => {
  console.log("updateWorkClass");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `updateClass_sync/${classId}`,
    body,
    {}
  );
}

/**
 * ワークインスタンス情報更新
 * @param bodyObject
 */
export const updateWorkInstance = async (
  {instanceId, ...body}: UpdateInstanceParam
): Promise<ResponseType<string[]>> => {
  console.log("updateWorkInstance");
  return callWebApi<string[]>(
    "post",
    String(process.env.REACT_APP_AWS_API_NAME_WORK),
    `updateInstance_sync/${instanceId}`,
    body,
    {}
  );
}


/**
 * S3へのファイルアップロード
 * @author TAW j.sano
 * @param File
 * @returns ResponseType<T>
 */
export const fileUploade = async (
  file: File | null
): Promise<ResponseType<string>> => {
  try {
    if (!file) return {isSuccess: false};

    // const fileName = file.name;
    const fileName = `${String(new Date().getTime())}_${file.name}`;

    console.log("Storage.put! ", fileName);
    const resPut = await Storage.put(fileName, file, {
      contentType: file.type,
      level: "private",
    })
      .then((result) => {
        console.log("Storage.put result", result);
        return {
          isSuccess: true,
          data: result.key,
        };
      })
      .catch((error) => {
        console.log("Storage.put error", error);
        return {
          isSuccess: false,
          data: "",
          err: error,
        };
      });
    if (!resPut.isSuccess) return resPut;

    console.log("Storage.get! ", fileName);
    const resGet = await Storage.get(fileName, {level: "private"})
      .then((result) => {
        console.log("Storage.get result", result);
        return {
          isSuccess: true,
          data: result,
        };
      })
      .catch((error) => {
        console.log("Storage.get error", error);
        return {
          isSuccess: false,
          data: "",
          err: error,
        };
      });
    if (!resGet.isSuccess) return resGet;

    const url = new URL(resGet.data);
    const s3Path = `s3://${String(
      process.env.REACT_APP_AWS_BUCKET
    )}${decodeURIComponent(url.pathname)}`;
    console.log("s3Path", s3Path);

    return {
      isSuccess: true,
      data: s3Path,
    };
  } catch (error) {
    console.log("fileUploade Error : ", error);
    return {
      isSuccess: false,
      err: error,
    };
  }
};

/**
 * S3への複数ファイルアップロード
 * @author TAW t.ichikawa
 * @param files
 * @param basePath
 * @returns ResponseType<T>
 */
export const fileBulkUpload = async (
  files: {
    file: File;
    path?: string;
  }[],
  basePath = '',
): Promise<ResponseType<string>> => {
  try {
    if (!files || files.length === 0) return {isSuccess: false};

    const buildPath = (path: string) => {
      const funcs: ((p: string) => string)[] = [
        // 末尾に / がない場合は追加する
        (p: string) => p !== '' && !p.endsWith('/') ? `${p}/` : p,
        // 先頭に / がある場合は削除する
        (p: string) => p.startsWith('/') ? p.slice(1) : p,
      ];

      return funcs.reduce((acc, func) => func(acc), path);
    }

    const result = await Promise.allSettled(files.map(async ({file, path}) => {
      try {
        const resPut = await Storage.put(`${buildPath(basePath)}${buildPath(path ?? '')}${file.name}`, file, {
          contentType: file.type,
          level: "private",
        })
        console.log("Storage.put result", resPut);
        return {
          isSuccess: true,
          data: resPut.key,
        };
      } catch (e) {
        console.log("Storage.put error", e);
        return {
          isSuccess: false,
          data: "",
          err: e,
        };
      }
    }));

    console.log("Storage.get! ", basePath);
    const resGet = await Storage.get(basePath, {level: "private"})
      .then((r) => {
        console.log("Storage.get result", r);
        return {
          isSuccess: true,
          data: r,
        };
      })
      .catch((e) => {
        console.log("Storage.get error", e);
        return {
          isSuccess: false,
          data: "",
          err: e,
        };
      });
    if (!resGet.isSuccess) return resGet;

    const url = new URL(resGet.data);
    const s3Path = `s3://${String(
      process.env.REACT_APP_AWS_BUCKET
    )}${decodeURIComponent(url.pathname)}`;
    console.log("s3Path", s3Path);

    return {
      isSuccess: result.every(r => r.status === 'fulfilled'),
      data: s3Path,
    };
  } catch (e) {
    console.log("fileUpload Error : ", e);
    return {
      isSuccess: false,
      err: e,
    };
  }
};

export const registHandInfo = () => {
  console.log(registHandInfo);
};
