import {Box, Link, Typography} from '@mui/material'

export default function MainFooter() {
  return (
    <Box width="100%" py={1} textAlign="center"
      sx={{ bgcolor: 'grey.900',color: 'white', position:"relative" }}
    >
      <Typography variant="caption">
        {"Copyright © "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://www.robocip.or.jp/" underline="none" target="_blank">
          ROBOCIP
        </Link>{" All rights reserved."}
      </Typography>
      <Typography variant="caption" sx={{position:"absolute",right:"5px",bottom:0, color: 'grey.900'}}>
        {String(process.env.REACT_APP_VERSION)}
      </Typography>
    </Box>
  )
}