import { WorkClassDocument } from "../types/pj1dbApiWork";
import {workAttrList} from "../types/workForm";

export function extractDomain(email: string): string|null {
  const arr = email.split('@');
  return arr.length < 2 ? null : arr[1];
}

export function extractDomainFromWorkClass(work: WorkClassDocument): string|null {
  return extractDomain(work.attr.creator)
}

export interface PickSameDomainParams {
  workClasses: WorkClassDocument[],
  domain: string
}

export function pickSameDomain({workClasses, domain}: PickSameDomainParams): WorkClassDocument[] {
  return workClasses.filter((work) => extractDomainFromWorkClass(work) === domain);
}

export function excludeClassesWithChildren(classes: WorkClassDocument[]): WorkClassDocument[] {
  return classes.filter((work) => work.attr[workAttrList.children.key].length === 0);
}
