import {Radio, RadioGroupField} from "@aws-amplify/ui-react";
import React, {useCallback, useEffect} from "react";
import {Stack, Typography} from "@mui/material";
import {CameraType, ThreeControl} from "../../../utils/view3dUtil";
import Pjdb1RadioGroup from "../../../../../components/inputs/Pjdb1RadioGroup";

export interface PerspectiveFieldProps {
  control: ThreeControl | undefined;
  value: CameraType;
  // eslint-disable-next-line react/require-default-props
  onChange?: (e:  React.ChangeEvent<HTMLInputElement>) => void;
}



export function PerspectiveField(props: PerspectiveFieldProps) {
  const changeCameraType = useCallback((useOrthoCamera: boolean) => {
    if (props.control) props.control.setCameraType(useOrthoCamera);
  }, [props.control]);

  useEffect(() => {
    changeCameraType(props.value === "ortho");
  }, [changeCameraType, props.value]);

  return (
    <Stack>
      <Typography>CameraType</Typography>
      <Pjdb1RadioGroup
        name="perspective"
        radios={[
          {btnKey: 'ortho', btnLbl: '平行投影'},
          {btnKey: 'perspective', btnLbl: '透視投影'},
        ]}
        value={props.value}
        onChange={(e) => {
          changeCameraType(e.target.value === "ortho")
          if (props.onChange) props.onChange(e);
        }}
      />
    </Stack>
  )
}
