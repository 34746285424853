import React from "react";
import Pjdb1TextField from "../../../../../components/inputs/Pjdb1TextField";
import WithLabel from "./WithLabel";
import {workAttrList} from "../../../types/workForm";
import {makeDateJST} from "../../../../../utils/dateUtil";

export interface WhenProps {
  // eslint-disable-next-line react/require-default-props
  value?: string | number;
  // eslint-disable-next-line react/require-default-props
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // eslint-disable-next-line react/require-default-props
  required?: boolean;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  // eslint-disable-next-line react/require-default-props
  isClear?: boolean;
}

export default function When(props: WhenProps) {
  return (
    <WithLabel label={workAttrList.when.label}>
      <Pjdb1TextField
        label={workAttrList.when.label}
        name={workAttrList.when.key}
        value={props.value ?? ''}
        required={props.required}
        onChange={props.onChange}
        disabled={props.disabled}
        isClear={props.isClear}
        xs={6}
        toolTip="モデルの測定日時"
        inputProps={{ type: 'date', max: makeDateJST().toISOString().split('T')[0] }}
        inputLabelProps={{ shrink: true }}
      />
    </WithLabel>
  )
}
