import { toast } from "react-toastify";

const useNotification = () => {
  const savedMsg = () => {
      toast.info("保存しました。", {
          position: "top-center", // ポジションを指定。top-right, top-left, top-center, bottom-left, bottom-right, bottom-centerから選択
          autoClose: 5000, // 設定したミリ秒経過時にclose defaultは5000
          hideProgressBar: false, // プログレスバーを制御
          closeOnClick: true, // クリックで消せるか否かを制御
          pauseOnHover: true, // ホバーするとミリ秒の進行が止まる
          draggable: false, // ドラッグしながら消すことができる
          progress: undefined, // プログレスバーの長さを制御 undefinedで問題なし
          theme: "light", // 背景をlight,dark,cloredから選択
      });
  };
  const updatedMsg = () => {
      toast.info("更新しました。", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
      });
  };
  const deletedMsg = () => {
      toast.info("削除しました。", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
  };
  // 任意のエラーメッセージを表示できるように引数を持たせる
  const errorMsg = (msg: string) => {
      toast.error(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
  };
  const infoMsg = (msg: string) => {
      toast.info(msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
  };
  return { savedMsg, updatedMsg, deletedMsg, errorMsg, infoMsg };
};
export default useNotification;