/* eslint-disable react/require-default-props */
import React from 'react';
import { FormControlLabel, Checkbox } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Pjdb1ToolTip from "./Pjdb1ToolTip";

interface Props {
  name: string;
  label: string;
  value: boolean|undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> ) => void;
  disabled?: boolean;
  toolTip?: string|JSX.Element;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1CheckBox]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1CheckBox]:areEqual(next)',nextProps);
  let retEqual = prevProps.value === nextProps.value;
  if(nextProps.disabled !== undefined && prevProps.disabled !== nextProps.disabled) retEqual = false;
  // console.log(`[${prevProps.name}]`,'[Pjdb1CheckBox]:areEqual(dif) val:',retEqual);
  return retEqual;
}

/**
 * チェックボックス
 * @author TAW j.sano
 * @param props
 * @returns
 */
// eslint-disable-next-line arrow-body-style
export default React.memo((props: Props) => {
  // console.log(`[${props.name}]`,'[Pjdb1CheckBox]:rendering!!',props);
  return (
    <Grid container spacing={2} alignItems="center" sx={{flexWrap:"nowrap"}}>
      <Grid sx={{pt:"6px !important"}}>
        <FormControlLabel control={
          <Checkbox
            name={props.name} checked={props.value}
            onChange={(e)=>{
              if(props.onChange) props.onChange(e);
            }}
          />
        } label={props.label}
        disabled={props.disabled !== undefined ? props.disabled : false}
        />
      </Grid>
      { props.toolTip !== undefined &&
      <Pjdb1ToolTip toolTip={props.toolTip} />
      }
    </Grid>
  );
},areEqual);
