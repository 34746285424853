/* eslint-disable no-void */
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import {
  Typography,
  Container,
  Box,
  Card,
  Paper,
  TableContainer,Table,TableHead,TableRow,TableCell,TableBody
} from '@mui/material/';
import Grid from '@mui/material/Grid2';
import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { EQUIPMENT_URLS } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE, initialState } from "../contexts/EquipmentContext";

import { ResponseType, deleteHand, findFingertip} from '../../../utils/awsAmplifyUtil'
import { HandDocument, handInitDoc, makeHandAttrList, makeFormHandFromDocument } from '../types/pj1dbApiHand';
import { FingertipDocument, fingertipInitDoc, makeFingertipAttrList } from '../types/pj1dbApiFingertip';
import { AttrListRowType } from '../types/handForm';

import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import UrdfLoader from '../components/UrdfLoader';

export default function HandDetail() {
  const { userRole, userDomain } = useUserRole();
  const { deletedMsg ,errorMsg } = useNotification();
  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const { state, dispatch } = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [info, setInfo] = useState<HandDocument>(handInitDoc);
  const [handAttrList, setHandAttrList] = useState<AttrListRowType[]>([]);
  const [dispImageType, setDispImageType] = useState<string>("image");

  interface ImageListRowType {
    key:string;
    type:string;
    url:string;
  }
  const [handImageList, setHandImageList] = useState<ImageListRowType[]>([]);
  const [selectedHandImageKey, setSelectedHandImageKey] = useState<string>("");

  const [fingertipList, setFingertipList] = useState<FingertipDocument[]>([]);
  const [selectedFingertipInfo, setSelectedFingertipInfo] = useState<FingertipDocument>(fingertipInitDoc);
  const [fingertipAttrList, setFingertipAttrList] = useState<AttrListRowType[]>([]);
  const [fingertipImageList, setFingertipImageList] = useState<ImageListRowType[]>([]);
  const [selectedFingertipImageKey, setSelectedFingertipImageKey] = useState<string>("");

  const navigate = useNavigate();
  const urlParams = useParams<{ handId: string }>();

  const imageStyle = {
    border : "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border : "2px solid #48B130",
  }

  useEffect(()=>{
    let tempInfo:HandDocument;
    if(urlParams.handId===state.handDetailPage.info.attr.handId){
      tempInfo = state.handDetailPage.info;
    }else if (!urlParams.handId){
      tempInfo = state.handDetailPage.info;
    }else{
      [tempInfo] =
        state.handListPage.handList.filter(
          (item)=>item.attr.handId === urlParams.handId
        );
    }
    if(tempInfo!==undefined){
      setInfo(tempInfo);
    }else{
      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_LIST.TAB_IDX });
      navigate(`${EQUIPMENT_URLS.HAND_LIST.URL}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const findFingertipProc = async () => {
    try {
      const cond = {
        "query":{
          "handIds":{ "$regex" : info.attr.handId }
        }
      };
      const result = await findFingertip(cond);
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        let fingertipListData:FingertipDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
        fingertipListData = fingertipListData.sort((a,b) => a.attr.fingertipInfo.name.localeCompare(b.attr.fingertipInfo.name));
        setFingertipList(fingertipListData);
        // 画像リスト作成
        setFingertipImageList(
          fingertipListData.map((row)=>{
            let sType = "";
            if(row.attr.fingertipInfo.setFingertip){
              sType = "maker";
            }else if(row.attr.public){
              sType = "public";
            }else{
              sType = "private";
            }
            return {
              key:row.attr.fingertipId,
              type: sType,
              url: String(row.url?.image[0])
            }
          })
        );
      }else{
        setFingertipList([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const makeHandImageList = (fingertipId = "") => {
    if(info.url){
      const tmpImgList:ImageListRowType[]=[];
      let imgIdx = 0;

      // 合成画像を追加
      if(fingertipId){
        info.url?.image.forEach((imageUrl)=>{
          const tempUrl:string[] = imageUrl.split("/");
          // console.log(tempUrl);
          if(tempUrl.length>7){
            if(tempUrl[6]==="image"&& tempUrl[7]===fingertipId){
              tmpImgList.push({key:`image${imgIdx}`,type:"",url:imageUrl});
              imgIdx+=1;
            }
          }
        });
      }

      // 合成画像以外を追加
      info.url?.image.forEach((imageUrl)=>{
        const tempUrl:string[] = imageUrl.split("/");
        // console.log(tempUrl);
        if(tempUrl.length>7){
          if(tempUrl[6]==="image"&&(tempUrl[7]==="main"||tempUrl[7]==="sub")){
            tmpImgList.push({key:`image${imgIdx}`,type:"",url:imageUrl});
            imgIdx+=1;
          }
        }
      });

      setHandImageList(tmpImgList);
      setSelectedHandImageKey("image0");
    }
  }

  useEffect(() => {
    if(info.attr.handId==="") return;

    // ハンド属性表
    setHandAttrList(makeHandAttrList(info));

    // 画像リストを作成
    makeHandImageList();

    // 取付け可能な爪を検索
    void findFingertipProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[info]);


  useEffect(() => {
    if(selectedFingertipInfo.attr.fingertipId!==""){
      // 爪属性表
      setFingertipAttrList(makeFingertipAttrList(selectedFingertipInfo,true));

      // ハンド属性表（選択した爪情報を加味）
      setHandAttrList(makeHandAttrList(info,selectedFingertipInfo));
    }else{
      // ハンド属性表（爪選択解除）
      setHandAttrList(makeHandAttrList(info));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedFingertipInfo]);

  const selectFingertip = (imageKey:string) => {
    setSelectedFingertipImageKey(imageKey);
    const selFingertipInfo = fingertipList.find((row)=>row.attr.fingertipId===imageKey);
    if (selFingertipInfo){
      setSelectedFingertipInfo(selFingertipInfo);
    }else{
      setSelectedFingertipInfo(fingertipInitDoc);
    }
  }

  const forwardFingertipDetail = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.FINGERTIP_DETAIL.TAB_IDX });
    dispatch({ type: CONTEXT_ACTION_TYPE.FINGERTIP_DETAIL , payload: {info:selectedFingertipInfo} });
    navigate(`${EQUIPMENT_URLS.FINGERTIP_DETAIL.URL}/${selectedFingertipInfo.attr.fingertipId}`);
  }

  const deleteData = async () => {
    try {
      console.log("deleteData",info.attr.handId);
      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      setIsLoading(true);

      const delData = {
        id:info.attr.handId
      }
      const resDel:ResponseType<string> = await deleteHand(delData);
      if(!resDel.isSuccess){
        errorMsg("削除処理が失敗しました");
        return;
      }

      deletedMsg();

      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL ,
        payload: {info: initialState.handDetailPage.info} });

      const newList = state.handListPage.handList.filter(row=>row.attr.handId!==info.attr.handId);
      const newState = state.handListPage;
      newState.handList = newList;
      newState.searchCond.listCount = newList.length;
      dispatch({ type: CONTEXT_ACTION_TYPE.HAND_LIST , payload: newState });

      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_LIST.TAB_IDX });
      navigate(`${EQUIPMENT_URLS.HAND_LIST.URL}`);

    } catch (error) {
      console.log("deleteData Error : ", error);
      errorMsg("削除処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }
  const editData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeHand:false,handPanel:makeFormHandFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.REGISTE.TAB_IDX });
    navigate(`${EQUIPMENT_URLS.REGISTE.URL}/hand/${info.attr.handId}`);
  }
  const copyData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeHand:true,handPanel:makeFormHandFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.REGISTE.TAB_IDX });
    navigate(`${EQUIPMENT_URLS.REGISTE.URL}/copyHand/${info.attr.handId}`);
  }

  return (
    <>
      <Container maxWidth="lg" sx={{maxWidth:"1500px !important"}}>
        <Card sx={{p:3,mt:2}}>

          <Grid container spacing={3} alignItems="flex-end" >
            <Grid>
              <Typography variant="h6">ハンド詳細</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              <Typography variant="body2" sx={{color:"gray"}}>
                登録日時（登録者）：
                  {`${info.attr?.registrationDate?new Date(info.attr?.registrationDate).toLocaleString():""}
                  （${userRole === ROLE_KEYS.ADMIN ? String(info.attr.registrant) : String(info.attr.domain)}）`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
            <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>

              <Pjdb1RadioGroup
                name="dispImageType" value={dispImageType}
                onChange={(e)=>(setDispImageType(e.target.value))}
                radios={[
                  {btnKey:"image",btnLbl:"画像"},
                  {btnKey:"model",btnLbl:"モデル"},
                ]}
                disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
              />

              <Grid container spacing={2} alignItems="flex-start" sx={{mt:0}}>
                { dispImageType === "image" &&
                  <>
                    <Grid size={{xs:3,md:2}}
                    sx={{pt:"0px !important",pr:"1px !important",height:"50vw",maxHeight:"70vh",overflowY:"auto",overflowX:"hidden"}}
                    className="thin-scroll-bar">
                        {handImageList.map(row =>(
                          <Box key={row.key}>
                            <a href="" onClick={(e)=>{
                              e.preventDefault();
                              setSelectedHandImageKey(row.key);
                            }}>
                              <img src={row.url} className="square-image" style={row.key === selectedHandImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                            </a>
                          </Box>
                        ))}
                    </Grid>
                    <Grid size={{xs:9,md:10}} sx={{pt:"0px !important"}}>
                      <img src={handImageList.find((row)=>(row.key===selectedHandImageKey))?.url} style={imageStyle} className="square-image" alt="" />
                    </Grid>
                  </>
                }
                { dispImageType === "model" &&
                  <Grid sx={{p:"0px !important",border:"1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                    <UrdfLoader urdfUrl={String(info.url?.urdf[0])} />
                  </Grid>
                }
              </Grid>



            </Grid>
            <Grid size={{xs:12,md:5}} sx={{pt:"20px !important"}}>

              <Typography variant="subtitle1">ハンド属性表</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                  <TableHead sx={{bgcolor:"#EEE"}}>
                    <TableRow>
                      <TableCell sx={{px:1,py:0.5}}>分類</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {handAttrList.map((row) => (
                      <TableRow
                        key={row.key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width={130} sx={{px:1,py:0.5}}>{row.class}</TableCell>
                        <TableCell width={140} sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.attr) }} />
                        <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>

          { info.attr.handInfo.fingertipDetachable &&
            <>
              <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
                <Grid sx={{pt:"0px !important"}}>
                  <Typography variant="h6">接続可能爪一覧</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
                <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>
                  { fingertipImageList.length === 0 &&
                    <Typography variant="subtitle1" sx={{ml:2,fontWeight:"bold"}}>
                      接続可能爪が登録されていません。
                    </Typography>
                  }
                  { fingertipImageList.filter(row=>(row.type==="maker")).length > 0 &&
                    <Box sx={{ml:2,mt:1}}>
                      <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>メーカー標準爪</Typography>
                      <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:2}}>
                        {fingertipImageList.filter(row=>(row.type==="maker")).map(row =>(
                          <Grid key={row.key} size={{xs:3}} sx={{pt:"0px !important"}}>
                            <a href="" onClick={(e)=>{
                              e.preventDefault();
                              selectFingertip(row.key);
                              makeHandImageList(row.key);
                            }}>
                              <img src={row.url} className="square-image" style={row.key === selectedFingertipImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  }
                  { fingertipImageList.filter(row=>(row.type==="public")).length > 0 &&
                    <Box sx={{ml:2,mt:1}}>
                      <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>公開カスタム爪</Typography>
                      <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:2}}>
                        {fingertipImageList.filter(row=>(row.type==="public")).map(row =>(
                          <Grid key={row.key} size={{xs:3}} sx={{pt:"0px !important"}}>
                            <a href="" onClick={(e)=>{
                              e.preventDefault();
                              selectFingertip(row.key);
                              makeHandImageList(row.key);
                            }}>
                              <img src={row.url} className="square-image" style={row.key === selectedFingertipImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  }
                  { fingertipImageList.filter(row=>(row.type==="private")).length > 0 &&
                    <Box sx={{ml:2,mt:1}}>
                      <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>非公開カスタム爪</Typography>
                      <Grid container spacing={2} alignItems="flex-start" sx={{mt:0,pl:2}}>
                        {fingertipImageList.filter(row=>(row.type==="private")).map(row =>(
                          <Grid key={row.key} size={{xs:3}} sx={{pt:"0px !important"}}>
                            <a href="" onClick={(e)=>{
                              e.preventDefault();
                              selectFingertip(row.key);
                              makeHandImageList(row.key);
                            }}>
                              <img src={row.url} className="square-image" style={row.key === selectedFingertipImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                            </a>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  }
                </Grid>
                <Grid size={{xs:12,md:5}} sx={{pt:"7px !important"}}>
                  { selectedFingertipImageKey !== "" &&
                    <>
                      <Typography variant="subtitle1">爪属性表</Typography>
                      <TableContainer component={Paper}>
                        <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                          <TableHead sx={{bgcolor:"#EEE"}}>
                            <TableRow>
                              <TableCell sx={{px:1,py:0.5}}>分類</TableCell>
                              <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                              <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {fingertipAttrList.map((row) => (
                              <TableRow
                                key={row.key}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell width={130} sx={{px:1,py:0.5}}>{row.class}</TableCell>
                                <TableCell width={140} sx={{px:1,py:0.5}}>{row.attr}</TableCell>
                                <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Box sx={{mt:2}}>
                        <Pjdb1Button
                          label="爪選択を解除"
                          variant="contained"
                          color="inherit"
                          onClick={()=>{
                            // setSelectedFingertipImageKey("");
                            // setHandAttrList(makeHandAttrList(info));
                            selectFingertip("");
                            makeHandImageList();
                          }}
                        />

                        <Pjdb1Button
                          label="爪詳細へ"
                          variant="contained"
                          color="primary"
                          sx={{ml:2}}
                          onClick={forwardFingertipDetail}
                        />
                      </Box>
                    </>
                  }
                </Grid>
              </Grid>
            </>
          }

        </Card>
      </Container>

      <Box component="section" sx={{ textAlign:"center", mt:2 }}>
        { (userRole === ROLE_KEYS.ADMIN || (userRole !== ROLE_KEYS.SIER && userDomain===info.attr.domain)) &&
          <Pjdb1Button
            label="登録情報を変更"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={editData}
            disabled={info.attr.handId===""}
          />
        }

        { (userRole === ROLE_KEYS.ADMIN || (userRole !== ROLE_KEYS.SIER && userDomain===info.attr.domain)) &&
          <Pjdb1Button
            label="登録情報を削除"
            variant="contained"
            color="error"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={()=>{void deleteData();}}
            disabled={info.attr.handId===""}
          />
        }

        { (userRole !== ROLE_KEYS.SIER) &&
          <Pjdb1Button
            label="コピー登録"
            variant="contained"
            color="secondary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={copyData}
            disabled={info.attr.handId===""}
          />
        }
      </Box>

      <ConfirmDialog
        title="削除確認"
        message="ハンド情報を削除しますか？"
        confirmButton="削除"
        confirmButtonStyle="error"
      />
    </>
  )
}