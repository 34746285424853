import InstanceDescription from "../fields/InstanceDescription";
import {workAttrList} from "../../../types/workForm";
import {handleInputChange} from "../../../../../utils/formUtil";

export interface InstanceInputGroupProps<T extends InstanceFormInputs = InstanceFormInputs> {
  inputs: T;
  setInputs: React.Dispatch<React.SetStateAction<T>>;
  isClear: boolean;
}

export interface InstanceFormInputs {
  [workAttrList.description.key]?: string;
}

export default function InstanceInputGroup<T extends InstanceFormInputs = InstanceFormInputs>(props: InstanceInputGroupProps<T>) {
  return (
    <InstanceDescription value={props.inputs[workAttrList.description.key]}
                         onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
                         isClear={props.isClear}/>
  )
}
