import {Box, Container, SelectChangeEvent, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {useContext, useEffect, useState} from "react";
import SearchForm, {useSearchFormProps} from "../components/forms/SearchForm";
import SortOrder, {useSortOrder} from "../components/forms/SortOrder";
import {FormWorkSearchCond, workAttrList} from "../types/workForm";
import {WorkSortOrderValue} from "../../../constants/constants";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType,
  WorkRegisteredListPageWorkListItem
} from "../contexts/WorkContext";
import useNotification from "../../../utils/notificationUtil";
import {WorkClassDocument, WorkInstanceDocument, WorkModelDocument} from "../types/pj1dbApiWork";
import {handleInputChangeSelect} from "../../../utils/formUtil";
import {findWork} from "../../../utils/awsAmplifyUtil";
import {excludeClassesWithChildren, extractDomain} from "../utils/workUtil";
import WorkGroupListItem from "../components/lists/WorkGroupListItem";
import useUser from "../hooks/useUser";
import {equalAdminRoleRule, Role} from "../policies/rules";

export default function WorkRegisteredList() {
  const {
    workList,
    isLoading,
    notFoundMsg,
    setSearchCond,
    changeSortOrder,
    searchCond,
    searchWorks,
    selectedInstanceId,
    changeSelectedInstanceId,
    restore,
  } = useSearchWorkHelpers();

  // 検索済みデータの復元
  useEffect(() => {
    restore("別サンプル登録する場合：商品/部品を検索して登録してください。<br>サンプル情報を修正する場合：商品/部品を検索して選択後に修正してください。<br>別モデルを登録する場合：商品/部品を検索して選択後に登録してください。");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchForm isLoading={isLoading} searchCond={searchCond} setSearchCond={setSearchCond} onSubmit={(e) => {
        e.preventDefault();
        searchWorks().catch(console.error);
      }}/>

      <Container
        sx={{p: 0, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
        maxWidth={false}
      >
        <Grid container alignItems="end" sx={{mt: 2, flexWrap: "nowrap"}} size={{xs: 12}}>
          <Grid size="grow">
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
              検索結果一覧
            </Typography>
          </Grid>
          <Grid size="auto">
            <Grid container alignItems="center" spacing={2} sx={{flexWrap: "nowrap"}}>
              <Grid>
                <Box component="span">
                  {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
                </Box>
              </Grid>
              <Grid>
                <Box component="span">
                  <SortOrder value={searchCond.sortOrder} onChange={changeSortOrder}/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container alignItems="stretch" spacing={2} sx={{mt: 2}}>
          {workList.map(row => (
            <Grid key={row.class.attr[workAttrList.classId.key]} size={12}>
              <WorkGroupListItem work={row.class} instances={row.instances} selectedInstanceId={selectedInstanceId}
                                 onChange={changeSelectedInstanceId}/>
            </Grid>
          ))}
        </Grid>

        {(workList.length === 0) && (
          <Box sx={{my: 3, width: "100%", textAlign: "center",}}>
            <Typography variant="subtitle1" sx={{fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: notFoundMsg}}/>
          </Box>
        )}
      </Container>
    </>
  )
}

function useSearchWorkHelpers() {
  const {user, role} = useUser();
  const {errorMsg} = useNotification();
  const {state, dispatch} = useContext(DataStoreContext);
  const [workList, setWorkList] = useState<WorkRegisteredListPageWorkListItem[]>([]);
  const [notFoundMsg, setNotFoundMsg] = useState("");
  const {isLoading, setIsLoading, searchCond, setSearchCond} = useSearchFormProps();
  const {sortWorkList: baseSortWorkList} = useSortOrder();
  const sortWorkList = (list: WorkRegisteredListPageWorkListItem[], order: WorkSortOrderValue): WorkRegisteredListPageWorkListItem[] => {
    const sorted = baseSortWorkList(list.map(w => w.class), order);
    return sorted.map((item) => {
      const work = list
        .find(w => w.class.attr[workAttrList.classId.key] === item.attr[workAttrList.classId.key]);
      if (!work) {
        throw new Error("Work not found");
      }
      return work;
    });
  }
  const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(null);

  const changeSortOrder = (event: SelectChangeEvent<WorkSortOrderValue>) => {
    handleInputChangeSelect<FormWorkSearchCond>(event, "sortOrder", searchCond, setSearchCond);
    setWorkList(sortWorkList(workList, event.target.value as WorkSortOrderValue));
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTERED_LIST, payload: {
        ...state.workRegisteredListPage,
        searchCond: {
          ...searchCond,
          sortOrder: event.target.value as WorkSortOrderValue
        },
        workList,
      } satisfies DataStoreType['workRegisteredListPage']
    });
  }

  const changeSelectedInstanceId = (instanceId: string | null) => {
    setSelectedInstanceId(instanceId);
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTERED_LIST, payload: {
        searchCond,
        workList,
        selectedInstanceId: instanceId,
      } satisfies DataStoreType['workRegisteredListPage']
    });
  }

  const searchWorks = async () => {
    setIsLoading(true);
    try {
      const models = await findModels(role, user.attributes?.email ?? '');
      if (!models.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const instanceIds = Object.keys(models.data?.items ?? {}).map(key => models.data?.items[key].attr[workAttrList.instanceId.key]).filter((id): id is string => id !== undefined);
      const instances = await findInstances(instanceIds);
      if (!instances.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const classes = await findClasses(searchCond.keyword);
      if (!classes.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data: WorkRegisteredListPageWorkListItem[] = sortWorkList(
        convertToList(excludeClassesWithChildren(Object.values(classes.data?.items ?? {})), Object.values(instances.data?.items ?? {}), Object.values(models.data?.items ?? {})),
        searchCond.sortOrder
      );

      if (data.length === 0) {
        setNotFoundMsg("該当データがありません。");
      }
      setWorkList(data);
      setSearchCond({...searchCond, listCount: data.length});
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTERED_LIST, payload: {
          ...state.workRegisteredListPage,
          searchCond: {...searchCond, listCount: data.length},
          workList: data,
        } satisfies DataStoreType['workRegisteredListPage']
      });
    } finally {
      setIsLoading(false);
    }
  };

  const restore = (initMsg: string) => {
    setSearchCond(state.workRegisteredListPage.searchCond);
    setWorkList(state.workRegisteredListPage.workList);
    setSelectedInstanceId(state.workRegisteredListPage.selectedInstanceId);
    setNotFoundMsg(initMsg);
    if (state.workRegisteredListPage.searchCond.keyword !== "" && state.workRegisteredListPage.workList.length === 0) {
      setNotFoundMsg('該当データがありません。');
    }
  }

  return {
    errorMsg,
    workList,
    setWorkList,
    notFoundMsg,
    setNotFoundMsg,
    isLoading,
    searchCond,
    setSearchCond,
    changeSortOrder,
    searchWorks,
    selectedInstanceId,
    changeSelectedInstanceId,
    restore,
  }
}

function findModels(role: Role, email: string) {
  return findWork('model', {
    version: "work-user",
    queryAttr: equalAdminRoleRule({role}) ? {} : {
      $and: [
        {
          [workAttrList.creator.key]: {
            "$regex": `^.+@${extractDomain(email) ?? ''}$`,
            "$options": "i"
          }
        },
      ],
    },
  });
}

function findClasses(keyword: string) {
  return findWork('class', {
    version: "work-user",
    queryAttr: {
      ...(keyword === "" ? {} : {
        $or: [
          {[workAttrList.nameJp.key]: {"$regex": keyword, "$options": "i"}},
          {[workAttrList.nameEn.key]: {"$regex": keyword, "$options": "i"}},
          {[workAttrList.code.key]: {"$regex": keyword, "$options": "i"}},
        ]
      }),
    },
  });
}

function findInstances(instanceIds: string[]) {
  return findWork('instance', {
    version: "work-user",
    queryAttr: {
      [workAttrList.instanceId.key]: {"$in": instanceIds}
    },
  });
}

function convertToList(workClasses: WorkClassDocument[], workInstances: WorkInstanceDocument[], workModels: WorkModelDocument[]): WorkRegisteredListPageWorkListItem[] {
  return workClasses.map((workClass) => {
    const instances = workInstances.filter(instance => instance.attr[workAttrList.classId.key] === workClass.attr[workAttrList.classId.key]);
    return {
      class: workClass,
      instances: instances.map(instance => {
        const models = workModels.filter(model => model.attr[workAttrList.instanceId.key] === instance.attr[workAttrList.instanceId.key]);
        return {instance, models};
      })
    };
  }).filter(item => !(item.instances.length === 0 || item.instances.some(instance => instance.models.length === 0)));
}
