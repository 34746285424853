import {Box, Card, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Pjdb1ImputEx from "../../../../components/inputs/Pjdb1ImputEx";
import ClassInfoGroup from "./groups/ClassInfoGroup";
import InstanceDescription from "./fields/InstanceDescription";
import {workAttrList} from "../../types/workForm";
import {handleInputChange} from "../../../../utils/formUtil";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import useMutationHelper from "../../hooks/useMutationHelper";
import {updateWorkInstance} from "../../../../utils/awsAmplifyUtil";
import {WorkClassDocument, WorkInstanceDocument} from "../../types/pj1dbApiWork";
import {Policies, policies} from "../../policies";
import Forbidden from "../errors/Forbidden";
import useUser from "../../hooks/useUser";

export interface EditInstanceFormProps {
  work: WorkClassDocument;
  instance: WorkInstanceDocument;
  inputs: EditInstanceFormInputs,
  setInputs: React.Dispatch<React.SetStateAction<EditInstanceFormInputs>>;
  // eslint-disable-next-line react/require-default-props
  onError?: (err: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  onSuccess?: () => void;
}

export interface EditInstanceFormInputs {
  [workAttrList.description.key]?: string;
}

export const editInstanceFormInitInputs: EditInstanceFormInputs = {}

export function canEditInstance(params: Parameters<Policies['editInstance']['can']>[0]) {
  return policies.editInstance.can(params);
}

export default function EditInstanceForm(props: EditInstanceFormProps) {
  const {user, role} = useUser();
  const {
    ConfirmDialog,
    formRef,
    isClear,
    isLoading,
    clear,
    updateInstance,
  } = useFormHelper(props);

  if (!policies.editInstance.can({user, role, work: props.instance})) {
    return <Forbidden/>
  }

  return (
    <>
      <ConfirmDialog
        title='ワーク情報登録'
        message='ワーク情報を登録します。入力内容に間違いがないか確認してください。'
        confirmButton='登録'
        confirmButtonStyle="primary"
      />

      <Container maxWidth="lg">
        <Card sx={{p: 3}}>
          <Grid container spacing={3} alignItems="flex-end" justifyContent='space-between' sx={{mb: 2}}>
            <Grid>
              <Typography variant="h6">商品/部品登録</Typography>
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <ClassInfoGroup work={props.work}/>

            <InstanceDescription value={props.inputs[workAttrList.description.key]}
                                 onChange={(e) => (handleInputChange<EditInstanceFormInputs>(e, props.inputs, props.setInputs))}
                                 isClear={isClear}/>
          </form>
        </Card>
      </Container>

      <Box component="section" sx={{textAlign: "center"}}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={clear}
        />
        <Pjdb1Button
          label="ワーク情報を登録"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={() => {
            updateInstance().catch(console.error)
          }}
        />
      </Box>
    </>
  );
}

function useFormHelper(params: EditInstanceFormProps) {
  const {makeMutation, ...helper} = useMutationHelper({
    onClear: () => {
      params.setInputs({
        ...editInstanceFormInitInputs,
        ...params.work.attr,
      });
    }
  });

  const updateInstance = makeMutation({
    onCompleted: () => {
      if (params.onSuccess) {
        params.onSuccess();
      } else {
        helper.notification.savedMsg();
      }
    },
    onError: (err) => {
      helper.handleError({error: err, message: '更新処理が失敗しました。'});
      params.onError?.(err);
    },
    validate: () => true,
    callback: async () => {
      const instanceResult = await updateWorkInstance({
        instanceId: params.instance.attr[workAttrList.instanceId.key],
        classId: params.instance.attr[workAttrList.classId.key],
        [workAttrList.description.key]: params.inputs[workAttrList.description.key],
      });
      if (!instanceResult.isSuccess) {
        return helper.handleError({error: instanceResult.err, message: '更新処理が失敗しました。'});
      }

      return true;
    },
  });

  return {
    updateInstance,
    ...helper,
  }
}
