/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React,{ useState , useEffect} from "react";

import { Box , IconButton, FormControlLabel, Switch} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { FormHandAttr, FormExtra } from '../../types/handForm'

import {handleInputChangeNameVal} from "../../../../utils/formUtil"

import Pjdb1TextField from '../../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList from '../../../../components/inputs/Pjdb1TextFieldList';

import Pjdb1ToolTip from "../../../../components/inputs/Pjdb1ToolTip";

interface Props {
  inputs: FormHandAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormHandAttr>>;
  isClear?: boolean;
  customErrorCheck?:() => void;
  customErrorMsg?:string[];
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log('[HandExtraFields]:areEqual(prev)',prevProps);
  // console.log('[HandExtraFields]:areEqual(next)',nextProps);
  let retEqual:boolean = JSON.stringify(prevProps.inputs.extra) === JSON.stringify(nextProps.inputs.extra);
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  if (nextProps.customErrorMsg !== undefined && JSON.stringify(prevProps.customErrorMsg) !== JSON.stringify(nextProps.customErrorMsg)) retEqual = false;
  // console.log('[HandExtraFields]:areEqual(dif) val:',retEqual);
  return retEqual;
}

export default React.memo((props: Props) => {
  // console.log('[HandExtraFields]:rendering!!',props);
  const [dispList, setDispList] = useState<FormExtra[]>([]);
  useEffect(()=>{
    let dispListData:FormExtra[] =[];
    const listCount = props.inputs.extra && props.inputs.extra.length > 0 ? props.inputs.extra.length : 1;
    if(listCount===0){
      dispListData.push({ key: "", value: "", values: [""], isArray: false });
    }else{
      dispListData = [...props.inputs.extra];
    }
    setDispList(dispListData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.inputs.extra]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>|null,controlName:string,rowIdx:number,values:string[]=[]) => {
    let controlValue = "";
    let controlValues = [""];
    if(event !== null && controlName==="key"||controlName==="value"){
      controlValue = String(event?.target.value);
    }else if(event === null && controlName==="values"){
      controlValues = values;
    }

    // console.log("handleChange",controlName,rowIdx,controlValue);
    setDispList((prevList)=>{
      const newList:FormExtra[] = prevList.map((row,idx)=>{
        if(idx === rowIdx){
          return {
            key: controlName==="key" ? controlValue : row.key,
            value: controlName==="value" ? controlValue : row.value,
            values: controlName==="values" ? controlValues : row.values,
            isArray: row.isArray,
          };
        }
        return row;
      });
      handleInputChangeNameVal<FormHandAttr>("extra", newList , props.inputs, props.setInputs);
      return newList;
    });
  }
  const handleChangeMulti = (event: React.ChangeEvent<HTMLInputElement>,rowIdx:number) => {
    const isArrayChk = event.target.checked;
    // console.log("handleChangeMulti",rowIdx,isArray);
    setDispList((prevList)=>{
      const newList:FormExtra[] = prevList.map((row,idx)=>{
        if(idx === rowIdx){
          return {
            key: row.key,
            value: isArrayChk ? row.value : row.values[0],
            values: isArrayChk ? row.values.map((val,i) => i===0 ? row.value : val) : row.values,
            isArray: isArrayChk,
          };
        }
        return row;
      });
      handleInputChangeNameVal<FormHandAttr>("extra", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  const addList = () => {
    setDispList((prevList)=>{
      const newList:FormExtra[] = prevList;
      newList.push({
        key: "",
        value: "",
        values: [""],
        isArray: false,
      });
      handleInputChangeNameVal<FormHandAttr>("extra", newList , props.inputs, props.setInputs);
      return newList;
    });
  }
  const delList = (index:number) => {
    let newList:FormExtra[];
    setDispList((prevList)=>{
      newList = prevList;
      if(newList.length===1){
        newList[0] = { key: "", value: "",values: [""], isArray: false };
      }else{
        newList = prevList.filter((val,idx) => idx !== index);
      }
      handleInputChangeNameVal<FormHandAttr>("extra", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  return (
    <>
      {dispList.map((row,index) => (
        <Box key={`row_${index}`}>
          <Grid container spacing={1} alignItems="start">
            <Grid width="300px">
              <Pjdb1TextField
                label="項目名"
                name={`key_${index}`} value={row.key}
                onChange={(e)=>{handleChange(e,"key",index)}}
                isClear={props.isClear}
                required
                customErrorCheck={props.customErrorCheck}
                customErrorMsg={
                  props.customErrorMsg!==undefined && props.customErrorMsg.length > index ?
                  props.customErrorMsg[index]:""}
              />
            </Grid>
            <Grid width="400px">

              { !row.isArray &&
                <Pjdb1TextField
                  label="項目値"
                  name={`value_${index}`} value={row.value}
                  onChange={(e)=>{handleChange(e,"value",index)}}
                  isClear={props.isClear}
                  required
                />
              }
              { row.isArray &&
                <Pjdb1TextFieldList
                  label="項目値"
                  name={`values_${index}`} value={row.values}
                  onChange={(name,values)=>(handleChange(null,"values",index,values))}
                  isClear={props.isClear}
                  required
                />
              }

            </Grid>
            <Grid sx={{pt:1}}>
              <FormControlLabel
                control={
                  <Switch
                    name={`multi_${index}`}
                    checked={row.isArray}
                    onChange={(e)=>{handleChangeMulti(e,index)}}
                    sx={{mr:-1}}
                  />
                }
                sx={{mr:0}}
                label="複数値"
              />
            </Grid>
            <Grid>
              <Pjdb1ToolTip toolTip={
                <span>
                  任意の項目名とそれに対する項目値を入力。<br />
                  ※複数の項目値を入力したい場合は、「複数値」をチェック。
                </span>
              } />
            </Grid>
            <Grid>
              <IconButton color="error" sx={{mt:1,ml:1}} onClick={()=>{delList(index)}} >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>

            { index === 0 &&
              <Grid>
                <Pjdb1ToolTip toolTip={
                  <span>
                    <AddCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで属性を追加、<br />
                    <RemoveCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで属性を削除
                  </span>
                } />
              </Grid>
            }

          </Grid>
        </Box>
      ))}

      <Box>
        <IconButton color="info" sx={{mt:0,mb:1}} onClick={addList} >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </>
  );
},areEqual);