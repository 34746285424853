/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React,{ useState , useEffect} from "react";

import { InputLabel, Box , IconButton} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { HAND_MOUNT_PATTERN } from '../../../../constants/constants'

import { FormHandAttr, FormHandShapeDetachable } from '../../types/handForm'

import {handleInputChangeNameVal} from "../../../../utils/formUtil"

import Pjdb1TextField from '../../../../components/inputs/Pjdb1TextField';
import Pjdb1RadioGroup from '../../../../components/inputs/Pjdb1RadioGroup';

import Pjdb1ToolTip from "../../../../components/inputs/Pjdb1ToolTip";

interface Props {
  inputs: FormHandAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormHandAttr>>;
  isClear?: boolean;
//  customErrorCheck?:() => void;
//  customErrorMsg?:string[];
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log('[HandShapeDetachable]:areEqual(prev)',prevProps);
  // console.log('[HandShapeDetachable]:areEqual(next)',nextProps);
  let retEqual:boolean = JSON.stringify(prevProps.inputs.handShapeDetachable) === JSON.stringify(nextProps.inputs.handShapeDetachable);
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  // if (nextProps.customErrorMsg !== undefined && JSON.stringify(prevProps.customErrorMsg) !== JSON.stringify(nextProps.customErrorMsg)) retEqual = false;
  // console.log('[HandShapeDetachable]:areEqual(dif) val:',retEqual);
  return retEqual;
}

export default React.memo((props: Props) => {
  // console.log('[HandShapeDetachable]:rendering!!',props);
  const [dispList, setDispList] = useState<FormHandShapeDetachable[]>([]);
  useEffect(()=>{
    const listCount = props.inputs.handShapeDetachable && props.inputs.handShapeDetachable.length > 0 ? props.inputs.handShapeDetachable.length : 1;
    const dispListData:FormHandShapeDetachable[] = [];
    for (let i = 0; i < listCount; i+=1) {
      if(props.inputs.handShapeDetachable.length > i){
        dispListData.push({
          mountPattern: props.inputs.handShapeDetachable[i].mountPattern,
          range: props.inputs.handShapeDetachable[i].range,
          openLength: props.inputs.handShapeDetachable[i].openLength,
          closeLength: props.inputs.handShapeDetachable[i].closeLength,
        });
      }else{
        dispListData.push({
          mountPattern: "",
          range: "",
          openLength: "",
          closeLength: "",
        });
      }
    }
    setDispList(dispListData);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.inputs.handShapeDetachable]);

  const makeToolTip = (mountPattern:string, controlName:string) => {
    let retToolTip:JSX.Element = <span>&nbsp;</span>;
    if (!mountPattern){
      retToolTip = <>取付け方向を選択してください。</>;
    }else{
      let description = <span>&nbsp;</span>;
      switch (mountPattern) {
        case "x":
          description =
            <span>
              可動部開閉寸法はねじ穴中心間の距離<br/>
              ※ねじ穴が複数ある場合は最も近いねじ穴同士について考える
            </span>;
          break;
        case "yi":
          description =
            <span>
              可動部開閉寸法は可動部内側平面間の距離<br/>
              ※可動部内側が平行でない場合、ねじ穴付近位置について考える
            </span>;
          break;
        case "yo":
          description =
            <span>
              可動部開閉寸法は可動部外側平面間の距離
            </span>;
          break;
        case "z":
          description =
            <span>
              可動部開閉寸法はねじ穴中心間の距離<br/>
              ※ねじ穴が複数ある場合は最も近いねじ穴同士について考える
            </span>;
          break;
        default:
          // console.log(mountPattern);
      }

      retToolTip =
        <Grid container spacing={1} alignItems="start">
          <Grid>
            <img src={`/images/handdb/tooltip/detachable.${mountPattern}.${controlName}.jpg`} alt={controlName}/>
          </Grid>
          <Grid>
            {description}
          </Grid>
        </Grid>;
    }

    return retToolTip;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,controlName:string,rowIdx:number) => {
    const controlValue:string = event.target.value ? event.target.value : "";
    // console.log("handleChange",controlName,rowIdx,controlValue);
    setDispList((prevList)=>{
      const newList:FormHandShapeDetachable[] = prevList.map((row,idx)=>{
        // rangeはUI上、代表一項目として配列外に切り出されているので、変更時は配列内全てのrangeを置き換え
        if(controlName==="range"){
          return {
            mountPattern: row.mountPattern,
            range: controlValue,
            openLength: row.openLength,
            closeLength: row.closeLength,
          };
        }

        if(idx === rowIdx){
          return {
            mountPattern: controlName==="mountPattern" ? controlValue : row.mountPattern,
            range: controlName==="range" ? controlValue : row.range,
            openLength: controlName==="openLength" ? controlValue : row.openLength,
            closeLength: controlName==="closeLength" ? controlValue : row.closeLength,
          };
        }

        return row;
      });
      handleInputChangeNameVal<FormHandAttr>("handShapeDetachable", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  const addList = () => {
    setDispList((prevList)=>{
      const newList:FormHandShapeDetachable[] = prevList;
      newList.push({
        mountPattern: "",
        range: prevList[0].range,
        openLength: "",
        closeLength: "",
      });
      handleInputChangeNameVal<FormHandAttr>("handShapeDetachable", newList , props.inputs, props.setInputs);
      return newList;
    });
  }
  const delList = (index:number) => {
    let newList:FormHandShapeDetachable[];
    setDispList((prevList)=>{
      newList = prevList;
      if(newList.length===1){
        newList[0] = {
          mountPattern: "",
          range: "",
          openLength: "",
          closeLength: "",
        }
      }else{
        newList = prevList.filter((val,idx) => idx !== index);
      }
      handleInputChangeNameVal<FormHandAttr>("handShapeDetachable", newList , props.inputs, props.setInputs);
      return newList;
    });
  }

  return (
    <>
      <Grid container spacing={2} alignItems="start">
        <Grid width="250px">
          <Pjdb1TextField
            label="ストローク"
            name="range" value={dispList.length > 0 ? dispList[0].range : ""}
            onChange={(e)=>{handleChange(e,"range",0)}}
            isClear={props.isClear}
            required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
            toolTip={
              <Grid container spacing={1} alignItems="start">
                <Grid>
                  <img src="/images/handdb/tooltip/detachable.range.jpg" style={{width:"320px"}} alt="ストローク"/>
                </Grid>
                <Grid>
                  <span>半角数値<br />最大開幅と最小開幅の差<br />※基準点によらない</span>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>

      {dispList.map((value,index) => (
        <Box key={`row_${index}`}>
          <Grid container spacing={2} alignItems="start">
            <Grid width="120px">
              <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>取付け方向{index+1}</InputLabel>
            </Grid>
            <Grid>
              <Pjdb1RadioGroup
                name={`mountPattern_${index}`} value={value.mountPattern}
                onChange={(e)=>{handleChange(e,"mountPattern",index)}}
                isClear={props.isClear}
                radios={HAND_MOUNT_PATTERN.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                required
                toolTip={
                  <Grid container alignItems="start" direction="column">
                    <Grid>
                      <img src="/images/handdb/tooltip/mountPattern.jpg" style={{width:"654px"}} alt="マウントパターン"/>
                    </Grid>
                    <Grid>
                      <span>ハンドと爪の取付け方向を上記パターンから選択。</span>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            <Grid>
              <IconButton color="error" sx={{mt:1,ml:1}} onClick={()=>{delList(index)}} >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>

            { index === 0 &&
              <Grid>
                <Pjdb1ToolTip toolTip={
                  <span>
                    <AddCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで取付けパターンを追加、<br />
                    <RemoveCircleOutlineIcon sx={{fontSize:"1rem",mt:"3px",mr:"3px",verticalAlign:"text-bottom"}} />ボタンで取付けパターンを削除
                  </span>
                } />
              </Grid>
            }

          </Grid>
          <Grid container spacing={2} alignItems="start">
            <Grid width="120px">&nbsp;</Grid>
            <Grid width="250px">
              <Pjdb1TextField
                label="開寸法"
                name={`openLength_${index}`} value={value.openLength}
                onChange={(e)=>{handleChange(e,"openLength",index)}}
                isClear={props.isClear}
                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={makeToolTip(value.mountPattern,"openLength")}
              />
            </Grid>
            <Grid width="250px" sx={{ml:1}}>
              <Pjdb1TextField
                label="閉寸法"
                name={`closeLength_${index}`} value={value.closeLength}
                onChange={(e)=>{handleChange(e,"closeLength",index)}}
                isClear={props.isClear}
                required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} unit="mm"
                toolTip={makeToolTip(value.mountPattern,"closeLength")}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      <Box>
        <IconButton color="info" sx={{mt:0,mb:1}} onClick={addList} >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
    </>
  );
},areEqual);