/* eslint-disable react/require-default-props */
/* eslint-disable no-void  */
/* eslint-disable @typescript-eslint/no-unsafe-argument  */
import { useState, useRef, useEffect, useContext } from 'react';
import {
  Container,
  Box,
  Typography,
  InputLabel,
  Card,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import { ARM_REACH_POINT } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";

import {
  ApiArmRegist,
  makeApiArmRegistFromForm,
  ApiArmUpdate,
  makeApiArmUpdateFromForm,
  ArmDocument
} from '../types/pj1dbApiArm'
import { FormArmAttr, armInitInputs } from '../types/armForm'

import {handleInputChange,handleInputChangeNameVal,handleInputChangeFile} from "../../../utils/formUtil"
import Pjdb1ImputEx from '../../../components/inputs/Pjdb1ImputEx';
import Pjdb1TextField from '../../../components/inputs/Pjdb1TextField';
import Pjdb1TextFieldList, {TextFieldListDescription} from '../../../components/inputs/Pjdb1TextFieldList';
import Pjdb1CheckBox from '../../../components/inputs/Pjdb1CheckBox';
import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1FileSelect  from '../../../components/inputs/Pjdb1FileSelect';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';

import ArmAxesTable from './inputs/ArmAxesTable';

import {ResponseType, fileUploade, registArm, updateArm, findArm} from '../../../utils/awsAmplifyUtil'

interface Props {
  regMode: boolean;
  setRegMode: React.Dispatch<React.SetStateAction<boolean>>;
  inputs: FormArmAttr;
  setInputs: React.Dispatch<React.SetStateAction<FormArmAttr>>;
}
export default function ArmRegistePanel(props:Props) {
  const { userRole } = useUserRole();
  const { savedMsg, updatedMsg, infoMsg ,errorMsg } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isClear, setIsClear] = useState(false);

  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();
  const { state, dispatch } = useContext(DataStoreContext);

  const formRef = useRef<HTMLFormElement>(null);

  const [fileErr,setFileErr] = useState({err:false,errMsg:""});

  const clearProc = () => {
    setIsLoading(true);
    props.setRegMode(true);
    props.setInputs({...props.inputs,...armInitInputs});
    setIsClear(true); // 各入力コントロールのerrを初期化
    setFileErr({err:false,errMsg:""});
    infoMsg("クリアしました。");
    setIsLoading(false);
  }
  // isClearの戻し処理
  useEffect(()=>{
    if(isClear) setIsClear(false);
  },[isClear]);

  // 更新後のcontextデータ反映
  const updateContextData = async () => {
    // 画像差し替え等もあるのでデータ取得
    const cond = { "query":{"armId":props.inputs.armId} };
    const result = await findArm(cond);
    if(!result.isSuccess) return;
    if(result.data && Object.entries(result.data).length > 0){
      const newData:ArmDocument = Object.entries(result.data).map(([,armDoc])=>(armDoc))[0];
      dispatch({ type: CONTEXT_ACTION_TYPE.ARM_DETAIL , payload: {info: newData} });
      let newList:ArmDocument[] = state.armListPage.armList;
      newList = newList.map(row=>row.attr.armId===props.inputs.armId?newData:row);
      dispatch({ type: CONTEXT_ACTION_TYPE.ARM_LIST ,
        payload: {...state.armListPage, armList:newList} });
    }
  }

  const registData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // ファイルの選択状態
      if (!props.inputs.file){
        const msg = "ファイルを選択してください。";
        errorMsg(msg);
        setFileErr({err:true,errMsg:msg});
        return;
      }
      setFileErr({err:false,errMsg:""});

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
      if(!resFile.isSuccess){
        errorMsg("ファイルアップロード処理が失敗しました。");
        return;
      }
      props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));

      // DB登録
      const regData:ApiArmRegist = makeApiArmRegistFromForm(props.inputs, String(resFile.data));
      const resReg:ResponseType<string[]> = await registArm(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }
      savedMsg();

    } catch (error) {
      console.log("registData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  const updateData = async () => {
    try {
      // input項目のバリデーション
      if (!formRef.current?.reportValidity()) {
        errorMsg("入力内容に誤りがあります。");
        return;
      }

      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      // ローディングボタン
      setIsLoading(true);

      // ファイルアップロード
      let sInputFile = "";
      if (props.inputs.file){
        const resFile:ResponseType<string> = await fileUploade(props.inputs.file);
        if(!resFile.isSuccess){
          errorMsg("ファイルアップロード処理が失敗しました。");
          return;
        }
        props.setInputs(prevInputs => ({...prevInputs, zipURI: String(resFile.data)}));
        sInputFile = String(resFile.data);
      }

      // DB更新
      const regData:ApiArmUpdate = makeApiArmUpdateFromForm(props.inputs, sInputFile);
      const resReg:ResponseType<string[]> = await updateArm(regData);
      if(!resReg.isSuccess){
        errorMsg("更新処理が失敗しました。");
        return;
      }

      updatedMsg();

      void updateContextData();

    } catch (error) {
      console.log("updateData Error : ", error);
      errorMsg("更新処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Container maxWidth="lg">
        <Card sx={{p:3}}>
          <Grid container spacing={3} alignItems="flex-end" sx={{mb:2}}>
            <Grid width="150px">
              <Typography variant="h6">アーム情報</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              { !props.regMode &&
                <Typography variant="body2" sx={{color:"gray"}}>
                  登録日時（登録者）：
                    {`${props.inputs.registrationDate}
                    （${userRole === ROLE_KEYS.ADMIN ? String(props.inputs.registrant) : String(props.inputs.domain)}）`}
                </Typography>
              }
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>
          <form ref={formRef}>
            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>基本情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>
                <Pjdb1TextField
                  label="製品名"
                  name="name" value={props.inputs.name}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製造者（メーカー名など）"
                  name="manufacturer" value={props.inputs.manufacturer}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  required xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="製品の型番"
                  name="number" value={props.inputs.number}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  isClear={isClear}
                  xs={6}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="制御軸数"
                  name="numberOfAxes" value={props.inputs.numberOfAxes}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{maxLength: 2, pattern: "[0-9]|1[0-5]" }} xs={4}
                  toolTip="半角数値(15以下の数値)"
                />

                <Pjdb1TextField
                  label="アーム質量"
                  name="weight" value={props.inputs.weight}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="kg"
                  toolTip={<span>半角数値<br/>制御装置を含めない質量</span>}
                />

                <Pjdb1CheckBox
                  label="協働ロボット"
                  name="cobot" value={props.inputs.cobot}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  disabled={!props.regMode}
                  toolTip="協働ロボットの場合にチェック"
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>製品情報</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextFieldList
                  label="キーワード"
                  name="keyword" value={props.inputs.keyword}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      属性値で表せないアームの特徴を表すキーワードを必要に応じて入力する<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="説明欄"
                  name="description" value={props.inputs.description}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6} multiline rows={4}
                  toolTip={
                    <span>
                      自由記入欄<br />
                      属性値で表せないアームの特徴を表す説明文を必要に応じて記入する
                    </span>
                  }
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid width="100px">
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>その他情報</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1TextFieldList
                      label="URL(製品HP, カタログのリンクなど)"
                      name="source" value={props.inputs.source}
                      onChange={(name,value)=>(handleInputChangeNameVal<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                      isClear={isClear}
                      xs={10}
                      toolTip={
                        <span>
                          文字列<br/>
                          <TextFieldListDescription />
                        </span>
                      }
                    />

                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>性能</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextField
                  label="可搬質量"
                  name="payload" value={props.inputs.payload}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="kg"
                  toolTip="半角数値"
                />

                <Pjdb1TextField
                  label="最大リーチ"
                  name="reachMaximumReach" value={props.inputs.reachMaximumReach}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="mm"
                  toolTip="半角数値"
                />

                <Grid container spacing={2} alignItems="start">
                  <Grid>
                    <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>最大リーチの基準点</InputLabel>
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>

                    <Pjdb1RadioGroup
                      name="reachPoint" value={props.inputs.reachPoint}
                      onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      radios={ARM_REACH_POINT.map((row)=>({btnKey:row.key,btnLbl:row.label}))}
                    />

                  </Grid>
                </Grid>

                <ArmAxesTable
                  inputs={props.inputs}
                  setInputs={props.setInputs}
                  isClear={isClear}
                />

                { props.inputs.cobot &&
                  <>
                    <Pjdb1TextField
                      label="協働状態時最大速度"
                      name="collaborativeSpeed" value={props.inputs.collaborativeSpeed}
                      onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="mm/s"
                      toolTip="半角数値"
                    />

                    <Pjdb1TextField
                      label="非協働状態時最大速度"
                      name="nonCollaborativeSpeed" value={props.inputs.nonCollaborativeSpeed}
                      onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                      isClear={isClear}
                      required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="mm/s"
                      toolTip="半角数値"
                    />
                  </>
                }

                { !props.inputs.cobot &&
                  <Pjdb1TextField
                    label="最大速度"
                    name="nonCollaborativeSpeed" value={props.inputs.nonCollaborativeSpeed}
                    onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                    isClear={isClear}
                    required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="mm/s"
                    toolTip="半角数値"
                  />
                }

                <Pjdb1TextField
                  label="位置繰り返し精度"
                  name="repeatability" value={props.inputs.repeatability}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  required inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={4} unit="mm"
                  toolTip={
                    <span>
                      半角数値<br/>
                      例 : ±0.02mm ⇒ 0.02 と入力
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="手首許容負荷モーメント"
                  name="wristLoadMoment" value={props.inputs.wristLoadMoment}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={5} unit="Nm"
                  requiredOnlyFirst
                  toolTip={
                    <span>
                      半角数値<br/>
                      軸数によって入力数が変わる。<br/>
                      手首軸のうち根本に近い方から順に入力する。<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextFieldList
                  label="手首許容負荷慣性モーメント"
                  name="wristLoadInertia" value={props.inputs.wristLoadInertia}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={5} unit="kgm^2"
                  requiredOnlyFirst
                  toolTip={
                    <span>
                      半角数値<br/>
                      軸数によって入力数が変わる。<br/>
                      手首軸のうち根本に近い方から順に入力する。<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>装置</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextFieldList
                  label="ツール配線(信号線、LAN)"
                  name="applicationCable" value={props.inputs.applicationCable}
                  onChange={(name,value)=>(handleInputChangeNameVal<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      <TextFieldListDescription />
                    </span>
                  }
                />

                <Pjdb1TextField
                  label="エア配管"
                  name="airPiping" value={props.inputs.airPiping}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={3}
                  toolTip="文字列"
                />

                <Pjdb1TextField
                  label="耐圧"
                  name="airPressure" value={props.inputs.airPressure}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  inputProps={{pattern: "[0-9]*\\.?[0-9]*" }} xs={3} unit="MPa"
                  toolTip="半角数値"
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start">
              <Grid width="150px">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>インターフェース</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1TextField
                  label="メカニカルインターフェース(フランジ面)の対応規格"
                  name="mechanical" value={props.inputs.mechanical}
                  onChange={(e)=>(handleInputChange<FormArmAttr>(e, props.inputs, props.setInputs))}
                  isClear={isClear}
                  xs={6}
                  toolTip={
                    <span>
                      文字列<br/>
                      JIS B8436(ISO 9409-1)等に対応している場合の呼び方コード
                    </span>
                  }
                />

              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="start" sx={{mt:1}}>
              <Grid width="150px" alignItems="start">
                <InputLabel variant='standard' sx={{mt:2,color: 'text.primary'}}>ファイル</InputLabel>
              </Grid>
              <Grid sx={{flexGrow: 1}}>

                <Pjdb1FileSelect
                  label="ファイル"
                  name="file" value={props.inputs.file ? [props.inputs.file]:[]}
                  onChange={(name,value)=>(handleInputChangeFile<FormArmAttr>(name, value, props.inputs, props.setInputs))}
                  accept={{'application/zip': ['.zip']}} multiple={false}
                  err={fileErr.err} errMsg={fileErr.errMsg}
                  xs={8}
                  toolTip={
                    <span>
                      以下のフォルダ構成のzipファイルのみアップロード可能<br />
                      zip内フォルダ構成<br />
                      ┣&nbsp;image<br />
                      ┃┣&nbsp;main<br />
                      ┃┃┗&nbsp;メイン画像 ※必須、1枚、外観のわかる画像<br />
                      ┃┗&nbsp;sub<br />
                      ┃&nbsp;&nbsp;┗ サブ画像 任意、0枚以上、その他補足画像<br />
                      ┗&nbsp;urdf<br />
                      &nbsp;&nbsp;┗ URDFファイル 任意、1枚<br /><br />
                      ※jpg、png、svgの画像をアップロード可能
                    </span>
                  }
                />

              </Grid>
            </Grid>

          </form>
        </Card>
      </Container>
      <Box component="section" sx={{ textAlign:"center" }}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={clearProc}
        />
        <Pjdb1Button
          label={`製品情報を${props.regMode?'登録':'更新'}`}
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m:2}}
          onClick={()=>{
            if(props.regMode){
              void registData();
            }else{
              void updateData();
            }
          }}
        />
      </Box>

      <ConfirmDialog
        title={`${props.regMode?'登録':'更新'}確認`}
        message={`アーム情報を${props.regMode?'登録':'更新'}しますか？`}
        confirmButton={`${props.regMode?'登録':'更新'}`}
        confirmButtonStyle="primary"
      />
    </>
  );
}