import { I18n  } from "aws-amplify";
import { View, useTheme } from '@aws-amplify/ui-react';
import {Link} from '@mui/material'

export const setAuthLanguage = () => {
  I18n.setLanguage('ja');
  I18n.putVocabularies({
    ja: {
      'Sign In': '', // Tab header
      'Sign in': 'ログイン', // Button label
      'Sign in to your account': 'メールアドレス、パスワードを入力してください。',
      Username: 'メールアドレス', // Username label
      Password: 'パスワード', // Password label
      'Incorrect username or password.': 'ユーザーが存在しません',
      'Signing in': 'ログイン中',
    },
  });
}

export const authFormFields = {
  signIn: {
    username: {
      placeholder: 'メールアドレス',
      isRequired: true,
      label: ''
    },
    password: {
      placeholder: 'パスワード',
      isRequired: true,
      label: ''
    },
  },
}

export const authComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <img src="/robocip_logo.svg" className="auth-logo" alt="利用者UI" />
      </View>
    );
  },
  Footer() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large} fontSize={tokens.fontSizes.small}>
        {"Copyright © "}
        {new Date().getFullYear()}{" "}
        <Link color="inherit" href="https://www.robocip.or.jp/" underline="none" target="_blank">
          ROBOCIP
        </Link>{" All rights reserved."}
      </View>
    );
  },
  SignIn: {
    Footer() {
      return (
        <span />
      );
    },
  },
}