import {workAttrList} from "../types/workForm";
import {MutationFormHookResult} from "../utils/formUtil";
import {registerWorkInstance} from "../../../utils/awsAmplifyUtil";
import {WorkInstanceDocument} from "../types/pj1dbApiWork";
import {InstanceFormInputs} from "../components/forms/groups/InstanceInputGroup";

export type RegisterInstanceFormInputs = InstanceFormInputs;

export const registerInstanceFormInitInputs: RegisterInstanceFormInputs = {}

export interface RegisterInstanceMutationParams {
  inputs: RegisterInstanceFormInputs,
  classId: WorkInstanceDocument['attr'][typeof workAttrList.classId.key]
}

export default function useRegisterInstance(): MutationFormHookResult<
  RegisterInstanceFormInputs,
  RegisterInstanceMutationParams,
  string
> {
  return {
    validate: (inputs: RegisterInstanceFormInputs) => ({
        isValid: true,
        errors: [],
      }),
    mutate: async (params: RegisterInstanceMutationParams) => {
      const instanceResult = await registerWorkInstance({
        version: 'work-user',
        classId: params.classId,
        [workAttrList.description.key]: params.inputs[workAttrList.description.key],
      })
      if (!instanceResult.isSuccess) {
        return {
          isSuccess: false,
          message: '更新処理が失敗しました。',
          error: instanceResult.err,
        }
      }

      if (!instanceResult.data) {
        throw new Error('Instance ID is not found');
      }

      return {
        isSuccess: true,
        // Instance ID
        data: instanceResult.data,
      }
    }
  }
}
