import {Box, Card, Container, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useState} from "react";
import Pjdb1ImputEx from "../../../../components/inputs/Pjdb1ImputEx";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {WorkClassDocument, WorkInstanceDocument,} from "../../types/pj1dbApiWork";
import {workAttrList} from "../../types/workForm";
import useMutationHelper from "../../hooks/useMutationHelper";
import ClassInfoGroup from "./groups/ClassInfoGroup";
import InstanceInfoGroup from "./groups/InstanceInfoGroup";
import useRegisterModel, {registerModelFormInitInputs, RegisterModelFormInputs} from "../../hooks/useRegisterModel";
import ModelInputGroup from "./groups/ModelInputGroup";
import {Policies, policies} from "../../policies";
import Forbidden from "../errors/Forbidden";

export interface RegisterModelFormProps {
  work: WorkClassDocument;
  instance: WorkInstanceDocument;
  inputs: RegisterModelFormInputs
  setInputs: React.Dispatch<React.SetStateAction<RegisterModelFormInputs>>;
  // eslint-disable-next-line react/require-default-props
  validate?: () => boolean;
  // eslint-disable-next-line react/require-default-props
  onClear?: () => void;
  // eslint-disable-next-line react/require-default-props
  onError?: (err: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  onSuccess?: () => void;
}

export function canRegisterModel() {
  return policies.registerModel.can();
}

/**
 * ワークのクラス情報編集とインスタンス、モデルの登録を行うフォーム
 * @param props
 * @constructor
 */
export default function RegisterModelForm(props: RegisterModelFormProps) {
  const {
    updateWork,
    setModelFileErr,
    modelFileErr,
    ConfirmDialog,
    formRef,
    clear,
    isClear,
    isLoading,
  } = useRegisterModelFormHelper(props);

  if (!canRegisterModel()) {
    return <Forbidden/>
  }

  return (
    <>
      <ConfirmDialog
        title='ワーク情報登録'
        message='ワーク情報を登録します。入力内容に間違いがないか確認してください。'
        confirmButton='登録'
        confirmButtonStyle="primary"
      />

      <Container maxWidth="lg">
        <Card sx={{p: 3}}>
          <Grid container spacing={3} alignItems="flex-end" justifyContent='space-between' sx={{mb: 2}}>
            <Grid>
              <Typography variant="h6">商品/部品登録</Typography>
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <ClassInfoGroup work={props.work}/>

            <InstanceInfoGroup instance={props.instance}/>

            <ModelInputGroup
              inputs={props.inputs}
              setInputs={props.setInputs}
              modelFileErr={modelFileErr}
              setModelFileErr={setModelFileErr}
              isClear={isClear}
            />
          </form>
        </Card>
      </Container>

      <Box component="section" sx={{textAlign: "center"}}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={clear}
        />
        <Pjdb1Button
          label="ワーク情報を登録"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={() => {
            updateWork().catch(console.error)
          }}
        />
      </Box>
    </>
  )
}

function useRegisterModelFormHelper(params: RegisterModelFormProps) {
  const [modelFileErr, setModelFileErr] = useState({err: false, errMsg: ""});
  const {makeMutation, ...helper} = useMutationHelper({
    onClear: () => {
      params.setInputs({
        ...registerModelFormInitInputs,
        ...params.work.attr,
      });
      params.onClear?.();
    }
  });
  const {validate, mutate} = useRegisterModel();

  const updateWork = makeMutation({
    onCompleted: () => {
      if (params.onSuccess) {
        params.onSuccess();
      } else {
        helper.notification.savedMsg();
      }
    },
    onError: (err) => {
      helper.handleError({error: err, message: '更新処理が失敗しました。'});
      params.onError?.(err);
    },
    validate: () => {
      if (!(params.validate?.() ?? true)) {
        return false;
      }

      const res = validate(params.inputs);
      if (!res.isValid) {
        helper.notification.errorMsg(res.errors.map((error) => error.message).join('\n'));
        if (res.errors.some((error) => error.field === workAttrList.modelFile.key)) {
          setModelFileErr({
            err: true,
            errMsg: res.errors.find((error) => error.field === workAttrList.modelFile.key)?.message ?? ""
          });
        } else {
          setModelFileErr({err: false, errMsg: ""});
        }

        return false;
      }
      setModelFileErr({err: false, errMsg: ""});

      return true;
    },
    callback: async () => {
      // ファイルアップロード
      const res = await mutate({
        inputs: params.inputs,
        instanceId: params.instance.attr[workAttrList.instanceId.key],
      });
      if (!res.isSuccess) {
        return helper.handleError({error: res.error, message: res.message});
      }

      return true;
    },
  });

  return {
    updateWork,
    modelFileErr,
    setModelFileErr,
    ...helper,
  }
}
