export interface MakeDateParams {
  date?: string | number | Date;
  locale?: string;
  timeZone?: string;
}

export function makeDate(params: MakeDateParams = {}): Date {
  const date = params.date ? new Date(params.date) : new Date();
  return new Date(date.toLocaleString(params.locale, {timeZone: params.timeZone}));
}

export function makeDateJST(params: MakeDateParams = {}): Date {
  return makeDate({...params, locale: 'ja-JP', timeZone: 'Asia/Tokyo'});
}

export function convertUnixTimeSec(date: Date): number {
  return Math.floor(date.getTime() / 1000);
}
