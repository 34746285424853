import {useContext, useEffect, useState} from "react";
import {Box, BoxProps, Container, SelectChangeEvent, Stack, Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType,
  REGISTRATION_ACTION_TYPE
} from "../../contexts/WorkContext";
import useNotification from "../../../../utils/notificationUtil";
import {WorkClassDocument} from "../../types/pj1dbApiWork";
import SearchForm, {useSearchFormProps} from "../forms/SearchForm";
import SortOrder, {useSortOrder} from "../forms/SortOrder";
import useConfirmDialog from "../../../../components/ConfirmDialog";
import {FormWorkSearchCond, workAttrList} from "../../types/workForm";
import {findWork} from "../../../../utils/awsAmplifyUtil";
import {handleInputChangeSelect} from "../../../../utils/formUtil";
import WorkListItem from "../WorkListItem";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {excludeClassesWithChildren} from "../../utils/workUtil";
import {WorkSortOrderValue} from "../../../../constants/constants";
import {canRegisterWork} from "../forms/RegisterForm";
import {canRegisterInstanceAndModel} from "../forms/RegisterInstanceAndModelForm";

type NextStep = (payload?: Partial<DataStoreType['workRegistrationPage']>) => void;

export interface SelectWorkProps {
  nextStep: NextStep;
}

export default function SelectWork(props: SelectWorkProps) {
  const {state, dispatch} = useContext(DataStoreContext);
  const {errorMsg} = useNotification();
  const {sortWorkList} = useSortOrder();
  const [workList, setWorkList] = useState<WorkClassDocument[]>([]);
  const convertWorkList = (data: WorkClassDocument[], order: WorkSortOrderValue) => sortWorkList(excludeClassesWithChildren(data), order);
  const [searchMes, setSearchMes] = useState("");
  const {isLoading, setIsLoading, searchCond, setSearchCond} = useSearchFormProps();
  const [selectedWork, setSelectedWork] = useState<DataStoreType['workRegistrationPage']['selection']['selectedWork']>(null);
  const selectedWorkStyle: BoxProps['sx'] = {
    outlineColor: (theme) => theme.palette.primary.light,
    outlineStyle: "solid",
    outlineWidth: 6,
    borderRadius: 1
  };
  const {openConfirmDialog: openNewFormDialog, ConfirmDialog: NewFormDialog} = useConfirmDialog();
  const {openConfirmDialog: openAddFormDialog, ConfirmDialog: AddFormDialog} = useConfirmDialog();

  const toggleWork = (work: WorkClassDocument) => {
    const isUnselect = selectedWork?.attr[workAttrList.classId.key] === work.attr[workAttrList.classId.key];
    const newSelectedWork = isUnselect ? null : work;

    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        selection: {
          ...state.workRegistrationPage.selection,
          selectedWork: newSelectedWork
        }
      } satisfies DataStoreType['workRegistrationPage']
    });

    setSelectedWork(newSelectedWork);
  }

  const findWorkProc = async () => {
    setIsLoading(true);
    try {
      const result = await findWork('class', {
        version: "work-user",
        queryAttr: {
          ...(searchCond.keyword === "" ? {} : {
            $or: [
              {[workAttrList.nameJp.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[workAttrList.nameEn.key]: {"$regex": searchCond.keyword, "$options": "i"}},
              {[workAttrList.code.key]: {"$regex": searchCond.keyword, "$options": "i"}},
            ]
          }),
        },
      });

      if (!result.isSuccess) {
        errorMsg("検索処理が失敗しました。");
        return;
      }

      const data = sortWorkList(
        excludeClassesWithChildren(result.data ? Object.values(result.data.items) : []),
        searchCond.sortOrder
      );
      if (data.length === 0) {
        setSearchMes("該当データがありません。");
      }

      const payload = {
        ...state.workRegistrationPage,
        selection: {
          ...state.workRegistrationPage.selection,
          searchCond: {...searchCond, listCount: data.length},
          workList: convertWorkList(data, searchCond.sortOrder),
        }
      } satisfies DataStoreType['workRegistrationPage'];
      setWorkList(payload.selection.workList);
      setSearchCond(payload.selection.searchCond);
      dispatch({type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION, payload});
    } finally {
      setIsLoading(false);
    }
  };

  const changeSortOrder = (event: SelectChangeEvent<WorkSortOrderValue>) => {
    handleInputChangeSelect<FormWorkSearchCond>(event, "sortOrder", searchCond, setSearchCond);
    const newWorkList = convertWorkList(workList, event.target.value as WorkSortOrderValue);
    setWorkList(newWorkList);
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        selection: {
          ...state.workRegistrationPage.selection,
          searchCond: {
            ...searchCond,
            sortOrder: event.target.value as WorkSortOrderValue
          },
          workList: newWorkList,
        }
      } satisfies DataStoreType['workRegistrationPage']
    });
  }

  // 検索済みデータの復元
  useEffect(() => {
    setSearchCond(state.workRegistrationPage.selection.searchCond);
    setWorkList(state.workRegistrationPage.selection.workList);
    setSelectedWork(state.workRegistrationPage.selection.selectedWork);
    setSearchMes("既存の商品/部品を追加登録する場合：商品/部品を検索して選択後に追加登録してください。<br>新規の商品/部品を登録する場合：新規登録してください。<br>※商品/部品の別姿勢モデルの追加登録は登録済み商品/部品一覧から実施してください。");
    if (state.workRegistrationPage.selection.searchCond.keyword !== "" && state.workRegistrationPage.selection.workList.length === 0) {
      setSearchMes('該当データがありません。');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SearchForm isLoading={isLoading} searchCond={searchCond} setSearchCond={setSearchCond} onSubmit={(e) => {
        e.preventDefault();
        findWorkProc().catch(console.error);
      }}/>

      <Stack gap={4}>
        <Container
          sx={{p: 0, display: "flex", flexDirection: "row", flexWrap: "wrap"}}
          maxWidth={false}
        >
          <Grid container alignItems="end" sx={{mt: 2, flexWrap: "nowrap"}} size={{xs: 12}}>
            <Grid size="grow">
              <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>
                検索結果一覧
              </Typography>
            </Grid>
            <Grid size="auto">
              <Grid container alignItems="center" spacing={2} sx={{flexWrap: "nowrap"}}>
                <Grid>
                  <Box component="span">
                    {searchCond.listCount > 0 ? `${searchCond.listCount.toLocaleString()}件` : ""}
                  </Box>
                </Grid>
                <Grid>
                  <Box component="span">
                    <SortOrder value={searchCond.sortOrder} onChange={changeSortOrder}/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container alignItems="stretch" spacing={2} sx={{mt: 2}}>
            {workList.map(row => (
              <Grid
                key={row.attr[workAttrList.classId.key]}
                onClick={() => toggleWork(row)}>
                <Box boxSizing='border-box'
                     height='100%'
                     sx={selectedWork?.attr[workAttrList.classId.key] === row.attr[workAttrList.classId.key] ? selectedWorkStyle : {}}>
                  <WorkListItem work={row}/>
                </Box>
              </Grid>
            ))}
          </Grid>

          {(workList.length === 0) && (
            <Box sx={{my: 3, width: "100%", textAlign: "center"}}>
              <Typography variant="subtitle1" sx={{fontWeight: "bold"}} dangerouslySetInnerHTML={{__html: searchMes}}/>
            </Box>
          )}
        </Container>


        <Box sx={{display: "flex", justifyContent: "center", width: "100%", gap: 4}}>
          <Pjdb1Button
            label="商品/部品を新規登録" variant="contained" color="info"
            disabled={!canRegisterWork()}
            onClick={() => {
              openNewFormDialog().then((result) => {
                if (result === "confirm") {
                  props.nextStep({
                    input: {
                      ...state.workRegistrationPage.input,
                      actionType: REGISTRATION_ACTION_TYPE.NEW,
                    },
                  });
                }
              }).catch(console.error);
            }}/>
          <Pjdb1Button
            label="既存の商品/部品に追加登録" variant="contained" color="primary"
            onClick={() => {
              openAddFormDialog().then((result) => {
                if (result === "confirm") {
                  props.nextStep({
                    input: {
                      ...state.workRegistrationPage.input,
                      actionType: REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL,
                    }
                  });
                }
              }).catch(console.error);
            }}
            disabled={!selectedWork || !canRegisterInstanceAndModel()}/>
        </Box>
      </Stack>


      <NewFormDialog title="商品/部品の重複登録確認"
                     message="同じ商品または部品がすでに登録されていないか確認してください。"
                     confirmButton="はい"
                     cancelButton="いいえ"
                     confirmButtonStyle="info"/>
      <AddFormDialog title="選択した商品/部品の確認"
                     message="選択された商品や備品に間違いがないか確認してください。"
                     confirmButton="はい"
                     cancelButton="いいえ"
                     confirmButtonStyle="primary"/>
    </>
  )
}
