import NameJp from "../fields/NameJp";
import {workAttrList} from "../../../types/workForm";
import {handleInputChange} from "../../../../../utils/formUtil";
import NameEn from "../fields/NameEn";
import Code from "../fields/Code";
import ContentWeight from "../fields/ContentWeight";
import ContentVolume from "../fields/ContentVolume";

export interface ClassFormInputs {
  [workAttrList.nameJp.key]?: string;
  [workAttrList.nameEn.key]?: string;
  [workAttrList.code.key]?: string;
  [workAttrList.contentWeight.key]?: string;
  [workAttrList.contentVolume.key]?: string;
}

export const initClassFormInputs: ClassFormInputs = {
  [workAttrList.nameJp.key]: "",
  [workAttrList.nameEn.key]: "",
  [workAttrList.code.key]: "",
  [workAttrList.contentWeight.key]: "",
  [workAttrList.contentVolume.key]: "",
}

export interface ClassInputGroupProps<T extends ClassFormInputs = ClassFormInputs> {
  inputs: T;
  setInputs: React.Dispatch<React.SetStateAction<T>>;
  isClear: boolean;
}

export default function ClassInputGroup<T extends ClassFormInputs = ClassFormInputs>(props: ClassInputGroupProps<T>) {
  return (
    <>
      <NameJp value={props.inputs[workAttrList.nameJp.key]}
              onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
              required
              isClear={props.isClear}/>

      <NameEn value={props.inputs[workAttrList.nameEn.key]}
              onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
              isClear={props.isClear}/>

      <Code value={props.inputs[workAttrList.code.key]}
            onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
            required

            isClear={props.isClear}/>

      <ContentWeight value={props.inputs[workAttrList.contentWeight.key]}
                     onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
                     isClear={props.isClear}/>

      <ContentVolume value={props.inputs[workAttrList.contentVolume.key]}
                     onChange={(e) => (handleInputChange<T>(e, props.inputs, props.setInputs))}
                     isClear={props.isClear}/>

    </>
  )
}
