/* eslint-disable no-void */
import { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom'
import {
  Typography,
  Container,
  Box,
  Card,CardMedia,CardContent,
  Paper,
  TableContainer,Table,TableHead,TableRow,TableCell,TableBody
} from '@mui/material/';
import Grid from '@mui/material/Grid2';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';
import useNotification from "../../../utils/notificationUtil";
import useConfirmDialog from '../../../components/ConfirmDialog'
import {EQUIPMENT_URLS} from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE, initialState } from "../contexts/EquipmentContext";

import {ResponseType, deleteArm, findHand, findAttachment} from '../../../utils/awsAmplifyUtil'
import { ArmDocument, armInitDoc , makeArmAttrList, makeFormArmFromDocument } from '../types/pj1dbApiArm'
import { HandDocument } from '../types/pj1dbApiHand';
import { AttachmentDocument } from '../types/pj1dbApiAttachment';
import { AttrListRowType } from '../types/handForm';

import Pjdb1RadioGroup from '../../../components/inputs/Pjdb1RadioGroup';
import Pjdb1Button from '../../../components/inputs/Pjdb1Button';
import UrdfLoader from '../components/UrdfLoader';

export default function ArmDetail() {
  const { userRole, userDomain } = useUserRole();
  const { deletedMsg, errorMsg } = useNotification();
  const {ConfirmDialog, openConfirmDialog} = useConfirmDialog();

  const { state, dispatch } = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(false);

  const [info, setInfo] = useState<ArmDocument>(armInitDoc);
  const [armAttrList, setArmAttrList] = useState<AttrListRowType[]>([]);
  const [dispImageType, setDispImageType] = useState<string>("image");

  interface ImageListRowType {
    key:string;
    url:string;
  }
  const [armImageList, setArmImageList] = useState<ImageListRowType[]>([]);
  const [selectedArmImageKey, setSelectedArmImageKey] = useState<string>("");

  const [handList, setHandList] = useState<HandDocument[]>([]);
  const [attachmentList, setAttachmentList] = useState<AttachmentDocument[]>([]);

  const navigate = useNavigate();
  const urlParams = useParams<{ armId: string }>();

  const imageStyle = {
    border : "1px solid rgba(0, 0, 0, 0.23)",
  }
  const selectedImageStyle = {
    border : "2px solid #48B130",
  }

  useEffect(()=>{
    let tempInfo:ArmDocument;
    if(urlParams.armId===state.armDetailPage.info.attr.armId){
      tempInfo = state.armDetailPage.info;
    }else if (!urlParams.armId){
      tempInfo = state.armDetailPage.info;
    }else{
      [tempInfo] =
        state.armListPage.armList.filter(
          (item)=>item.attr.armId === urlParams.armId
        );
    }
    if(tempInfo!==undefined){
      setInfo(tempInfo);
    }else{
      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.ARM_LIST.TAB_IDX });
      navigate(`${EQUIPMENT_URLS.ARM_LIST.URL}`);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const findHandProc = async () => {
    try {
      const result = await findHand({});
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        let handListData:HandDocument[] = Object.entries(result.data).map(([,handDoc])=>(handDoc));
        handListData = handListData.sort((a,b) => a.attr.handInfo.name.localeCompare(b.attr.handInfo.name));
        setHandList(handListData);
      }else{
        setHandList([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const findAttachmentProc = async () => {
    try {
      const cond = {
        "query":{
          "armIds":{ "$regex" : info.attr.armId }
        }
      };
      const result = await findAttachment(cond);
      if(!result.isSuccess) return;
      if(result.data && Object.entries(result.data).length > 0){
        let attachmentListData:AttachmentDocument[] = Object.entries(result.data).map(([,attachmentDoc])=>(attachmentDoc));
        attachmentListData = attachmentListData.sort((a,b) => a.attr.attachmentInfo.name.localeCompare(b.attr.attachmentInfo.name));
        setAttachmentList(attachmentListData);
      }else{
        setAttachmentList([]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    if(info.attr.armId==="") return;

    // アーム属性表
    setArmAttrList(makeArmAttrList(info));

    // 画像リストを作成
    if(info.url){
      setArmImageList(
        info.url?.image.map((imageUrl,idx)=>(
          {key:`image${idx}`,url:imageUrl}
        ))
      );
      setSelectedArmImageKey("image0");
    }

    // ハンドを検索
    void findHandProc();
    // 接続可能アタッチメントを検索
    void findAttachmentProc();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[info]);

  const deleteData = async () => {
    try {
      console.log("deleteData",info.attr.armId);
      // 確認ダイアログ
      const result = await openConfirmDialog();
      if (result !== "confirm") {
        return;
      }

      setIsLoading(true);

      const delData = {
        id:info.attr.armId
      }
      const resDel:ResponseType<string> = await deleteArm(delData);
      if(!resDel.isSuccess){
        errorMsg("削除処理が失敗しました");
        return;
      }

      deletedMsg();

      dispatch({ type: CONTEXT_ACTION_TYPE.ARM_DETAIL ,
        payload: {info: initialState.armDetailPage.info} });

      const newList = state.armListPage.armList.filter(row=>row.attr.armId!==info.attr.armId);
      const newState = state.armListPage;
      newState.armList = newList;
      newState.searchCond.listCount = newList.length;
      dispatch({ type: CONTEXT_ACTION_TYPE.ARM_LIST , payload: newState });

      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.ARM_LIST.TAB_IDX });
      navigate(`${EQUIPMENT_URLS.ARM_LIST.URL}`);

    } catch (error) {
      console.log("deleteData Error : ", error);
      errorMsg("削除処理が失敗しました。");
    } finally {
      setIsLoading(false);
    }
  }
  const editData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeArm:false,armPanel:makeFormArmFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.REGISTE.TAB_IDX });
    navigate(`${EQUIPMENT_URLS.REGISTE.URL}/arm/${info.attr.armId}`);
  }
  const copyData = () => {
    dispatch({ type: CONTEXT_ACTION_TYPE.REGIST ,
      payload: {...state.registPage,regModeArm:true,armPanel:makeFormArmFromDocument(info)} });
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.REGISTE.TAB_IDX });
    navigate(`${EQUIPMENT_URLS.REGISTE.URL}/copyArm/${info.attr.armId}`);
  }

  return (
    <>
      <Container maxWidth="lg" sx={{maxWidth:"1500px !important"}}>
        <Card sx={{p:3,mt:2}}>

          <Grid container spacing={3} alignItems="flex-end" >
            <Grid>
              <Typography variant="h6">アーム詳細</Typography>
            </Grid>
            <Grid sx={{flexGrow: 1}}>
              <Typography variant="body2" sx={{color:"gray"}}>
                登録日時（登録者）：
                  {`${info.attr?.registrationDate?new Date(info.attr?.registrationDate).toLocaleString():""}
                  （${userRole === ROLE_KEYS.ADMIN ? String(info.attr.registrant) : String(info.attr.domain)}）`}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-start" sx={{mt:1}}>
            <Grid size={{xs:12,md:7}} sx={{pt:"0px !important"}}>

              <Pjdb1RadioGroup
                name="dispImageType" value={dispImageType}
                onChange={(e)=>(setDispImageType(e.target.value))}
                radios={[
                  {btnKey:"image",btnLbl:"画像"},
                  {btnKey:"model",btnLbl:"モデル"},
                ]}
                disabledRadios={info.url?.urdf && info.url?.urdf.length > 0 ? [] : ["model"]}
              />

              <Grid container spacing={2} alignItems="flex-start" sx={{mt:0}}>
                { dispImageType === "image" &&
                  <>
                    <Grid size={{xs:3,md:2}}
                    sx={{pt:"0px !important",pr:"1px !important",height:"50vw",maxHeight:"70vh",overflowY:"auto",overflowX:"hidden"}}
                    className="thin-scroll-bar">
                      {armImageList.map(row =>(
                        <Box key={row.key}>
                          <a href="" onClick={(e)=>{
                            e.preventDefault();
                            setSelectedArmImageKey(row.key);
                          }}>
                            <img src={row.url} className="square-image" style={row.key === selectedArmImageKey ? selectedImageStyle : imageStyle} alt={row.key}/>
                          </a>
                        </Box>
                      ))}
                    </Grid>
                    <Grid size={{xs:9,md:10}} sx={{pt:"0px !important"}}>
                      <img src={armImageList.find((row)=>(row.key===selectedArmImageKey))?.url} style={imageStyle} className="square-image" alt="" />
                    </Grid>
                  </>
                }
                { dispImageType === "model" &&
                  <Grid sx={{p:"0px !important",border:"1px solid rgba(0, 0, 0, 0.23)"}} className="square-image">
                    <UrdfLoader urdfUrl={String(info.url?.urdf[0])} />
                  </Grid>
                }
              </Grid>

            </Grid>
            <Grid size={{xs:12,md:5}} sx={{pt:"20px !important"}}>

              <Typography variant="subtitle1">アーム属性表</Typography>
              <TableContainer component={Paper}>
                <Table sx={{ maxWidth: "100%" }} size="small" aria-label="a dense table">
                  <TableHead sx={{bgcolor:"#EEE"}}>
                    <TableRow>
                      <TableCell sx={{px:1,py:0.5}}>分類</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性</TableCell>
                      <TableCell sx={{px:1,py:0.5}}>属性値</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {armAttrList.map((row) => (
                      <TableRow
                        key={row.key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width={80} sx={{px:1,py:0.5}}>{row.class}</TableCell>
                        <TableCell width={150} sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.attr) }} />
                        <TableCell sx={{px:1,py:0.5}} dangerouslySetInnerHTML={{ __html: String(row.value) }} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            </Grid>
          </Grid>

          <Grid container spacing={3} alignItems="flex-end" sx={{mt:1}}>
            <Grid sx={{pt:"8px !important"}}>
              <Typography variant="h6">接続可能ハンド一覧</Typography>
            </Grid>
          </Grid>

          { attachmentList.length === 0 &&
            <Typography variant="subtitle1" sx={{m:2,fontWeight:"bold"}}>
              接続可能ハンドがありません。
            </Typography>
          }
          { attachmentList.length > 0 &&
            <>
              <Grid container spacing={1} alignItems="flex-start" sx={{mt:2,mb:0,mx:2}}>
                <Grid sx={{width:"240px"}}>
                  <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
                    アタッチメント
                  </Typography>
                </Grid>
                <Grid sx={{width:"50px"}}>
                  &nbsp;
                </Grid>
                <Grid sx={{flexGrow: 1}}>
                  <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
                    ハンド
                  </Typography>
                </Grid>
              </Grid>
              {attachmentList.map(row =>(
                <Grid container spacing={1} alignItems="flex-start" sx={{mt:1,m:2,pt:2,borderTop:"1px solid rgba(0, 0, 0, 0.23)",flexWrap:'nowrap'}} key={row.attr.attachmentId}>
                  <Grid sx={{flexShrink:0, width:"240px"}}>
                    <Link to={`${EQUIPMENT_URLS.ATTACHMENT_DETAIL.URL}/${row.attr.attachmentId}`} style={{ textDecoration:'none' }}
                    onClick={()=>{
                      dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.ATTACHMENT_DETAIL.TAB_IDX });
                      dispatch({ type: CONTEXT_ACTION_TYPE.ATTACHMENT_DETAIL , payload: {info:row} });
                    }}>

                      <Card sx={{ width:240, height:200 }}>
                        <CardMedia
                          component="img"
                          height="145"
                          image={row.url?.image[0]}
                          alt={row.attr.attachmentInfo.name}
                        />
                        <CardContent sx={{py:0.5,px:1,m:0}}>
                          <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                            製品名：{row.attr.attachmentInfo.name}
                          </Typography>
                          <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                            製造者：{row.attr.attachmentInfo.manufacturer}
                          </Typography>
                        </CardContent>
                      </Card>

                    </Link>

                  </Grid>
                  <Grid sx={{flexShrink:0, width:"40px",pt:"85px",textAlign:"center"}}>
                    <ArrowForwardIosIcon style={{ fontSize: "30px"}} />
                  </Grid>
                  <Grid sx={{flexGrow: 1}}>
                    { row.attr.handIds.filter(item => item !== "").length === 0 &&
                      <Typography variant="subtitle1" sx={{mx:2,fontWeight:"bold"}}>
                        接続可能ハンドがありません。
                      </Typography>
                    }
                    { row.attr.handIds.filter(item => item !== "").length > 0 &&
                      <Grid container spacing={3} alignItems="flex-start" sx={{m:0}}>
                        {row.attr.handIds.filter(item => item !== "").map(handId =>(
                          handList.filter(handItem => handItem.attr.handId === handId).map(handItem =>(
                            <Grid key={`${row.attr.attachmentId}_${handItem.attr.handId}`} >
                              <Link to={`${EQUIPMENT_URLS.HAND_DETAIL.URL}/${handItem.attr.handId}`} style={{ textDecoration:'none' }}
                              onClick={()=>{
                                dispatch({ type: CONTEXT_ACTION_TYPE.TAB , payload: EQUIPMENT_URLS.HAND_DETAIL.TAB_IDX });
                                dispatch({ type: CONTEXT_ACTION_TYPE.HAND_DETAIL , payload: {info:handItem} });
                              }}>
                                <Card sx={{ width:240, height:200 }}>
                                  <CardMedia
                                    component="img"
                                    height="145"
                                    image={handItem.url?.image[0]}
                                    alt={handItem.attr.handInfo.name}
                                  />
                                  <CardContent sx={{py:0.5,px:1,m:0}}>
                                    <Typography variant="subtitle2" noWrap component='p' sx={{mt:0.5}}>
                                      製品名：{handItem.attr.handInfo.name}
                                    </Typography>
                                    <Typography variant="subtitle2" noWrap component='p' sx={{color:"#888"}}>
                                      製造者：{handItem.attr.handInfo.producer}
                                    </Typography>
                                  </CardContent>
                                </Card>

                              </Link>
                            </Grid>
                          ))
                        ))}
                      </Grid>
                    }
                  </Grid>
                </Grid>
              ))}
            </>
          }
        </Card>
      </Container>
      <Box component="section" sx={{ textAlign:"center", mt:2 }}>
        { (userRole === ROLE_KEYS.ADMIN || (userRole === ROLE_KEYS.ARM_MAKER && userDomain===info.attr.domain)) &&
          <Pjdb1Button
            label="登録情報を変更"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={editData}
            disabled={info.attr.armId===""}
          />
        }

        { (userRole === ROLE_KEYS.ADMIN || (userRole === ROLE_KEYS.ARM_MAKER && userDomain===info.attr.domain)) &&
          <Pjdb1Button
            label="登録情報を削除"
            variant="contained"
            color="error"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={()=>{void deleteData();}}
            disabled={info.attr.armId===""}
          />
        }

        { (userRole === ROLE_KEYS.ADMIN || userRole === ROLE_KEYS.ARM_MAKER) &&
          <Pjdb1Button
            label="コピー登録"
            variant="contained"
            color="secondary"
            isLoading={isLoading}
            sx={{m:1}}
            onClick={copyData}
            disabled={info.attr.armId===""}
          />
        }
      </Box>

      <ConfirmDialog
        title="削除確認"
        message="アーム情報を削除しますか？"
        confirmButton="削除"
        confirmButtonStyle="error"
      />
    </>

  )
}