import { RobocipDbDocument } from "./pj1dbApi";
import { FormAttachmentAttr } from './attachmentForm'
import { AttrListRowType } from "./handForm";

// --------------------------------------------------------------------------------------
/**
 * attachment APIのレスポンス型の定義
 */

export interface AttachmentInfo {
  name: string;
  number?: string;
  manufacturer: string;
  weight?: number;
  setAttachment: boolean;
  handCount: number;
}
export interface ProductInfo {
  keyword?: string[];
  description?: string;
  source?: string[];
}

export interface AttachmentAttr extends RobocipDbDocument {
  _id: string;
  attachmentId: string;
  public: boolean;
  armIds: string[];
  handIds: string[];
  attachmentInfo: AttachmentInfo;
  productInfo: ProductInfo;
}
export interface ObjUrl {
  zip: string[];
  image: string[];
  urdf: string[];
}

export interface AttachmentDocument {
  attr: AttachmentAttr;
  url?: ObjUrl;
}

// AttachmentDocumentの初期値
export const attachmentInitDoc:AttachmentDocument = {
  attr: {
    _id: "",
    attachmentId: "",
    public: false,
    armIds: [],
    handIds: [],
    attachmentInfo: {
      name: "",
      manufacturer: "",
      setAttachment: false,
      handCount: 0,
    },
    productInfo: {},
    registrationDate: 0,
    registrant: "",
    domain:"",
  },
  url:  {
    zip: [],
    image: [],
    urdf: [],
  },
}

// --------------------------------------------------------------------------------------
/**
 * アタッチメントAPI 登録用タイプ
 */
export interface ApiAttachmentRegist {
  zipURI: string;
  public: boolean;
  armIds: string[];
  handIds: string[];
  attachmentInfo: AttachmentInfo;
  productInfo: ProductInfo;
}

export interface ApiAttachmentUpdate {
  id:string;
  set:{
    zipURI?: string;
    public: boolean;
    armIds: string[];
    handIds: string[];
    attachmentInfo: AttachmentInfo;
    productInfo: ProductInfo;
  }
}


// --------------------------------------------------------------------------------------
export function makeApiAttachmentRegistFromForm(
  inputs:FormAttachmentAttr,zipURI:string
):ApiAttachmentRegist{
  return {
    zipURI,
    public: inputs.public,
    armIds: inputs.armIds,
    handIds: inputs.handIds,
    attachmentInfo: {
      name: inputs.name,
      ...(inputs.number ? {number: inputs.number} : {}),
      manufacturer: inputs.manufacturer,
      ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
      setAttachment: inputs.setAttachment,
      handCount: parseFloat(inputs.handCount),
    },
    productInfo: {
      ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
      ...(inputs.description ? {description: inputs.description} : {}),
      ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
    },
  };
}
export function makeApiAttachmentUpdateFromForm(
  inputs:FormAttachmentAttr,argZipURI:string
):ApiAttachmentUpdate{
  return {
    id: inputs.attachmentId,
    set: {
      ...(argZipURI !== "" ? {zipURI:argZipURI} : {}),
      public: inputs.public,
      armIds: inputs.armIds,
      handIds: inputs.handIds,
      attachmentInfo: {
        name: inputs.name,
        ...(inputs.number ? {number: inputs.number} : {}),
        manufacturer: inputs.manufacturer,
        ...(inputs.weight ? {weight: parseFloat(inputs.weight)} : {}),
        setAttachment: inputs.setAttachment,
        handCount: parseFloat(inputs.handCount),
      },
      productInfo: {
        ...(inputs.keyword.filter(val => val !== '').length > 0 ? {keyword: inputs.keyword.filter(val => val !== '')} : {}),
        ...(inputs.description ? {description: inputs.description} : {}),
        ...(inputs.source.filter(val => val !== '').length > 0 ? {source: inputs.source.filter(val => val !== '')} : {}),
      },
    }
  };
}

export function makeFormAttachmentFromDocument(
  info:AttachmentDocument
):FormAttachmentAttr{
  return {
    attachmentId: info.attr.attachmentId,
    registrationDate:
      info.attr.registrationDate?new Date(info.attr.registrationDate).toLocaleString():"",
    registrant: info.attr.registrant,
    domain: String(info.attr.domain),
    setAttachment: info.attr.attachmentInfo.setAttachment,
    public: info.attr.public,
    name: info.attr.attachmentInfo.name,
    manufacturer: info.attr.attachmentInfo.manufacturer,
    number: info.attr.attachmentInfo.number ? info.attr.attachmentInfo.number : "",
    weight: info.attr.attachmentInfo.weight ? String(info.attr.attachmentInfo.weight) : "",
    handCount: String(info.attr.attachmentInfo.handCount) ,
    keyword: info.attr.productInfo.keyword ? info.attr.productInfo.keyword : [],
    description: info.attr.productInfo.description ? info.attr.productInfo.description : "",
    source: info.attr.productInfo.source ? info.attr.productInfo.source : [],
    file: null,
    zipURI: "",
    armIds: info.attr.armIds,
    handIds: info.attr.handIds,
  }
}
// --------------------------------------------------------------------------------------
function getUrlHtml(url:string):string {
  let retHtml = "";
  retHtml = `<span><a href="${url}" style="word-break:break-all;font-size:0.75rem;" target="_blank">${url}</a></span>`;
  return retHtml;
}
export function makeAttachmentAttrList(
  info:AttachmentDocument
):AttrListRowType[]{
  const attrList:AttrListRowType[] = [];

  attrList.push({key:"setAttachment", class:"基本情報", attr:"メーカー製か否か",
    value:info.attr.attachmentInfo.setAttachment?"メーカー製":"自作カスタムアタッチメント"});
  attrList.push({key:"public", class:"", attr:"公開可否",
    value:info.attr.public?"公開アタッチメント":"非公開アタッチメント"});
  attrList.push({key:"name", class:"", attr:"製品名", value:info.attr.attachmentInfo.name});
  attrList.push({key:"manufacturer", class:"", attr:"製造者", value:info.attr.attachmentInfo.manufacturer});
  if(info.attr.attachmentInfo.number){
    attrList.push({key:"number", class:"", attr:"型番", value:info.attr.attachmentInfo.number });
  }
  if(info.attr.attachmentInfo.weight){
    attrList.push({key:"weight", class:"", attr:"アタッチメント質量",
      value : `${String(info.attr.attachmentInfo.weight.toLocaleString())} kg` });
  }
  attrList.push({key:"handCount", class:"", attr:"同時取付け可能ハンド数",
    value:info.attr.attachmentInfo.handCount.toLocaleString()});
  let divLabel = "製品情報";
  if(info.attr.productInfo.keyword){
    attrList.push({key:"keyword", class: divLabel, attr:"キーワード",
      value:info.attr.productInfo.keyword.join("、")});
    divLabel = "";
  }
  if(info.attr.productInfo.description){
    attrList.push({key:"description", class: divLabel, attr:"説明",
      value:String(info.attr.productInfo.description.replace(/\n/g,'<br>')) });
    divLabel = "";
  }
  if(info.attr.productInfo.source){
    let sourceStr = "";
    info.attr.productInfo.source.forEach((src)=>{
      if(sourceStr) sourceStr += "<br />";
      sourceStr += getUrlHtml(src);
    });
    attrList.push({key:"source", class: divLabel, attr:"その他情報", value:sourceStr});
    divLabel = "";
  }

  return attrList;
}