import { TextField , Typography} from "@mui/material";
import Grid from '@mui/material/Grid2';

export default function  Pjdb1ImputEx() {

  const exlStyle = {
    fontSize: ".7rem",
    p:".5rem",
    width:"60px",
    textAlign:"center",
  }
  const exlOptStyle = {
    backgroundColor: "#ddd",
  }


  return (
    <Grid container spacing={1} alignItems="center">
      <Grid><Typography variant="body2">凡例：</Typography></Grid>
      <Grid>
        <TextField
          variant="outlined"
          size="small"
          defaultValue="必須項目"
          disabled
          sx={{
            '& .MuiOutlinedInput-input': exlStyle,
            '& .MuiInputBase-input.Mui-disabled': {WebkitTextFillColor:"rgba(0,0,0,0.87)"},
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid>
        <TextField
          variant="outlined"
          size="small"
          defaultValue="任意項目"
          disabled
          sx={{
            '& .MuiOutlinedInput-input': {...exlStyle,...exlOptStyle},
            '& .MuiInputBase-input.Mui-disabled': {WebkitTextFillColor:"rgba(0,0,0,0.87)"},
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};
