import {
  Box,
  Card,
  Container,
  Paper, Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  WorkClassDocument, WorkModelDocument,
} from "../types/pj1dbApiWork";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType, makeClassEditPayload,
} from "../contexts/WorkContext";
import {WORK_URLS} from "../../../constants/constants";
import {workAttrList} from "../types/workForm";
import {findRelatedModels, findWork} from "../../../utils/awsAmplifyUtil";
import {MaterialTypeField} from "../components/3d/controlFields/MaterialTypeField";
import {PerspectiveField} from "../components/3d/controlFields/PerspectiveField";
import {LookAtField} from "../components/3d/controlFields/LookAtField";
import {GridSizeField} from "../components/3d/controlFields/GridSizeField";
import {handleInputChange} from "../../../utils/formUtil";
import {ControlState, useControlState, useThree} from "../components/3d/View3D";
import Model3D from "../components/3d/Model3D";
import Pjdb1Button from "../../../components/inputs/Pjdb1Button";
import DownloadFileButton from "../components/buttons/DownloadFileButton";
import {policies, policyTypes} from "../policies";
import useUser from "../hooks/useUser";

export default function WorkDetail() {
  const {user, role} = useUser();
  const {state, dispatch} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const three = useThree();
  const {
    work,
    isLoading,
  } = useWork();
  const {
    controlState,
    setControlState,
    clearControlState
  } = useControlState();
  const latestModel = state
    .workDetailPage
    .models
    .sort((a, b) => (a.attr[workAttrList.created.key] > b.attr[workAttrList.created.key] ? -1 : 1))
    .find(() => true);

  useEffect(() => {
    setControlState(state.workDetailPage.controlState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (work && !isLoading) {
      findRelatedModels(work).then((result) => {
        if (result.isSuccess) {
          console.log("WorkDetail: findRelatedModels", Object.values(result.data?.items ?? {}),);
          dispatch({
            type: CONTEXT_ACTION_TYPE.WORK_DETAIL,
            payload: {
              ...state.workDetailPage,
              info: work,
              models: Object.values(result.data?.items ?? {}),
            } satisfies DataStoreType['workDetailPage']
          });
        }
      }).catch(console.error);

      clearControlState();
    } else if (work === null && !isLoading) {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_DETAIL,
        payload: {
          ...state.workDetailPage,
          info: null,
          models: [],
        } satisfies DataStoreType['workDetailPage']
      });
      dispatch({type: CONTEXT_ACTION_TYPE.TAB, payload: WORK_URLS.WORK_LIST.TAB_IDX});
      navigate(`${WORK_URLS.WORK_LIST.URL}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work, isLoading]);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_DETAIL, payload: {
        ...state.workDetailPage,
        controlState,
      } satisfies DataStoreType['workDetailPage']
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlState]);

  if (!work) {
    console.log("WorkDetail: Work is not found.");
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{maxWidth: "1500px !important"}}>
      {isLoading
        ? <Typography>Loading...</Typography>
        : (
          <Card sx={{p: 3, mt: 2}}>
            <Stack gap={4}>
              <Box display='flex' gap={4} flexWrap='wrap'>
                <Stack gap={1}>
                  <Typography variant="body2">※ モデルはイメージです。実際とは異なる場合があります。</Typography>
                  <Model3D model={latestModel} three={three}/>
                </Stack>

                <Stack gap={4}>
                  <Info work={work}/>

                  <Stack gap={2}>
                    <MaterialTypeField control={three.control} value={controlState.materialType}
                                       onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                    <PerspectiveField control={three.control} value={controlState.perspective}
                                      onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                    <LookAtField control={three.control} value={controlState.lookAtType}
                                 onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                    <GridSizeField control={three.control} value={controlState.gridSize}
                                   onChange={(e) => handleInputChange<ControlState>(e, controlState, setControlState)}/>
                  </Stack>
                </Stack>
              </Box>

              <Box display='flex' justifyContent='center' flexWrap='wrap' width='100%' gap={4}>
                {policies[policyTypes.editClass].can({user, role, work}) && <Pjdb1Button
                    label="商品/部品情報を修正"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch({
                        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
                        payload: makeClassEditPayload({state, work}),
                      });
                      navigate(WORK_URLS.WORK_REGISTER.URL);
                    }}
                />}
                {policies[policyTypes.downloadModelFile].can() && <DownloadModelFIle model={latestModel}/>}
              </Box>
            </Stack>
          </Card>
        )}
    </Container>
  )
}

function Info({work}: { work: WorkClassDocument }) {
  return (
    <Stack>
      <Typography variant="subtitle1">ワーク属性表</Typography>
      <AttributeListTable work={work}/>
    </Stack>
  )
}

function AttributeListTable({work}: { work: WorkClassDocument }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{maxWidth: "100%"}} size="small" aria-label="a dense table">
        <TableHead sx={{bgcolor: "#EEE"}}>
          <TableRow>
            <TableCell sx={{px: 1, py: 0.5}}>属性</TableCell>
            <TableCell sx={{px: 1, py: 0.5}}>属性値</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{workAttrList.nameJp.label}</TableCell>
            <TableCell sx={{px: 1, py: 0.5, overflowWrap: 'anywhere'}}
                       dangerouslySetInnerHTML={{__html: String(work.attr[workAttrList.nameJp.key] ?? '')}}/>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{workAttrList.nameEn.label}</TableCell>
            <TableCell sx={{px: 1, py: 0.5, overflowWrap: 'anywhere'}}
                       dangerouslySetInnerHTML={{__html: String(work.attr[workAttrList.nameEn.key] ?? '')}}/>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{workAttrList.code.label}</TableCell>
            <TableCell sx={{px: 1, py: 0.5, overflowWrap: 'anywhere'}}
                       dangerouslySetInnerHTML={{__html: String(work.attr[workAttrList.code.key] ?? '')}}/>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{workAttrList.contentWeight.label}</TableCell>
            <TableCell sx={{px: 1, py: 0.5, overflowWrap: 'anywhere'}}
                       dangerouslySetInnerHTML={{__html: work.attr[workAttrList.contentWeight.key] ? `${String(work.attr[workAttrList.contentWeight.key])} ${workAttrList.contentWeight.meta.unit}` : ''}}/>
          </TableRow>
          <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
            <TableCell sx={{px: 1, py: 0.5, textWrap: 'nowrap'}}>{workAttrList.contentVolume.label}</TableCell>
            <TableCell sx={{px: 1, py: 0.5, overflowWrap: 'anywhere'}}
                       dangerouslySetInnerHTML={{__html: work.attr[workAttrList.contentVolume.key] ? `${String(work.attr[workAttrList.contentVolume.key])} ${workAttrList.contentVolume.meta.unit}` : ''}}/>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type UseWorkResult = WorkClassDocument | null;

function useWork(): { work: UseWorkResult, isLoading: boolean } {
  const urlParams = useParams<{ [WORK_URLS.WORK_DETAIL.ARG_CLASS_ID]?: string }>();
  const {state, dispatch} = useContext(DataStoreContext);
  const [isLoading, setIsLoading] = useState(true);
  const [work, setWork] = useState<UseWorkResult>(null);

  useEffect(() => {
    if (!urlParams.classId) {
      console.log("useWork: classId is not found.");
      setWork(state.workDetailPage.info)
      setIsLoading(false);
      return;
    }

    if (urlParams.classId === state.workDetailPage.info?.attr["class-id"]) {
      console.log("useWork: classId is same.");
      setWork(state.workDetailPage.info)
      setIsLoading(false);
      return;
    }

    findWork('class', {
      queryAttr: {
        [workAttrList.classId.key]: urlParams.classId,
      },
    }).then((result) => {
      if (result.isSuccess) {
        const foundWork = Object.values(result.data?.items ?? {}).find(() => true) ?? null;
        setWork(foundWork);
        setIsLoading(false);
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_DETAIL,
          payload: {
            ...state.workDetailPage,
            info: foundWork,
          } satisfies DataStoreType['workDetailPage']
        });
      }
    }).catch(console.error);
  }, [dispatch, state.workDetailPage, urlParams.classId, work]);

  return {
    work,
    isLoading,
  };
}

function DownloadModelFIle({model}: { model: WorkModelDocument | undefined }) {
  const files = model ? [
    {
      id: model.attr[workAttrList.modelId.key],
      signedUrls: [
        ...model.url?.obj_zip?.all ?? [],
        ...model.url?.usdz ? Object.values(model.url.usdz).flat() : [],
      ],
    }
  ] : [];

  return (
    <DownloadFileButton
      label="モデルのダウンロード"
      variant="contained"
      color="info"
      files={files}
      disabled={files.length === 0}
    />
  )
}
