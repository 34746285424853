import { SelectChangeEvent } from "@mui/material";

/**
 * Inputデータ保持
 * @param event
 * @param getter
 * @param setter
 */
export const handleInputChange = <T>(
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  getter: T,
  setter: React.Dispatch<React.SetStateAction<T>>
) => {

  if (!event) {
    return;
  }
  const newName:string = event.target.name ? event.target.name : "";
  let newValue:string|boolean = "";
  if (event.target instanceof HTMLInputElement) {
    newValue = event.target.type === "checkbox" ? event.target.checked : event.target.value;
  }else {
    newValue = event.target.value;
  }
  setter((prevInputs) => {
    // console.log(`[${event.target.name}]`,'[EquipmentRegiste][onChangeEditEvent]:prevInputs',prevInputs);
    const nextInputs = {...prevInputs, [newName]: newValue}
    // console.log(`[${event.target.name}]`,'[EquipmentRegiste][onChangeEditEvent]:aftrInputs',nextInputs);
    return nextInputs;
  });
};

/**
 * Inputデータ保持(SelectForm用)
 * @param event
 * @param getter
 * @param setter
 */
export const handleInputChangeSelect = <T>(
  event: SelectChangeEvent<string>,
  name: string,
  getter: T,
  setter: React.Dispatch<React.SetStateAction<T>>
) => {
  if (event.target.value != null) {
    setter((prevInputs) => {
      // console.log(`[${name}]`,'[handleInputChangeSelect][onChangeEditEvent]:prevInputs',prevInputs);
      const nextInputs = {...prevInputs, [name]: event.target.value}
      // console.log(`[${name}]`,'[handleInputChangeSelect][onChangeEditEvent]:aftrInputs',nextInputs);
      return nextInputs;
    });
  }
};

/**
 * Inputデータ保持(配列など)
 * @param name
 * @param value
 * @param getter
 * @param setter
 */
export const handleInputChangeNameVal = <T>(
  name:string,
  value:string|string[]|File[]|boolean|object,
  getter: T,
  setter: React.Dispatch<React.SetStateAction<T>>
) => {
  setter((prevInputs) => {
    // console.log(`[${name}]`,'[EquipmentRegiste][handleInputChangeNameVal]:prevInputs',prevInputs);
    const nextInputs = {...prevInputs, [name]: value};
    // console.log(`[${name}]`,'[EquipmentRegiste][handleInputChangeNameVal]:aftrInputs',nextInputs);
    return nextInputs;
  });
};

/**
 * Inputデータ保持(ファイル)
 * @param name
 * @param value
 * @param getter
 * @param setter
 */
export const handleInputChangeFile = <T>(
  name:string,
  value:File[],
  getter: T,
  setter: React.Dispatch<React.SetStateAction<T>>
) => {
  setter((prevInputs) => {
    // console.log(`[${name}]`,'[EquipmentRegiste][onChangeEditNameValue]:prevInputs',prevInputs);
    const nextInputs = {...prevInputs, [name]: value[0]};
    // console.log(`[${name}]`,'[EquipmentRegiste][onChangeEditNameValue]:aftrInputs',nextInputs);
    return nextInputs;
  });
};

/**
 * Inputデータ保持(複数ファイル)
 * @param name
 * @param value
 * @param getter
 * @param setter
 */
export const handleInputChangeFiles = <T>(
  name:string,
  value:File[],
  getter: T,
  setter: React.Dispatch<React.SetStateAction<T>>
) => {
  setter((prevInputs) => {
    // console.log(`[${name}]`,'[EquipmentRegiste][onChangeEditNameValue]:prevInputs',prevInputs);
    const nextInputs = {...prevInputs, [name]: value};
    // console.log(`[${name}]`,'[EquipmentRegiste][onChangeEditNameValue]:aftrInputs',nextInputs);
    return nextInputs;
  });
};
