/**
 * アーム一覧検索条件
 */
export interface FormArmSearchCond {
  keyword:string,
  listCount:number,
  sortOrder:string,
}

/**
 * アーム登録フォーム用タイプ
 */
export interface FormArmAttr {
  armId: string;
  registrationDate: string;
  registrant: string;
  domain: string;
  name: string;
  manufacturer: string;
  number: string;
  numberOfAxes: string;
  weight: string;
  cobot: boolean;
  keyword: string[];
  description: string;
  source: string[];
  payload: string;
  reachMaximumReach: string;
  reachPoint: string;
  motionAngleMin: string[];
  motionAngleMax: string[];
  angularSpeed: string[];
  collaborativeSpeed: string;
  nonCollaborativeSpeed: string;
  repeatability: string;
  wristLoadMoment: string[];
  wristLoadInertia: string[];
  applicationCable: string[];
  airPiping: string;
  airPressure: string;
  mechanical: string;
  file: File|null;
  zipURI: string;
}

// ハンド登録フォーム 初期値
export const armInitInputs:FormArmAttr = {
  armId: "",
  registrationDate: "",
  registrant: "",
  domain: "",
  name: "",
  manufacturer: "",
  number: "",
  numberOfAxes: "",
  weight: "",
  cobot: false,
  keyword: [""],
  description: "",
  source: [""],
  payload: "",
  reachMaximumReach: "",
  reachPoint: "",
  motionAngleMin: [],
  motionAngleMax: [],
  angularSpeed: [],
  collaborativeSpeed: "",
  nonCollaborativeSpeed: "",
  repeatability: "",
  wristLoadMoment: ["","",""],
  wristLoadInertia: ["","",""],
  applicationCable: [""],
  airPiping: "",
  airPressure: "",
  mechanical: "",
  file: null,
  zipURI:"",
}
