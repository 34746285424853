import {useCallback, useContext, useEffect, useState} from "react";
import {Box, Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {
  CONTEXT_ACTION_TYPE,
  DataStoreContext,
  DataStoreType,
  initialState,
  REGISTRATION_ACTION_TYPE, RegistrationActionType
} from "../../contexts/WorkContext";
import useNotification from "../../../../utils/notificationUtil";
import BaseRegisterForm, {RegisterFormInputs} from "../forms/RegisterForm";
import ScrollToTop from "../../../../utils/ScrollTop";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import {workAttrList} from "../../types/workForm";
import BaseEditInstanceForm from "../forms/EditInstanceForm";
import BaseRegisterInstanceForm from "../forms/RegisterInstanceForm";
import BaseRegisterModelForm from "../forms/RegisterModelForm";
import {WORK_URLS} from "../../../../constants/constants";
import {RegisterInstanceFormInputs} from "../../hooks/useRegisterInstance";
import {RegisterModelFormInputs} from "../../hooks/useRegisterModel";
import BaseRegisterInstanceAndModelForm, {
  RegisterInstanceAndModelFormInputs
} from "../forms/RegisterInstanceAndModelForm";
import BaseEditClassForm, {EditClassFormInputs} from "../forms/EditClassForm";

type PrevStep = (payload?: Partial<DataStoreType['workRegistrationPage']>) => void;

export interface RegisterWorkProps {
  prevStep: PrevStep;
}

export default function WorkForm(props: RegisterWorkProps) {
  console.log("WorkForm: render");
  const {state} = useContext(DataStoreContext);
  const navigate = useNavigate();
  const forms = {
    [REGISTRATION_ACTION_TYPE.NEW]: {
      component: RegisterForm,
      canShow: () => true,
      toPrev: () => props.prevStep()
    },
    [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
      component: EditClassForm,
      canShow: () => state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS].work !== null,
      toPrev: () => navigate(WORK_URLS.WORK_DETAIL.URL),
    },
    [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
      component: RegisterInstanceAndModelForm,
      canShow: () => state.workRegistrationPage.selection.selectedWork !== null,
      toPrev: () => props.prevStep(),
    },
    [REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]: {
      component: EditInstanceForm,
      canShow: () => state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE].work !== null && state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE].instance !== null,
      toPrev: () => navigate(WORK_URLS.WORK_REGISTER_LIST.URL),
    },
    [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
      component: RegisterInstanceForm,
      canShow: () => state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].work !== null,
      toPrev: () => navigate(WORK_URLS.WORK_REGISTER_LIST.URL),
    },
    [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
      component: RegisterModelForm,
      canShow: () => state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL].work !== null,
      toPrev: () => navigate(WORK_URLS.WORK_REGISTER_LIST.URL),
    },
  } as const satisfies Record<RegistrationActionType, {
    component: () => JSX.Element,
    canShow: () => boolean,
    toPrev: () => void,
  }>;

  useEffect(() => {
    if (!forms[state.workRegistrationPage.input.actionType].canShow()) {
      forms[state.workRegistrationPage.input.actionType].toPrev();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Form = forms[state.workRegistrationPage.input.actionType].component;

  return (
    <>
      <ScrollToTop/>

      <Container>
        <Box mb={1} display='flex' justifyContent='flex-end'>
          <Pjdb1Button label="商品/部品を選択する" variant="contained" color="inherit" onClick={props.prevStep}/>
        </Box>
      </Container>

      <Form/>
    </>
  )
}

function RegisterForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg, errorMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.NEW]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW],
            inputs,
          }
        }
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  return (
    <BaseRegisterForm
      inputs={inputs}
      setInputs={setInputs}
      onClear={() => {
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
          payload: {
            ...state.workRegistrationPage,
            input: {
              ...state.workRegistrationPage.input,
              [REGISTRATION_ACTION_TYPE.NEW]: {
                ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW],
                registered: false,
                inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW].inputs,
              }
            }
          } satisfies DataStoreType['workRegistrationPage'],
        })
      }}
      validate={() => {
        if (state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW].registered) {
          errorMsg('すでに登録済みのため登録できません。登録する場合は、クリアボタンをクリックし入力内容をクリアしてください。');
          return false;
        }
        return true;
      }}
      onSuccess={() => {
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
          payload: {
            ...state.workRegistrationPage,
            input: {
              ...state.workRegistrationPage.input,
              [REGISTRATION_ACTION_TYPE.NEW]: {
                ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.NEW],
                registered: true,
              }
            }
          } satisfies DataStoreType['workRegistrationPage'],
        })
        savedMsg();
      }}/>
  )
}

function EditClassForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg} = useNotification();
  const [inputs, setInputs] = useState<EditClassFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS],
            inputs,
          }
        }
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const {work} = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS];
  if (!work) {
    throw new Error("Logic error: editClass.work is null");
  }

  return <BaseEditClassForm
    work={work}
    inputs={inputs}
    setInputs={setInputs}
    onSuccess={() => {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
        payload: {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.EDIT_CLASS]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_CLASS],
              work: {
                ...work,
                attr: {
                  ...work.attr,
                  ...inputs,
                  [workAttrList.contentWeight.key]: inputs[workAttrList.contentWeight.key]
                    ? Number(inputs[workAttrList.contentWeight.key])
                    : undefined,
                  [workAttrList.contentVolume.key]: inputs[workAttrList.contentVolume.key]
                    ? Number(inputs[workAttrList.contentVolume.key])
                    : undefined,
                }
              },
            }
          }
        } satisfies DataStoreType['workRegistrationPage']
      })
      savedMsg();
    }}
  />
}

function RegisterInstanceAndModelForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg, errorMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterInstanceAndModelFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL],
            inputs,
          }
        },
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  if (!state.workRegistrationPage.selection.selectedWork) {
    throw new Error("Logic error: selectedWork is null");
  }

  return (
    <BaseRegisterInstanceAndModelForm
      inputs={inputs}
      setInputs={setInputs}
      work={state.workRegistrationPage.selection.selectedWork}
      validate={() => {
        if (state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL].registered) {
          errorMsg('すでに登録済みのため登録できません。登録する場合は、クリアボタンをクリックし入力内容をクリアしてください。');
          return false;
        }
        return true;
      }}
      onClear={() => {
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
          payload: {
            ...state.workRegistrationPage,
            input: {
              ...state.workRegistrationPage.input,
              [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
                ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL],
                registered: false,
                inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL].inputs,
              }
            }
          } satisfies DataStoreType['workRegistrationPage'],
        });
      }}
      onSuccess={() => {
        // 更新データをstateに反映
        const payload = {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL],
              registered: true,
            }
          }
        } satisfies DataStoreType['workRegistrationPage'];
        setInputs(payload.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE_AND_MODEL].inputs);
        dispatch({
          type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
          payload,
        })
        savedMsg();
      }}
    />
  )
}

function RegisterInstanceForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg, errorMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterInstanceFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE],
            inputs,
          }
        }
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const workClass = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].work;

  if (!workClass) {
    throw new Error("Logic error: registerInstance.work is null");
  }

  return <BaseRegisterInstanceForm
    work={workClass}
    inputs={inputs}
    setInputs={setInputs}
    validate={() => {
      if (state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].registered) {
        errorMsg('すでに登録済みのため登録できません。登録する場合は、クリアボタンをクリックし入力内容をクリアしてください。');
        return false;
      }
      return true;
    }}
    onClear={() => {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
        payload: {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE],
              registered: false,
              inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE].inputs,
            }
          }
        } satisfies DataStoreType['workRegistrationPage'],
      });
    }}
    onSuccess={() => {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
        payload: {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_INSTANCE],
              registered: true,
            }
          }
        } satisfies DataStoreType['workRegistrationPage'],
      });
      savedMsg();
    }}
  />
}

function EditInstanceForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterInstanceFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE],
            inputs,
          }
        }
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const {work, instance} = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.EDIT_INSTANCE]
  if (!work || !instance) {
    throw new Error("Logic error: editInstance.work or editInstance.instance is null");
  }

  return <BaseEditInstanceForm
    work={work}
    instance={instance}
    inputs={inputs}
    setInputs={setInputs}
    onSuccess={() => {
      savedMsg();
    }}
  />
}

function RegisterModelForm() {
  const {state, dispatch} = useContext(DataStoreContext);
  const {savedMsg, errorMsg} = useNotification();
  const [inputs, setInputs] = useState<RegisterModelFormInputs>(state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL].inputs);

  useEffect(() => {
    dispatch({
      type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
      payload: {
        ...state.workRegistrationPage,
        input: {
          ...state.workRegistrationPage.input,
          [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
            ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL],
            inputs,
          }
        },
      } satisfies DataStoreType['workRegistrationPage']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs]);

  const {work, instance} = state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL]
  if (!work || !instance) {
    throw new Error("Logic error: registerModel.work or registerModel.instance is null");
  }

  return <BaseRegisterModelForm
    work={work}
    instance={instance}
    inputs={inputs}
    setInputs={setInputs}
    validate={() => {
      if (state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL].registered) {
        errorMsg('すでに登録済みのため登録できません。登録する場合は、クリアボタンをクリックし入力内容をクリアしてください。');
        return false;
      }
      return true;
    }}
    onClear={() => {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
        payload: {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL],
              registered: false,
              inputs: initialState.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL].inputs,
            }
          },
        } satisfies DataStoreType['workRegistrationPage']
      })
    }}
    onSuccess={() => {
      dispatch({
        type: CONTEXT_ACTION_TYPE.WORK_REGISTRATION,
        payload: {
          ...state.workRegistrationPage,
          input: {
            ...state.workRegistrationPage.input,
            [REGISTRATION_ACTION_TYPE.REGISTER_MODEL]: {
              ...state.workRegistrationPage.input[REGISTRATION_ACTION_TYPE.REGISTER_MODEL],
              registered: true,
            }
          }
        } satisfies DataStoreType['workRegistrationPage'],
      });
      savedMsg();
    }}
  />
}
