import {Box, Card, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Pjdb1ImputEx from "../../../../components/inputs/Pjdb1ImputEx";
import ClassInfoGroup from "./groups/ClassInfoGroup";
import {workAttrList} from "../../types/workForm";
import Pjdb1Button from "../../../../components/inputs/Pjdb1Button";
import useMutationHelper from "../../hooks/useMutationHelper";
import {WorkClassDocument} from "../../types/pj1dbApiWork";
import useRegisterInstance, {
  registerInstanceFormInitInputs,
  RegisterInstanceFormInputs
} from "../../hooks/useRegisterInstance";
import InstanceInputGroup from "./groups/InstanceInputGroup";
import {policies} from "../../policies";
import Forbidden from "../errors/Forbidden";

export interface RegisterInstanceFormProps {
  work: WorkClassDocument;
  inputs: RegisterInstanceFormInputs,
  setInputs: React.Dispatch<React.SetStateAction<RegisterInstanceFormInputs>>;
  // eslint-disable-next-line react/require-default-props
  validate?: () => boolean;
  // eslint-disable-next-line react/require-default-props
  onClear?: () => void;
  // eslint-disable-next-line react/require-default-props
  onError?: (err: unknown) => void;
  // eslint-disable-next-line react/require-default-props
  onSuccess?: () => void;
}

export default function RegisterInstanceForm(props: RegisterInstanceFormProps) {
  const {
    ConfirmDialog,
    formRef,
    isClear,
    isLoading,
    clear,
    registerInstance,
  } = useFormHelper(props);

  if (!policies.registerInstance.can()) {
    return <Forbidden/>
  }

  return (
    <>
      <ConfirmDialog
        title='ワーク情報登録'
        message='ワーク情報を登録します。入力内容に間違いがないか確認してください。'
        confirmButton='登録'
        confirmButtonStyle="primary"
      />

      <Container maxWidth="lg">
        <Card sx={{p: 3}}>
          <Grid container spacing={3} alignItems="flex-end" justifyContent='space-between' sx={{mb: 2}}>
            <Grid>
              <Typography variant="h6">商品/部品登録</Typography>
            </Grid>
            <Grid><Pjdb1ImputEx/></Grid>
          </Grid>

          <form ref={formRef}>
            <ClassInfoGroup work={props.work}/>

            <InstanceInputGroup inputs={props.inputs}
                                setInputs={props.setInputs}
                                isClear={isClear}/>
          </form>
        </Card>
      </Container>

      <Box component="section" sx={{textAlign: "center"}}>
        <Pjdb1Button
          label="クリア"
          variant="contained"
          color="inherit"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={clear}
        />
        <Pjdb1Button
          label="ワーク情報を登録"
          variant="contained"
          color="primary"
          isLoading={isLoading}
          sx={{m: 2}}
          onClick={() => {
            registerInstance().catch(console.error)
          }}
        />
      </Box>
    </>
  );
}

function useFormHelper(params: RegisterInstanceFormProps) {
  const {makeMutation, ...helper} = useMutationHelper({
    onClear: () => {
      params.setInputs({
        ...registerInstanceFormInitInputs,
        ...params.work.attr,
      });
      params.onClear?.();
    }
  });
  const {validate, mutate} = useRegisterInstance();

  const registerInstance = makeMutation({
    onCompleted: () => {
      if (params.onSuccess) {
        params.onSuccess();
      } else {
        helper.notification.savedMsg();
      }
    },
    onError: (err) => {
      helper.handleError({error: err, message: '更新処理が失敗しました。'});
      params.onError?.(err);
    },
    validate: () => {
      if (!(params.validate?.() ?? true)) {
        return false;
      }

      const res = validate(params.inputs);
      if (!res.isValid) {
        helper.notification.errorMsg(res.errors.map((error) => error.message).join('\n'));
      }
      return res.isValid;
    },
    callback: async () => {
      const res = await mutate({
        inputs: params.inputs,
        classId: params.work.attr[workAttrList.classId.key],
      });
      if (!res.isSuccess) {
        return helper.handleError({error: res.error, message: '更新処理が失敗しました。'});
      }

      return true;
    },
  });

  return {
    registerInstance,
    ...helper,
  }
}
