import { Amplify } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react'

interface ApiEndpointInfo {
  name: string;
  endpoint: string;
  region: string;
}

export default function configureAmplify() {
  const apiEndpoints: ApiEndpointInfo[] = [];
  apiEndpoints.push({
    name : String(process.env.REACT_APP_AWS_API_NAME_HAND),
    endpoint : String(process.env.REACT_APP_AWS_API_ENDPOINT_HAND),
    region : String(process.env.REACT_APP_AWS_REGION),
  });
  apiEndpoints.push({
    name : String(process.env.REACT_APP_AWS_API_NAME_ARM),
    endpoint : String(process.env.REACT_APP_AWS_API_ENDPOINT_ARM),
    region : String(process.env.REACT_APP_AWS_REGION),
  });
  apiEndpoints.push({
    name : String(process.env.REACT_APP_AWS_API_NAME_WORK),
    endpoint : String(process.env.REACT_APP_AWS_API_ENDPOINT_WORK),
    region : String(process.env.REACT_APP_AWS_REGION),
  });

  Amplify.configure({
    Auth: {
      mandatorySignIn: false, // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    },
    Storage: {
      region: process.env.REACT_APP_AWS_REGION,
      bucket: process.env.REACT_APP_AWS_BUCKET,
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    },
    API: {
      endpoints: apiEndpoints,
    },
  });
};

// --------------------------------------------------
export const ROLE_KEYS = {
  ADMIN : "admin",
  ARM_MAKER : "armmaker",
  HAND_MAKER : "handmaker",
  SIER : "sier",
  RETAILER : "retailer",
} as const;
export const USER_ROLES = [
  {key: ROLE_KEYS.ADMIN,      label: "管理者"},
  {key: ROLE_KEYS.ARM_MAKER,  label: "アームメーカー"},
  {key: ROLE_KEYS.HAND_MAKER, label: "ハンドメーカー"},
  {key: ROLE_KEYS.SIER,       label: "ロボットSier"},
  {key: ROLE_KEYS.RETAILER,   label: "小売り業者"},
] as const;

/**
 * cognito user の権限取り出し
 * @returns 
 */
export function useUserRole() {
  let userRole = "";
  let userDomain = "";
  const { user } = useAuthenticator((context) => [context.user]);
  try{
    // console.log(user);
    if(user.getSignInUserSession()?.getIdToken()?.payload){
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const cognitoGroups:string[] = user.getSignInUserSession()?.getIdToken()?.payload['cognito:groups'] ?? [];
      if(cognitoGroups.length>0) userRole = cognitoGroups[0].toLowerCase();
    }

    const mailaddr = String(user?.attributes?.email);
    userDomain = mailaddr.substring(mailaddr.indexOf('@') + 1);

  }catch(e){
    console.log(e);
  }
  return {userRole, userDomain};
}
