/* eslint-disable react/require-default-props */
import React,{ useState ,useEffect, useRef } from 'react';
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Pjdb1ToolTip from "./Pjdb1ToolTip";

interface Props {
  name: string;
  value: string[];
  checks: {chkKey: string, chkLbl:string}[];
  disabledChecks?: string[];
  onChange?: (name:string,value:string[]) => void;
  required?: boolean;
  disabled?: boolean;
  toolTip?: string|JSX.Element;
  isClear?: boolean;
}

const areEqual = (prevProps:Props, nextProps:Props) => {
  // console.log(`[${prevProps.name}]`,'[Pjdb1SelectCheck]:areEqual(prev)',prevProps);
  // console.log(`[${prevProps.name}]`,'[Pjdb1SelectCheck]:areEqual(next)',nextProps);
  let retEqual:boolean = prevProps.value === nextProps.value;
  if (nextProps.isClear !== undefined && prevProps.isClear !== nextProps.isClear) retEqual = false;
  // console.log(`[${prevProps.name}]`,'[Pjdb1SelectCheck]:areEqual(dif) val:',retEqual);
  return retEqual;
}

/**
 * チェックボックス(複数選択)
 * @author TAW j.sano
 * @param props
 * @returns
 */
export default React.memo((props: Props) => {
  const [checks, setChecks] = useState(props.value);

  // 入力チェック用
  const validRef = useRef<HTMLInputElement>(null);
  const [validRequired, setValidRequired] = useState(true);
  const [err, setErr] = useState(false);

  useEffect(()=>{
    setChecks(props.value);
    setValidRequired(!(props.value.length>0));
  },[props.value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name:string = event.target.name ? event.target.name : "";
    const checkd:boolean = event.target.checked ? event.target.checked : false;
    setChecks((prevChecks)=>{
      let newChecks:string[] = [];
      if(checkd){
        if(!prevChecks.includes(name)){
          newChecks = [...prevChecks,name];
        }
      }else if(prevChecks.includes(name)){
        newChecks = prevChecks.filter(chk => chk !== name);
      }
      if(props.onChange) props.onChange(props.name,newChecks);
      if(props.required){
        setValidRequired(newChecks.length===0);
        setTimeout(() => {
          setErr(!validRef.current?.checkValidity());
        }, 100);
      }
      return newChecks;
    });
  }

  const handleInvalid = () =>{
    setErr(true);
  }

  // isClear = trueになったときはErr状態解除
  useEffect(()=>{
    if(props.isClear) {
      setErr(false);
    }
  },[props.isClear]);

  // console.log(`[${props.name}]`,'[Pjdb1SelectCheck]:rendering!!',props,checks);
  return (
    <>
      <Grid container spacing={2} alignItems="center" sx={{flexWrap:"nowrap"}}>
        {props.checks.map((check) => (
          <Grid sx={{pt:"6px !important"}} key={check.chkKey}>
            <FormControlLabel control={
              <Checkbox
                name={check.chkKey}
                checked={checks && checks.includes(check.chkKey)}
                inputRef={validRef}
                onChange={handleChange}
                disabled={ props.disabledChecks && props.disabledChecks.includes(check.chkKey)}
                sx={err ? {color:"error.main"}:{}}
                required={props.required !== undefined && validRequired? props.required : false}
                onInvalid={handleInvalid}
              />
            } label={check.chkLbl}
            disabled={props.disabled !== undefined ? props.disabled : false} />
          </Grid>
        ))}
        { props.toolTip !== undefined &&
        <Pjdb1ToolTip toolTip={props.toolTip} />
        }
      </Grid>
      { err &&
        <Typography color="error" sx={{fontSize:"0.75rem"}}>
          {validRef.current?.validationMessage}
        </Typography>
      }
    </>

  );
},areEqual);