/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useNavigate , useLocation} from "react-router-dom"
import { ROLE_KEYS, useUserRole } from '../../../utils/awsConfigure';

import {EQUIPMENT_URLS } from '../../../constants/constants'
import { DataStoreContext, CONTEXT_ACTION_TYPE } from "../contexts/EquipmentContext";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function EquipmentFuncTab() {
  const { userRole} = useUserRole();

  const { state, dispatch } = React.useContext(DataStoreContext);
  // console.log("EquipmentFuncTab",state);

  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch({ type: CONTEXT_ACTION_TYPE.TAB, payload: newValue });
    Object.entries(EQUIPMENT_URLS).some(([, CONST])=>{
      if(CONST.TAB_IDX===newValue){
        navigate(CONST.URL);
        return true;
      }
      return false;
    });
  };

  // locationの状態からタブ状態を復元（戻る・進む対応）
  const location = useLocation();
  React.useEffect(()=>{
    // console.log("location.pathname",location.pathname)
    Object.entries(EQUIPMENT_URLS).some(([, CONST])=>{
      if(location.pathname.includes(CONST.URL)){
        if(state.tabIndex!==CONST.TAB_IDX) dispatch({ type: CONTEXT_ACTION_TYPE.TAB, payload: CONST.TAB_IDX })
        return true;
      }
      return false;
    });
  },[dispatch, location.pathname, state.tabIndex]);

  const handDetailTabDisabled = state.handDetailPage.info.attr.handId === "";
  const fingertipDetailTabDisabled = state.fingertipDetailPage.info.attr.fingertipId === "";
  const armDetailTabDisabled = state.armDetailPage.info.attr.armId === "";
  const attachmentDetailTabDisabled = state.attachmentDetailPage.info.attr.attachmentId === "";

  return (
    <Container sx={{m:0, pt:2, backgroundColor: 'white', width:"100%", maxWidth:"unset!important"}}>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={state.tabIndex} onChange={handleChange}>
            <Tab label="ハンド一覧" {...a11yProps(0)} sx={{ fontSize:'1rem' }}/>
            <Tab
              label="ハンド詳細" {...a11yProps(1)} sx={{ fontSize:'1rem' }}
              disabled = {handDetailTabDisabled}
            />
            <Tab label="爪一覧" {...a11yProps(2)} sx={{ fontSize:'1rem' }}/>
            <Tab
              label="爪詳細" {...a11yProps(3)} sx={{ fontSize:'1rem' }}
              disabled = {fingertipDetailTabDisabled}
            />
            <Tab label="アーム一覧" {...a11yProps(0)} sx={{ fontSize:'1rem' }}/>
            <Tab
              label="アーム詳細" {...a11yProps(1)} sx={{ fontSize:'1rem' }}
              disabled = {armDetailTabDisabled}
            />
            <Tab label="アタッチメント一覧" {...a11yProps(2)} sx={{ fontSize:'1rem' }}/>
            <Tab
              label="アタッチメント詳細" {...a11yProps(3)} sx={{ fontSize:'1rem' }}
              disabled = {attachmentDetailTabDisabled}
            />
            { userRole !== ROLE_KEYS.RETAILER &&
              <Tab label="登録" {...a11yProps(4)} sx={{ fontSize:'1rem' }} />
            }
          </Tabs>
        </Box>
      </Box>
    </Container>
  );
}
