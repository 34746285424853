import {workAttrList} from "../../../types/workForm";
import WithLabel, {WithLabelSpacingWrapper} from "../fields/WithLabel";
import {WorkClassDocument} from "../../../types/pj1dbApiWork";

export interface ClassInfoGroupProps {
  work: WorkClassDocument;
}

export default function ClassInfoGroup(props: ClassInfoGroupProps) {
  return (
   <>
     {props.work.attr[workAttrList.nameJp.key] && (
       <WithLabel label={workAttrList.nameJp.label}>
         <WithLabelSpacingWrapper>
           {props.work.attr[workAttrList.nameJp.key]}
         </WithLabelSpacingWrapper>
       </WithLabel>
     )}

     {props.work.attr[workAttrList.nameEn.key] && (
       <WithLabel label={workAttrList.nameEn.label}>
         <WithLabelSpacingWrapper>
           {props.work.attr[workAttrList.nameEn.key]}
         </WithLabelSpacingWrapper>
       </WithLabel>
     )}

     {props.work.attr[workAttrList.code.key] && (
       <WithLabel label={workAttrList.code.label}>
         <WithLabelSpacingWrapper>
           {props.work.attr[workAttrList.code.key]}
         </WithLabelSpacingWrapper>
       </WithLabel>
     )}

     {props.work.attr[workAttrList.contentWeight.key] && (
       <WithLabel label={workAttrList.contentWeight.label}>
         <WithLabelSpacingWrapper>
           {props.work.attr[workAttrList.contentWeight.key]} g
         </WithLabelSpacingWrapper>
       </WithLabel>
     )}

     {props.work.attr[workAttrList.contentVolume.key] && (
       <WithLabel label={workAttrList.contentVolume.label}>
         <WithLabelSpacingWrapper>
           {props.work.attr[workAttrList.contentVolume.key]} ℓ
         </WithLabelSpacingWrapper>
       </WithLabel>
     )}
   </>
  );
}
