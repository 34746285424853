import {UseAuthenticator} from '@aws-amplify/ui-react'
import {WorkAnyDocument} from "../types/pj1dbApiWork";
import {extractDomain} from "../utils/workUtil";
import {ROLE_KEYS} from "../../../utils/awsConfigure";

export type User = UseAuthenticator['user'];

export type Role = typeof ROLE_KEYS[keyof typeof ROLE_KEYS] | string;

// ==================================================
// RoleRule
// ==================================================
export interface RoleRuleParams {
  role: Role
}

export function equalAdminRoleRule({role}: RoleRuleParams): boolean {
  return role === ROLE_KEYS.ADMIN;
}

export function higherThanAdminRoleRule({role}: RoleRuleParams): boolean {
  return equalAdminRoleRule({role});
}

export function equalArmMakerRoleRule({role}: RoleRuleParams): boolean {
  return role === ROLE_KEYS.ARM_MAKER;
}

export function higherThanArmMakerRoleRule({role}: RoleRuleParams): boolean {
  return equalArmMakerRoleRule({role}) || equalAdminRoleRule({role});
}

export function equalHandMakerRoleRule({role}: RoleRuleParams): boolean {
  return role === ROLE_KEYS.HAND_MAKER;
}

export function higherThanHandMakerRoleRule({role}: RoleRuleParams): boolean {
  return equalHandMakerRoleRule({role}) || higherThanArmMakerRoleRule({role})
}

export function equalSierRoleRule({role}: RoleRuleParams): boolean {
  return role === ROLE_KEYS.SIER;
}

export function higherThanSierRoleRule({role}: RoleRuleParams): boolean {
  return equalSierRoleRule({role}) || higherThanHandMakerRoleRule({role})
}

export function equalRetailerRoleRule({role}: RoleRuleParams): boolean {
  return role === ROLE_KEYS.RETAILER;
}

export function higherThanRetailerRoleRule({role}: RoleRuleParams): boolean {
  return equalRetailerRoleRule({role}) || higherThanSierRoleRule({role})
}

// ==================================================
// EqualCreatorRule
// ==================================================
export interface EqualCreatorRuleParams<T extends WorkAnyDocument = WorkAnyDocument> {
  user: User
  work: T
}

export function equalCreatorRule({user, work}: EqualCreatorRuleParams): boolean {
  const userDomain = extractDomain(user.attributes?.email ?? '')
  const creatorDomain = extractDomain(work.attr.creator);

  return userDomain !== null && creatorDomain !== null && userDomain === creatorDomain;
}
